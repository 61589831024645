import styled, { createGlobalStyle } from 'styled-components';
const fontFaces = `
  /* Fontspring-integralcf-bold */
  @font-face {
    font-family: 'Fontspring-integralcf-bold';
    src: url('%PUBLIC_URL%/fonts/Fontspring-integralcf-bold.otf') format('opentype');
  }

  /* Fontspring-integralcf-boldoblique */
  @font-face {
    font-family: 'Fontspring-integralcf-boldoblique';
    src: url('%PUBLIC_URL%/fonts/Fontspring-integralcf-boldoblique.otf') format('opentype');
  }

  /* Fontspring-integralcf-demibold */
  @font-face {
    font-family: 'Fontspring-integralcf-demibold';
    src: url('%PUBLIC_URL%/fonts/Fontspring-integralcf-demibold.otf') format('opentype');
  }

  /* Fontspring-integralcf-demiboldoblique */
  @font-face {
    font-family: 'Fontspring-integralcf-demiboldoblique';
    src: url('%PUBLIC_URL%/fonts/Fontspring-integralcf-demiboldoblique.otf') format('opentype');
  }

  /* Fontspring-integralcf-extrabold */
  @font-face {
    font-family: 'Fontspring-integralcf-extrabold';
    src: url('%PUBLIC_URL%/fonts/Fontspring-integralcf-extrabold.otf') format('opentype');
  }

  /* Fontspring-integralcf-extraboldoblique */
  @font-face {
    font-family: 'Fontspring-integralcf-extraboldoblique';
    src: url('%PUBLIC_URL%/fonts/Fontspring-integralcf-extraboldoblique.otf') format('opentype');
  }

  /* Fontspring-integralcf-heavy */
  @font-face {
    font-family: 'Fontspring-integralcf-heavy';
    src: url('%PUBLIC_URL%/fonts/Fontspring-integralcf-heavy.otf') format('opentype');
  }

  /* Fontspring-integralcf-heavyoblique */
  @font-face {
    font-family: 'Fontspring-integralcf-heavyoblique';
    src: url('%PUBLIC_URL%/fonts/Fontspring-integralcf-heavyoblique.otf') format('opentype');
  }

  /* Fontspring-integralcf-medium */
  @font-face {
    font-family: 'Fontspring-integralcf-medium';
    src: url('%PUBLIC_URL%/fonts/Fontspring-integralcf-medium.otf') format('opentype');
  }

  /* Fontspring-integralcf-mediumoblique */
  @font-face {
    font-family: 'Fontspring-integralcf-mediumoblique';
    src: url('%PUBLIC_URL%/fonts/Fontspring-integralcf-mediumoblique.otf') format('opentype');
  }

  /* Fontspring-integralcf-regular */
  @font-face {
    font-family: 'Fontspring-integralcf-regular';
    src: url('%PUBLIC_URL%/fonts/Fontspring-integralcf-regular.otf') format('opentype');
  }

  /* Fontspring-integralcf-regularoblique */
  @font-face {
    font-family: 'Fontspring-integralcf-regularoblique';
    src: url('%PUBLIC_URL%/fonts/Fontspring-integralcf-regularoblique.otf') format('opentype');
  }
`;

export const GlobalStyle = createGlobalStyle`
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {

 // border: 0;
  font-size: 100%;
  //font: inherit;
  //vertical-align: baseline;
}
  /* Use font-face directly for better compatibility */
  @font-face {
    font-family: 'Inter';
    font-weight: 200;
    font-display: swap; /* Use font-display for better loading behavior */
    src: url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&display=swap') format('woff2'); /* Use WOFF2 for modern browsers */
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 600;
    font-display: swap;
    src: url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600&display=swap') format('woff2');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 900;
    font-display: swap;
    src: url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@900&display=swap') format('woff2');
  }

  @font-face {
    font-family: 'Inter';
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    src: url('https://rsms.me/inter/inter.css') format('woff2-variations'); /* For variable fonts */
  }

    @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,600;1,900&display=swap');
    @import url('https://rsms.me/inter/inter.css');
  
  html {
    font-family: 'Inter', sans-serif;

  }
  
  @supports (font-variation-settings: normal) {
    html {
      font-family: 'Inter', sans-serif;
    }
  }

  * {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
  }

  -ms-hyphenate-limit-chars,
  html, body, #root {
  width: 100%;
  height: 100%;
  width: 100vw;
    height: 100vh;
    min-width: 100vw;
    min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Consider if you need to prevent scrolling */
}

body {
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.text};
  font-family: 'Inter', sans-serif;
  user-select: none;
  cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIxNiIiIGN5PSIxNiIiIHI9IjEwIiBmaWxsPSIjM2UzZTNkIi8+PC9zdmc+'),
    auto;
}

/* Ensure maximum compatibility and responsiveness */
@media screen and (max-width: 100vw) {
  body, #root {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    min-width: 100vw;
    min-height: 100vh;
  }
}

/* Additional styles for high DPI displays */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
  body, #root {
    background-size: cover;
  }
}


  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  canvas {
    opacity: 0;
    touch-action: none;
    animation: fade-in 5s ease 1s forwards;
    position: fixed;
  }
`;

export const LandingPageWrap = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;

`