import { motion } from 'framer-motion';
import React from 'react';
import {
  DropdownItem,
  DropdownWrapper,
  IoDropIcon
} from "./SearchComponents";

const OrderDropdown = ({
  isDarkMode,
  onFilterChange,
  setIsSubNavbarDropdownOpen,
  isSubNavbarDropdownOpen,
  toggleSubNavbarDropdown,
}) => {
  return (

           
    <DropdownWrapper className="sub-navbar-dropdown">
    <IoDropIcon onClick={toggleSubNavbarDropdown} />
    {isSubNavbarDropdownOpen && (
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: 'initial' }}
        transition={{ duration: 0.5 }}
        style={{
          position: 'fixed',
          top: '100%',
          right: '-17.5%',
          backgroundColor: isDarkMode ? '#fff' : '#333',
          borderRadius: '5px',
          padding: '5px 0',
          boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
          zIndex: '999',
          overflow: 'hidden',
        }}
      >
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: '100vh' }}
          transition={{ duration: 0.5 }}
        >
         <div
                    style={{
                      position: 'fixed',
                      top: '100%',
                      right: '-17.5%',
                      backgroundColor: isDarkMode ? '#fff' : '#333',
                      borderRadius: '5px',
                      padding: '5px 0',
                      boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
                      zIndex: '999',
                      width: 'auto',
                      maxWidth: '90vw' 
                    }}
                  >

                    <DropdownItem 
                      onClick={() => {
                        onFilterChange('Price: Low to High');
                        setIsSubNavbarDropdownOpen(false); // Close dropdown after selection
                      }}
                      style={{ padding: '10px', color: isDarkMode ? '#000' : '#fff' }}
                      onMouseEnter={(e) => e.currentTarget.style.color = 'yellow'}
                      onMouseLeave={(e) => e.currentTarget.style.color = isDarkMode ? '#000' : '#fff'}
                    >
                      Price: Low to High
                    </DropdownItem>

                    <DropdownItem 
                      onClick={() => {
                        onFilterChange('Price: High to Low');
                        setIsSubNavbarDropdownOpen(false); // Close dropdown after selection
                      }}
                      style={{ padding: '10px', color: isDarkMode ? '#000' : '#fff' }}
                      onMouseEnter={(e) => e.currentTarget.style.color = 'yellow'}
                      onMouseLeave={(e) => e.currentTarget.style.color = isDarkMode ? '#000' : '#fff'}
                    >
                      Price: High to Low
                    </DropdownItem>

                    <DropdownItem 
                      onClick={() => {
                        onFilterChange('Recently Listed');
                        setIsSubNavbarDropdownOpen(false); // Close dropdown after selection
                      }}
                      style={{ padding: '10px', color: isDarkMode ? '#000' : '#fff' }}
                      onMouseEnter={(e) => e.currentTarget.style.color = 'yellow'}
                      onMouseLeave={(e) => e.currentTarget.style.color = isDarkMode ? '#000' : '#fff'}
                    >
                      Recently Listed
                    </DropdownItem>

                    <DropdownItem 
                      onClick={() => {
                        onFilterChange('Rare to Common');
                        setIsSubNavbarDropdownOpen(false); // Close dropdown after selection
                      }}
                      style={{ padding: '10px', color: isDarkMode ? '#000' : '#fff' }}
                      onMouseEnter={(e) => e.currentTarget.style.color = 'yellow'}
                      onMouseLeave={(e) => e.currentTarget.style.color = isDarkMode ? '#000' : '#fff'}
                    >
                      Rare to Common
                    </DropdownItem>

                    <DropdownItem 
                      onClick={() => {
                        onFilterChange('Common to Rare');
                        setIsSubNavbarDropdownOpen(false); // Close dropdown after selection
                      }}
                      style={{ padding: '10px', color: isDarkMode ? '#000' : '#fff' }}
                      onMouseEnter={(e) => e.currentTarget.style.color = 'yellow'}
                      onMouseLeave={(e) => e.currentTarget.style.color = isDarkMode ? '#000' : '#fff'}
                    >
                      Common to Rare
                    </DropdownItem>

                    <DropdownItem 
                      onClick={() => {
                        onFilterChange('Number: Low to High');
                        setIsSubNavbarDropdownOpen(false); // Close dropdown after selection
                      }}
                      style={{ padding: '10px', color: isDarkMode ? '#000' : '#fff' }}
                      onMouseEnter={(e) => e.currentTarget.style.color = 'yellow'}
                      onMouseLeave={(e) => e.currentTarget.style.color = isDarkMode ? '#000' : '#fff'}
                    >
                      Number: Low to High
                    </DropdownItem>

                    <DropdownItem 
                      onClick={() => {
                        onFilterChange('Number: High to Low');
                        setIsSubNavbarDropdownOpen(false); // Close dropdown after selection
                      }}
                      style={{ padding: '10px', color: isDarkMode ? '#000' : '#fff' }}
                      onMouseEnter={(e) => e.currentTarget.style.color = 'yellow'}
                      onMouseLeave={(e) => e.currentTarget.style.color = isDarkMode ? '#000' : '#fff'}
                    >
                      Number: High to Low
                    </DropdownItem>
                    
                  </div>
        </motion.div>
      </motion.div>
    )}
  </DropdownWrapper>
  )
}

export default OrderDropdown
