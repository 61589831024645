import { Canvas, useFrame } from "@react-three/fiber";
import { EffectComposer, TiltShift2 } from "@react-three/postprocessing";
import React, { useRef, useState } from 'react';

import icon from '../../../../images/w3oNameHorzBlack.png';
import icon2 from '../../../../images/w3oNameHorzWhite.png';
import { EnvDesktop } from "../../ClosetModel";
// Closet Item Imports
import {
    About,
    CanvasWrapper,
    Description,
    Details,
    ExitIcon,
    ExpandedBottomCard,
    ExpandedTopCard,
    ExpandedTopCardColumn,
    LeftArrow,
    RightArrow,
    Tab,
    TabContainer,
    Traits
} from './ItemElements';

import { itemModels } from "../../W3oUserDefaultCloset";

const RotatingModel = ({ hoveredItem }) => {
    const ModelComponent = itemModels[hoveredItem];
    const ref = useRef();
  
    useFrame(() => {
      if (ref.current) {
        ref.current.rotation.y += 0.01; // Adjust rotation speed as needed
      }
    });
  
    return ModelComponent ? <group scale={0.1} ref={ref}><ModelComponent /></group> : null;
  };

  
// Framer Motion animations
const rotateAnimation = {
    hidden: { rotateZ: 0 },
    visible: { rotateZ: 180 },
  };
  
  const scaleAnimation = {
    hidden: { scale: 1 },
    visible: { scale: 1.02 },
  };
  
  const fadeInScaleAnimation = {
    hidden: { opacity: 0, scale: 0, filter: 'blur(15px)' },
    visible: { opacity: 1, scale: 1, filter: 'blur(0)' },
  };
  
  const blurIn = {
    hidden: { opacity: 0, y: -2, filter: 'blur(15px)' },
    visible: { opacity: 1, y: 0, filter: 'blur(0)' },
  };
  
  const scaleIn = {
    hidden: { scale: 0.5, filter: 'blur(15px)' },
    visible: { scale: 1, filter: 'blur(0)' },
  };
  
  const fadeIn = {
    hidden: { opacity: 0, scale: 0.8, filter: 'blur(15px)' },
    visible: { opacity: 1, scale: 1, filter: 'blur(0px)' },
  };
  
  const rotateIn = {
    hidden: { opacity: 0, rotateX: -90, rotateZ: -90 },
    visible: { opacity: 1, rotateX: 0, rotateZ: 0 },
  };
  
  const slideIn = {
    hidden: { opacity: 0, x: 200, filter: 'blur(15px)' },
    visible: { opacity: 1, x: 0, filter: 'blur(0px)' },
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.2 // stagger the animation of children
      }
    },
    exit: { opacity: 0, transition: { duration: 0.5 } }
  };
  const childVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 }
  };


  export const ItemDetailsCardExpanded = ({ details = [], setHoveredGroup, setHoveredItemDetails, setSelectedObject, isDarkMode, onClose, onNext, isMobile, onPrev, currentIndex = 0 }) => {
    const [activeTab, setActiveTab] = useState('about');
  
    if (!details || details.length === 0) return null;
  
    const logoSrc = isDarkMode ? icon : icon2;
    const currentDetail = details[currentIndex];
  
    if (!currentDetail) return null;
  
    return (
      <>
        <ExpandedTopCard
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          isDarkMode={isDarkMode}
          isMobile={isMobile}
          style={{ zIndex: 999999999 }}
        >
          <ExitIcon onClick={onClose}>✖</ExitIcon>
          <LeftArrow onClick={() => { 
               setHoveredGroup(null); 
            setSelectedObject(null); 
  
            setHoveredItemDetails(null); 
            onPrev(); 
         
          }}>◀</LeftArrow>
          <RightArrow onClick={() => { 
               setHoveredGroup(null); 
            setSelectedObject(null); 
            setHoveredItemDetails(null); 
            onNext(); 
         
          }}>▶</RightArrow>
          <React.Fragment>
            <CanvasWrapper style={{ width: '100vw', height: '225px', marginTop: '25px' }}>
              <Canvas style={{ width: '100vw', height: '100vh' }} camera={{ position: [2, 2, 2], fov: 50 }}>
                <ambientLight intensity={0.5} />
                <pointLight position={[10, 10, 10]} intensity={0.8} />
                <spotLight position={[-10, 10, -10]} angle={0.15} penumbra={1} intensity={1} castShadow />
                <spotLight position={[10, -10, 10]} angle={0.15} penumbra={1} intensity={1} castShadow />
                <group scale={2}>
                    <EnvDesktop />
                  <RotatingModel hoveredItem={currentDetail.group} />
                </group>
                <EffectComposer stencilBuffer disableNormalPass autoClear={false} multisampling={4}>
                
                  <TiltShift2 samples={5} blur={0.1} />
                </EffectComposer>
              </Canvas>
            </CanvasWrapper>
            <ExpandedTopCardColumn>
              <Description variants={childVariants}>{currentDetail.name}</Description>
            </ExpandedTopCardColumn>
          </React.Fragment>
        </ExpandedTopCard>
        <ExpandedBottomCard isMobile={isMobile}>
          <TabContainer isDarkMode={isDarkMode}>
            <Tab isActive={activeTab === 'about'} isDarkMode={isDarkMode} onClick={() => setActiveTab('about')}>About</Tab>
            <Tab isActive={activeTab === 'traits'} isDarkMode={isDarkMode} onClick={() => setActiveTab('traits')}>Traits</Tab>
            <Tab isActive={activeTab === 'details'} isDarkMode={isDarkMode} onClick={() => setActiveTab('details')}>Details</Tab>
          </TabContainer>
          {activeTab === 'about' && <About currentDetail={currentDetail} isDarkMode={isDarkMode} />}
          {activeTab === 'traits' && <Traits currentDetail={currentDetail} isDarkMode={isDarkMode} />}
          {activeTab === 'details' && <Details currentDetail={currentDetail} isDarkMode={isDarkMode} />}
        </ExpandedBottomCard>
      </>
    );
  };
  
  