import React, { useEffect, useState } from 'react';
import { BsFillGrid3X3GapFill, BsGrid1X2Fill, BsGridFill } from 'react-icons/bs';
import { FaCaretDown } from 'react-icons/fa';
import { IoList } from 'react-icons/io5';
import styled from 'styled-components';
import LayoutDropdown from './LayoutDropdown';
import OrderDropdown from './OrderDropdown';
import {
  BrowseCollectionButton,
  FaHomeIcon,
  FaTagsIcon,
  FaThLargeIcon,
  IconText,
  IconTextMobile,
  IoFilterIcon,
  MobileSubNavbarContainer,
  NavItemWrapper,
  NavbarItem,
  NavbarItemLast,
  SearchIcon,
  SearchInput,
  SearchInputWrapper,
  SubNavbarWrapMobile
} from "./SearchComponents";


const ColumnContainer = styled.div`
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px; /* Gap between elements */
`;

const Col1 = styled.div`
  flex: 0 0 auto; /* Column takes width of its content */
left: 2%;
position: relative;
`;

const Col2 = styled.div`
  flex: 1 1 auto; /* Column takes up remaining space */
`;

const Col3 = styled.div`
  flex: 0 0 auto; /* Column takes width of its content */
  position: relative;
  right: 2%;
  
`;




export const SearchNavbarMobile = ({
    onSearch, onBrowseAllClick, setHoveredGroup, setHoveredItemDetails, setIsSelected,
    setIsBrowseAllMode, onWearablesClick, viewState, onNFTsClick, isDarkMode, homeToggle,
    browseMode, setBrowseMode, setViewState, onFilterChange, setSelectedObject, selectedFilter, onToggleFilter, ...props
  }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isNavItemDropdownOpen, setIsNavItemDropdownOpen] = useState(false);
    const [isSubNavbarDropdownOpen, setIsSubNavbarDropdownOpen] = useState(false);

    const isMobile = window.innerWidth <= 600;

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (!event.target.closest('.dropdown-wrapper')) {
          setIsNavItemDropdownOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
    
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (!event.target.closest('.sub-navbar-dropdown')) {
          setIsSubNavbarDropdownOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
    
// Update the toggle function to correctly handle dropdown states
const toggleSubNavbarDropdown = () => {
  setIsSubNavbarDropdownOpen(!isSubNavbarDropdownOpen);
  if (isSubNavbarDropdownOpen) {
    setIsNavItemDropdownOpen(false); // Close the NavItem dropdown when the SubNavbar dropdown is open
  }
};

// Ensure dropdowns only close when clicking outside or selecting an option
useEffect(() => {
  const handleClickOutside = (event) => {
    if (!event.target.closest('.dropdown-wrapper') && !event.target.closest('.sub-navbar-dropdown')) {
      setIsNavItemDropdownOpen(false);
      setIsSubNavbarDropdownOpen(false); // Close both dropdowns when clicking outside
    }
  };
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

    
    const toggleNavItemDropdown = () => {
      setIsNavItemDropdownOpen(!isNavItemDropdownOpen);
      setIsSubNavbarDropdownOpen(false); // Ensure the SubNavbar dropdown is closed when this one is open
    };
    
    

    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
    };

    const handleSearchSubmit = () => {
      onSearch(searchTerm); 
      onFilterChange(selectedFilter); 
    };

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        handleSearchSubmit();
      }
    };

    const browseToggle = () => {
      setBrowseMode(true);
      setIsSelected(false);
      setSelectedObject(null);
      setHoveredGroup(null);
      setHoveredItemDetails(null);
      if (isMobile) {
        setIsBrowseAllMode(true);
      }
    };

    const renderCurrentIcon = () => {
      switch (viewState) {
        case 0:
          return <BsGrid1X2Fill />;
        case 1:
          return <BsGridFill />;
        case 2:
          return <BsFillGrid3X3GapFill />;
        case 3:
          return <IoList />;
        default:
          return <BsGrid1X2Fill />;
      }
    };

    const handleViewStateChange = (newState) => {
      setViewState(newState);
      setIsNavItemDropdownOpen(false); // Close the dropdown after selection
    };

  if (!isMobile) return null;

  return (
    <>
      {browseMode ? (
        <ColumnContainer>
          <Col1>
            <SubNavbarWrapMobile isMobile={isMobile} browseMode={browseMode}>
              <IoFilterIcon onClick={onToggleFilter} />
            </SubNavbarWrapMobile>
          </Col1>

          <Col2>
            <MobileSubNavbarContainer
              initial={{ opacity: 0, y: browseMode ? -100 : 100, filter: 'blur(15px)' }}
              animate={{ opacity: 1, y: 0, filter: 'blur(0px)' }}
              transition={{ duration: 0.5 }}
              isDarkMode={isDarkMode}
              browseMode={browseMode}
            >
            
                <SearchInputWrapper isFocused={isFocused}>
                  <SearchInput
                    type="text"
                    placeholder="Search"
                    isDarkMode={isDarkMode}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyPress}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                  />
                  <SearchIcon isDarkMode={isDarkMode} onClick={handleSearchSubmit} />
                </SearchInputWrapper>

                <NavItemWrapper>
                  <NavbarItem onClick={homeToggle}>
                    <FaHomeIcon />
                    <IconText>{'HOME'}</IconText>
                  </NavbarItem>

                  <NavbarItem onClick={onNFTsClick}>
                    <FaTagsIcon />
                    <IconText>{'NFTs'}</IconText>
                  </NavbarItem>

                  <NavbarItemLast
                    onClick={toggleNavItemDropdown}
                    style={{
                      display: 'flex', 
                      alignItems: 'center', 
                      position: 'relative', 
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      left: '15px',

                      padding: '0 0px',  
                      margin: '0 0px'    
                    }}
                    className="dropdown-wrapper"
                  >
                    {renderCurrentIcon()}
                    <FaCaretDown  style={{ left: '0px', position: 'relative' }}/>

                    {isNavItemDropdownOpen && (
                      <LayoutDropdown
                        isDarkMode={isDarkMode}
                        handleViewStateChange={handleViewStateChange}
                      />
                    )}
                  </NavbarItemLast>
                </NavItemWrapper>
      
            </MobileSubNavbarContainer>
          </Col2>

          <Col3>
            <SubNavbarWrapMobile isMobile={isMobile} browseMode={browseMode}>
              <OrderDropdown
                isDarkMode={isDarkMode}
                onFilterChange={onFilterChange}
                setIsSubNavbarDropdownOpen={setIsSubNavbarDropdownOpen}
                isSubNavbarDropdownOpen={isSubNavbarDropdownOpen}
                toggleSubNavbarDropdown={toggleSubNavbarDropdown}
              />
            </SubNavbarWrapMobile>
          </Col3>
        </ColumnContainer>
      ) : (
        <MobileSubNavbarContainer style={{bottom: '2.5%'}} >
          <BrowseCollectionButton  onClick={browseToggle}>
            <IconTextMobile>{'BROWSE COLLECTION'}</IconTextMobile>
            <FaThLargeIcon />
          </BrowseCollectionButton>
        </MobileSubNavbarContainer>
      )}
    </>
  );
};
