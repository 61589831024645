import { HiPlusCircle } from "react-icons/hi2";
import styled from 'styled-components';

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

export const ListCard = styled.div`
  display: flex;
  align-items: center;
  background: ${({ isDarkMode }) => (isDarkMode ? '#333' : '#fff')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
  //border: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ddd')};
  border-radius: 10px;
  height: 50px;
  width: 80vw;
  padding: 0 15px;
  margin-left: 50%;
  transform: translateX(-50%);

  &:hover {
  box-shadow: 0px 2.5px 7.5px rgba(0, 0, 0, 0.3);
  border-radius: 10px; /* Ensure the shadow remains rounded */
}


@media screen and (max-width: 600px) {
   // max-width: 100px;

   // margin: 0 auto; /* Center featured card on mobile */
   // margin-top: 40px;


  }
`;

export const ListImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 40px;
  max-height: 40px;
  border-radius: 5px;
`;

export const ListTitle = styled.h3`
  margin: 0 10px;
  font-size: 1.2em;
`;

export const ListDescription = styled.p`
  color: ${({ isDarkMode }) => (isDarkMode ? '#ddd' : '#666')};
  font-size: 0.6em;
  white-space: nowrap;
  margin: 0 10px;
`;

export const FeaturedListDescription = styled.p`
  color: ${({ isDarkMode }) => (isDarkMode ? '#ddd' : '#666')};

  margin: 0 10px;
`;

export const ListPrice = styled.p`
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: 0 10px;
`;

export const ListOwner = styled.p`
  font-size: 0.9em;
  color: ${({ isDarkMode }) => (isDarkMode ? '#aaa' : '#888')};
  margin: 0 10px;
`;

export const ListCollectionTitle = styled.h2`
  font-size: 2em;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  text-align: left;
  margin: 0;
  white-space: nowrap;
`;

export const ListCollectionSubheader = styled.h3`
  font-size: 1.5em;
  color: ${({ isDarkMode }) => isDarkMode ? '#aaa' : '#666'};
  text-align: left;
  margin: 0;
  white-space: nowrap;
`;

export const CollectionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: ${({ isMobile }) => isMobile ? '12.5vh' : '12.5vh'};
  white-space: nowrap;
  margin-left: 5%;
  @media screen and (max-width: 600px) {
   // grid-template-columns: 1fr;
   // max-width: 300px;
   font-size: 0.8rem;

  }
`;

export const FeaturedListCard = styled(ListCard)`
  display: grid;
  grid-template-columns: 1fr 3fr; /* 1/4 for image, 3/4 for the rest */
  align-items: center;
  padding: 20px;
  margin-bottom: 35px;
  width: 92.5%;
  height: auto;
  text-align: left;
  margin-top: -40px;



`;

export const FeaturedColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const FeaturedRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 0;
  & > img {
 // width: 100%;

  object-fit: cover;
  //border-radius: 10px 10px 0 0;
  transition: transform 0.3s ease-in-out;
  max-width: initial;
  max-height: initial;

  &:hover {
    transform: scale(1.05);
  }
}
`;

export const ViewDropsButton = styled.button`
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ddd')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  position: absolute;
  //margin-top: 20px;
  left: 50%;
  margin-bottom: 20pxh;
  transform: translateX(-50%);
  bottom: 10px;
`;

export const SmallText = styled.span`
  font-size: 0.8em;
  color: ${({ isDarkMode }) => (isDarkMode ? '#ccc' : '#999')};
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 10px;
`;

export const PlusButton= styled(HiPlusCircle)`
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  border: none;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  cursor: pointer;
  position: relative;
  margin: 0 10px;
  &:hover {
    transform: scale(1.1);
  }
`;



export const BlurBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 9999;
`;

export const CloseIcon = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5em;
  cursor: pointer;
  z-index: 99999999999;
`;

export const RarityMark = styled.mark`
  margin: 0 10px;
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent; 
  font-size: 0.8em;
  background-image: ${({ rarity }) => {
    switch (rarity) {
      case 'legendary':
        return 'linear-gradient(to right, rgba(255, 225, 0, 0.1), rgba(255, 225, 0, 0.7) 4%, rgba(255, 180, 0, 0.3))';
      case 'epic':
        return 'linear-gradient(to right, rgba(255, 180, 0, 0.1), rgba(255, 127, 0, 0.7) 4%, rgba(255, 127, 0, 0.3))';
      case 'rare':
        return 'linear-gradient(to right, rgba(255, 127, 0, 0.1), rgba(255, 64, 0, 0.7) 4%, rgba(255, 64, 0, 0.3))';
      case 'uncommon':
        return 'linear-gradient(to right, rgba(255, 64, 0, 0.1), rgba(255, 0, 0, 0.7) 4%, rgba(255, 0, 0, 0.3))';
      case 'common':
        return 'linear-gradient(to right, rgba(255, 0, 0, 0.1), rgba(192, 192, 192, 0.7) 4%, rgba(192, 192, 192, 0.3))';
      default:
        return 'linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.7) 4%, rgba(255, 255, 255, 0.3))';
    }
  }};
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
`;
