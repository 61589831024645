import { Canvas, useFrame } from "@react-three/fiber";
import { EffectComposer, TiltShift2 } from "@react-three/postprocessing";
import React, { useRef, useState } from 'react';
import { FaInfoCircle } from "react-icons/fa";
import icon from '../../../../images/w3oNameHorzBlack.png';
import icon2 from '../../../../images/w3oNameHorzWhite.png';
import { EnvDesktop } from "../../ClosetModel";
// Closet Item Imports
import { ExitIcon } from "../../../../components/Global/GlobalElements";
import { itemModels } from "../../W3oUserDefaultCloset";
import {
  About,
  CanvasWrapper,
  Description,
  Details,
  ExpandedBottomCardMobile,
  ExpandedTopCardColumn,
  InfoIcon,
  InfoPopup,
  LeftArrow,
  RightArrow,
  Tab,
  TabContainer,
  Traits
} from './ItemElements';

import { ExitIconWrap, PExit } from "../../../../components/Global/GlobalElements";
const RotatingModel = ({ hoveredItem }) => {
    const ModelComponent = itemModels[hoveredItem];
    const ref = useRef();
  
    useFrame(() => {
      if (ref.current) {
        ref.current.rotation.y += 0.01; // Adjust rotation speed as needed
      }
    });
  
    return ModelComponent ? <group scale={0.1} ref={ref}><ModelComponent /></group> : null;
};

export const ItemDetailsCardExpandedMobile = ({ details = [], setHoveredGroup, setHoveredItemDetails, setSelectedObject, isDarkMode, onClose, onNext, onPrev, currentIndex = 0 }) => {
    const [activeTab, setActiveTab] = useState('about');
    const [showInfoPopup, setShowInfoPopup] = useState(false);

    if (!details || details.length === 0) return null;
  
    const logoSrc = isDarkMode ? icon : icon2;
    const currentDetail = details[currentIndex];
  
    if (!currentDetail) return null;
  
    const handleCloseInfoPopup = () => {
        setShowInfoPopup(false);
    };
  
    return (
      <>
        <ExpandedBottomCardMobile isDarkMode={isDarkMode}>
          <CanvasWrapper style={{ width: '100vw', height: '225px', marginTop: '-25px' }}>
          <LeftArrow onClick={() => { 
               setHoveredGroup(null); 
            setSelectedObject(null); 
  
            setHoveredItemDetails(null); 
            onPrev(); 
         
          }}>◀</LeftArrow>
          <RightArrow onClick={() => { 
               setHoveredGroup(null); 
            setSelectedObject(null); 
            setHoveredItemDetails(null); 
            onNext(); 
         
          }}>▶</RightArrow>
            <Canvas style={{ width: '100vw', height: '100vh'}} camera={{ position: [2, 2, 2], fov: 50 }}>
              <ambientLight intensity={0.5} />
              <pointLight position={[10, 10, 10]} intensity={0.8} />
              <spotLight position={[-10, 10, -10]} angle={0.15} penumbra={1} intensity={1} castShadow />
              <spotLight position={[10, -10, 10]} angle={0.15} penumbra={1} intensity={1} castShadow />
              <group scale={2}>
                <EnvDesktop />
                <RotatingModel hoveredItem={currentDetail.group} />
              </group>
              <EffectComposer stencilBuffer disableNormalPass autoClear={false} multisampling={4}>
                <TiltShift2 samples={5} blur={0.1} />
              </EffectComposer>
            </Canvas>
          </CanvasWrapper>
          <ExpandedTopCardColumn >
            <Description>{currentDetail.name}</Description>
            <InfoIcon onClick={() => setShowInfoPopup(true)}><FaInfoCircle /></InfoIcon>
          </ExpandedTopCardColumn>
        </ExpandedBottomCardMobile>
        
        {showInfoPopup && (
          <InfoPopup isDarkMode={isDarkMode}>
            <PExit isDarkMode={isDarkMode} className='close-btn' onClick={handleCloseInfoPopup}>
              <ExitIconWrap
                whileHover={{ rotate: 180, transition: { duration: 0.25 } }}
                whileTap={{ rotate: 180, transition: { duration: 0.25 } }}
              >
                <ExitIcon whileHover={{ color: isDarkMode ? '#000' : '#fff', backgroundColor: isDarkMode ? '#fff' : '#000' }} />
              </ExitIconWrap>
            </PExit>
            <TabContainer isDarkMode={isDarkMode}>
              <Tab isActive={activeTab === 'about'} isDarkMode={isDarkMode} onClick={() => setActiveTab('about')}>About</Tab>
              <Tab isActive={activeTab === 'traits'} isDarkMode={isDarkMode} onClick={() => setActiveTab('traits')}>Traits</Tab>
              <Tab isActive={activeTab === 'details'} isDarkMode={isDarkMode} onClick={() => setActiveTab('details')}>Details</Tab>
            </TabContainer>
            {activeTab === 'about' && <About currentDetail={currentDetail} isDarkMode={isDarkMode} />}
            {activeTab === 'traits' && <Traits currentDetail={currentDetail} isDarkMode={isDarkMode} />}
            {activeTab === 'details' && <Details currentDetail={currentDetail} isDarkMode={isDarkMode} />}
          </InfoPopup>
        )}
      </>
    );
};
