import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { FaEthereum } from 'react-icons/fa';
import { FixedSizeGrid as Grid } from 'react-window';
import styled from 'styled-components';
import InfoPopup from '../InfoPopup.js';
import {
  Col1, Col2,
  FeaturedMainCard,
  MainCard, MainDescription,
  MainDescription2,
  MainImage, MainOwner,
  MainPrice, MainTitle,
  PlusButton,
  RarityMark,
  SmallText,
  TwoColumnSection,
  ViewDropsButton
} from './SplitViewElements.js';

const HorizontalScrollWrapper = styled(motion.div)`
  width: 100%;
  height: 45vh; /* Closer to the bottom of the screen */
  position: fixed;
  bottom: 0%; /* Adjusted to be closer to the bottom */
  display: flex;
  overflow-x: auto; /* Enable horizontal scroll */
  overflow-y: hidden;
  padding: 10px;
  box-sizing: border-box;
  z-index: 999;
`;

const FeaturedMainCardWrapper = styled.div`
  margin-top: 30px;
  transform: scale(0.9);
`;

const MainCardWrapper = styled.div`
  margin-top: 50px;
`;

export const SplitCardViewMobile = ({ isDarkMode, tempGeneralData, isFilterVisible }) => {
  const isMobile = window.innerWidth <= 600;
  const [selectedCard, setSelectedCard] = useState(null);

  const featuredMain = tempGeneralData[0];
  const otherMains = tempGeneralData.slice(1); // The rest are main cards

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleClosePopup = () => {
    setSelectedCard(null);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === 'blur-background') {
      handleClosePopup();
    }
  };

  const columnCount = tempGeneralData.length; // Ensure the number of columns matches data length
  const rowHeight = 350; // Height for each row (card)
  const columnWidth = window.innerWidth / 3; // Set a default column width
  const featuredCardWidth = window.innerWidth; // Full width for the featured card
  const widthDifference = featuredCardWidth - columnWidth; // Calculate the width difference
  const extraShift = 150; // Additional shift to the right by 50px

  return (
    <>
      {selectedCard && (
        <InfoPopup
          selectedCard={selectedCard}
          handleClosePopup={handleClosePopup}
          handleOutsideClick={handleOutsideClick}
          isDarkMode={isDarkMode}
        />
      )}

      <HorizontalScrollWrapper>
        <Grid
          columnCount={columnCount}
          columnWidth={columnWidth} // Set the default column width
          height={rowHeight}
          rowCount={1} // Single row for horizontal scrolling
          rowHeight={rowHeight}
          width={window.innerWidth}
        >
          {({ columnIndex, style }) => {
            let content;

            // Adjust the width for the featured card (first column)
            const adjustedStyle = {
              ...style,
              width: columnIndex === 0 ? featuredCardWidth : columnWidth, // Make the first column wider
              marginLeft: columnIndex >= 1 ? `${widthDifference + -20}px` : '0px', // Offset every card after the featured one by the width difference plus 50px
            };



            if (columnIndex === 0) {
              // First item is the featured card
              content = (
                <FeaturedMainCardWrapper key="featured">
                  <FeaturedMainCard isDarkMode={isDarkMode}>
                    <MainImage src={featuredMain.logo} alt="Featured Main Logo" />
                    <MainTitle>{featuredMain.header}</MainTitle>
                    <MainDescription>{featuredMain.name}</MainDescription>
                    <MainDescription>{featuredMain.description}</MainDescription>
                    <TwoColumnSection>
                      <Col1>
                        <MainDescription2>{featuredMain.ethPrice} <FaEthereum /></MainDescription2>
                        <MainDescription>Owner: <a href={`https://etherscan.io/address/${featuredMain.owner}`} style={{ color: 'lightblue' }}>{featuredMain.owner}</a></MainDescription>
                        <MainDescription>Listed Time: {featuredMain.listedTime}</MainDescription>
                      </Col1>
                      <Col2>
                        <MainDescription>Last Sold: {featuredMain.lastSold} <FaEthereum /></MainDescription>
                        <MainDescription>RARITY <RarityMark rarity={featuredMain.rarityLevel} isDarkMode={isDarkMode}>#{featuredMain.rarity}</RarityMark></MainDescription>
                        <MainDescription>Floor Difference: {featuredMain.floorDifference}</MainDescription>
                      </Col2>
                    </TwoColumnSection>
                    <ViewDropsButton isDarkMode={isDarkMode}>View Latest Drops</ViewDropsButton>
                  </FeaturedMainCard>
                </FeaturedMainCardWrapper>
              );
            } else {
              const adjustedIndex = columnIndex - 1; // Adjust for the featured card
              const main = otherMains[adjustedIndex];

              if (!main) return null;

              content = (
                <MainCardWrapper key={main.id} style={{ marginLeft: '5px' }}>
                  <MainCard isMobile={isMobile} isDarkMode={isDarkMode}>
                    <MainImage src={main.logo} alt="Main Logo" />
                    <div>
                      <span>
                        <MainOwner>Owner: <a href={`https://etherscan.io/address/${main.owner}`} style={{ color: 'lightblue' }}>{main.owner}</a></MainOwner>
                        <SmallText>RARITY <RarityMark rarity={main.rarityLevel} isDarkMode={isDarkMode}>#{main.rarity}</RarityMark></SmallText>
                        <MainPrice>{main.ethPrice} <FaEthereum /></MainPrice>
                        <MainDescription style={{ fontSize: '0.8em', color: '#999' }}>Last sale: {main.lastSold} <FaEthereum /></MainDescription>
                        <SmallText>{main.listedTime}</SmallText>
                      </span>
                    </div>
                    <PlusButton onClick={() => handleCardClick(main)} />
                  </MainCard>
                </MainCardWrapper>
              );
            }

            return <div style={adjustedStyle}>{content}</div>;
          }}
        </Grid>
      </HorizontalScrollWrapper>
    </>
  );
};



