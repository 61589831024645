// InfoPopupElements.js
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const BlurBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 9999;
`;

export const InfoPopupContainer = styled.div`
  background: ${({ isDarkMode }) => isDarkMode ? '#333' : '#fff'};
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  border-radius: 10px;
  padding: 20px;
  width: 70vw;
  height: 90vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  overflow-x: hidden;
`;

export const CloseIcon = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5em;
  cursor: pointer;
  z-index: 99999999999;
`;

export const InfoPopupImage = styled.img`
  width: 100%;
  height: 70%;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
  overflow: hidden; /* Ensure the image does not overflow */
`;

export const InfoPopupTitle = styled.h3`
  margin: 5px 0;
  font-size: 1.1em;
`;

export const InfoPopupDescription = styled.p`
  color: ${({ isDarkMode }) => isDarkMode ? '#ddd' : '#666'};
  font-size: 0.9em;
  margin: 2px 0;
`;

export const InfoPopupPrice = styled.p`
  font-weight: bold;
  margin: 4px 0;
  display: flex;
  align-items: center;
  font-size: 0.9em;
`;

export const InfoPopupOwner = styled.p`
  font-size: 0.8em;
  color: ${({ isDarkMode }) => isDarkMode ? '#aaa' : '#888'};
  margin: 4px 0;
`;

export const SmallText = styled.span`
  font-size: 0.7em;
  color: ${({ isDarkMode }) => isDarkMode ? '#ccc' : '#999'};
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
`;

export const RarityMark = styled.mark`
  margin: 0 0.4em;
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  font-size: 0.6em; /* Smaller font size for the rarity value */
  background-image: ${({ rarity }) => {
    switch (rarity) {
      case 'legendary':
        return 'linear-gradient(to right, rgba(255, 225, 0, 0.1), rgba(255, 225, 0, 0.7) 4%, rgba(255, 180, 0, 0.3))';
      case 'epic':
        return 'linear-gradient(to right, rgba(255, 180, 0, 0.1), rgba(255, 127, 0, 0.7) 4%, rgba(255, 127, 0, 0.3))';
      case 'rare':
        return 'linear-gradient(to right, rgba(255, 127, 0, 0.1), rgba(255, 64, 0, 0.7) 4%, rgba(255, 64, 0, 0.3))';
      case 'uncommon':
        return 'linear-gradient(to right, rgba(255, 64, 0, 0.1), rgba(255, 0, 0, 0.7) 4%, rgba(255, 0, 0, 0.3))';
      case 'common':
        return 'linear-gradient(to right, rgba(255, 0, 0, 0.1), rgba(192, 192, 192, 0.7) 4%, rgba(192, 192, 192, 0.3))';
      default:
        return 'linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.7) 4%, rgba(255, 255, 255, 0.3))';
    }
  }};
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
`;

export const TraitsTitle = styled(motion.h4)`
  font-size: 1.5em;
  margin-top: 20px;
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
`;

export const TraitDescription = styled(motion.p)`
  margin: 5px 0;
  color: ${({ isDarkMode }) => isDarkMode ? '#ddd' : '#666'};
`;

export const SectionTitle = styled(motion.h4)`
  font-size: 1.5em;
  margin-top: 30px;
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
`;

export const SectionContent = styled(motion.p)`
  margin: 10px 0;
  color: ${({ isDarkMode }) => isDarkMode ? '#ddd' : '#666'};
`;

export const GraphPlaceholder = styled(motion.div)`
  width: 100%;
  height: 200px;
  background-color: ${({ isDarkMode }) => isDarkMode ? '#444' : '#ddd'};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  margin-top: 20px;
`;
