import { motion } from 'framer-motion';
import { HiPlusCircle } from "react-icons/hi2";
import styled from 'styled-components';


export const TraitsTitle = styled(motion.h4)`
  font-size: 1.5em;
  margin-top: 20px;
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
`;

export const TraitDescription = styled(motion.p)`
  margin: 5px 0;
  color: ${({ isDarkMode }) => isDarkMode ? '#ddd' : '#666'};
`;

export const SectionTitle = styled(motion.h4)`
  font-size: 1.5em;
  margin-top: 30px;
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
`;

export const SectionContent = styled(motion.p)`
  margin: 10px 0;
  color: ${({ isDarkMode }) => isDarkMode ? '#ddd' : '#666'};
`;

export const GraphPlaceholder = styled(motion.div)`
  width: 100%;
  height: 200px;
  background-color: ${({ isDarkMode }) => isDarkMode ? '#444' : '#ddd'};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  margin-top: 20px;
`;


export const MiniGrid = styled.div`
  display: grid;
  gap: 10px;
  padding: 20px;
  grid-template-columns: repeat(8, 1fr); // 8 columns for mini cards

  @media (max-width: 1200px) {
    grid-template-columns: repeat(6, 1fr); // 6 columns when width is <= 1200px
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(4, 1fr); // 4 columns when width is <= 900px
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr); // 2 columns when width is <= 600px
  }
`;

export const MiniCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ isDarkMode }) => (isDarkMode ? '#333' : '#fff')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  //border: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ddd')};
  border-radius: 10px;
  font-size: small;
  overflow: hidden;
  min-width: 100px;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
  margin-left: 5px;
  margin-right: 5px;
  padding: 0px 10px;
  position: relative;
&:hover {
  box-shadow: 0px 2.5px 7.5px rgba(0, 0, 0, 0.3);
  border-radius: 10px; /* Ensure the shadow remains rounded */
}

& > img {
 // width: 100%;
 //height: 250px; /* Image takes up 70% of the MainCard height */
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}



& > div {
    padding: 5px 10px;
    margin-top: 0px;
    margin-bottom: -20px;
    //padding: 15px;
    font-size: small;
    align-items: center;
    justify-content: center;
    text-align: center;
  }


  @media screen and (max-width: 600px) {
   // max-width: 100px;
    margin: 0 auto; /* Center featured card on mobile */
    margin-top: 350px;
    min-width: 80px;
    max-width: 80px;
    padding: 0px 10px;

    & > img {

    //height: 150px; /* Image takes up 70% of the MainCard height */
   
    &:hover {
      transform: scale(1.05);
    }
  }

  & > div {
    padding: 0px 0px;
    //height: 75px; /* Content div takes up 30% of the MainCard height */
        font-size: 10px;
  }
  }
`;

export const MiniImage = styled.img`
  width: 100%;
  height: 50px;
  border-radius: 5px;
`;

export const MiniTitle = styled.h3`
  margin: 2px 0;
  font-size: 1em;
`;

export const MiniDescription = styled.p`
  color: ${({ isDarkMode }) => (isDarkMode ? '#ddd' : '#666')};
  margin: 2px 0;
`;

export const MiniPrice = styled.p`
  font-weight: 900;

  margin: 2px 0;
`;

export const MiniOwner = styled.p`
  font-size: 0.9em;
  color: ${({ isDarkMode }) => (isDarkMode ? '#aaa' : '#888')};
  margin: 2px 0;
`;

export const MiniCollectionTitle = styled.h2`
  font-size: 2em;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  text-align: left;
  margin: 0;
`;

export const MiniCollectionSubheader = styled.h3`
  font-size: 1.5em;
  color: ${({ isDarkMode }) => (isDarkMode ? '#aaa' : '#666')};
  text-align: left;
  margin: 0;
`;

export const CollectionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: ${({ isMobile }) => isMobile ? '12.5vh' : '12.5vh'};
  white-space: nowrap;
  margin-left: 5%;
  @media screen and (max-width: 600px) {
   // grid-template-columns: 1fr;
   // max-width: 300px;
   font-size: 0.8rem;

  }
`;

export const FeaturedMiniCard = styled(MiniCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
 // margin-top: 160px;
  width: 100%;
  min-width: 100%;
`;

export const ViewDropsButton = styled.button`
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ddd')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  margin-top: 20px;
`;

export const SmallText = styled.span`
  font-size: 0.8em;
  color: ${({ isDarkMode }) => (isDarkMode ? '#ccc' : '#999')};
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 10px;
`;

export const PlusButton= styled(HiPlusCircle)`
  //background-color: ${({ isDarkMode }) => (isDarkMode ? '#555' : '#ddd')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  border: none;
  border-radius: 100%;

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  cursor: pointer;
margin-top: 15px;
&:hover {
  transform: scale(1.1);
}
@media screen and (max-width: 600px) {
  margin-top: 20px;
  font-size: 1em;
  
}
`;





export const MiniPopup = styled.div`
  background: ${({ isDarkMode }) => (isDarkMode ? '#333' : '#fff')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#333')};
  border-radius: 10px;
  padding: 20px;
  width: 70vw;
  height: 90vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
`;

export const BlurBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 9999;
`;

export const CloseIcon = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5em;
  cursor: pointer;
  z-index: 99999999999;
`;

export const RarityMark = styled.mark`
  margin: 0 5px;
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  font-size: 0.8em;
  background-image: ${({ rarity }) => {
    switch (rarity) {
      case 'legendary':
        return 'linear-gradient(to right, rgba(255, 225, 0, 0.1), rgba(255, 225, 0, 0.7) 4%, rgba(255, 180, 0, 0.3))';
      case 'epic':
        return 'linear-gradient(to right, rgba(255, 180, 0, 0.1), rgba(255, 127, 0, 0.7) 4%, rgba(255, 127, 0, 0.3))';
      case 'rare':
        return 'linear-gradient(to right, rgba(255, 127, 0, 0.1), rgba(255, 64, 0, 0.7) 4%, rgba(255, 64, 0, 0.3))';
      case 'uncommon':
        return 'linear-gradient(to right, rgba(255, 64, 0, 0.1), rgba(255, 0, 0, 0.7) 4%, rgba(255, 0, 0, 0.3))';
      case 'common':
        return 'linear-gradient(to right, rgba(255, 0, 0, 0.1), rgba(192, 192, 192, 0.7) 4%, rgba(192, 192, 192, 0.3))';
      default:
        return 'linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.7) 4%, rgba(255, 255, 255, 0.3))';
    }
  }};
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
`;
