import React, { useState } from 'react';
import { FaEthereum } from 'react-icons/fa';
import { FixedSizeGrid as Grid } from 'react-window';
import InfoPopup from '../InfoPopup.js';
import {
  AnimatedCollectionWrapper,
  Col1, Col2,
  CollectionHeaderWrapper,
  FeaturedMainCard,
  GridItemWrapper,
  MainCard, MainCollectionSubheader, MainCollectionTitle, MainDescription,
  MainDescription2,
  MainImage, MainOwner,
  MainPrice, MainTitle,
  PlusButton,
  RarityMark,
  SmallText,
  TwoColumnSection,
  ViewDropsButton
} from './SplitViewElements.js';

export const SplitCardView = ({ isDarkMode, tempGeneralData, isFilterVisible }) => {
  const isMobile = window.innerWidth <= 600;
  const [selectedCard, setSelectedCard] = useState(null);

  const featuredMain = tempGeneralData[0];
  const otherMains = tempGeneralData.slice(1);

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleClosePopup = () => {
    setSelectedCard(null);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === 'blur-background') {
      handleClosePopup();
    }
  };

  const columnCount = isMobile ? 1 : window.innerWidth <= 1200 ? 2 : 3;
  const rowCount = Math.ceil((otherMains.length) / columnCount) + 2;
  const columnWidth = isMobile ? window.innerWidth : window.innerWidth * 0.4 / columnCount;

  return (
    <>
      {selectedCard && (
        <InfoPopup
          selectedCard={selectedCard}
          handleClosePopup={handleClosePopup}
          handleOutsideClick={handleOutsideClick}
          isDarkMode={isDarkMode}
        />
      )}

      <AnimatedCollectionWrapper
        isMobile={isMobile}
        initial={{ height: '85vh' }}
        animate={{ height: (isFilterVisible && !isMobile) ? '77.5vh' : '85vh' }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        <Grid
          columnCount={columnCount}
          columnWidth={columnWidth}
          height={isMobile ? window.innerHeight - 100 : window.innerHeight - 100}
          rowCount={rowCount}
          rowHeight={350} // Adjusted for max height of the card
          width={isMobile ? window.innerWidth : columnWidth * columnCount}
          style={{ overflowX: 'hidden', margin: '0 auto' }} // Center the grid and hide horizontal overflow
        >
          {({ columnIndex, rowIndex, style }) => {
            let content;

            if (rowIndex === 0 && columnIndex === 0) {
              content = (
                <GridItemWrapper key="header">
                <CollectionHeaderWrapper isMobile={isMobile}>
                    <MainCollectionTitle isDarkMode={isDarkMode}>Our Collection</MainCollectionTitle>
                    <MainCollectionSubheader isDarkMode={isDarkMode}>
                      Explore the latest and greatest
                    </MainCollectionSubheader>
                  </CollectionHeaderWrapper>
                </GridItemWrapper>
              );
            } else if (rowIndex === 1 && columnIndex === 0) {
              content = (
                <>
                  <FeaturedMainCard isDarkMode={isDarkMode}>
                    <MainImage src={featuredMain.logo} alt="Featured Main Logo" />
                    <MainTitle>{featuredMain.header}</MainTitle>
                    <MainDescription>{featuredMain.name}</MainDescription>
                    <MainDescription>{featuredMain.description}</MainDescription>
                    <TwoColumnSection>
                      <Col1>
                        <MainDescription2>{featuredMain.ethPrice} <FaEthereum /></MainDescription2>
                        <MainDescription>Owner: <a href={`https://etherscan.io/address/${featuredMain.owner}`} style={{ color: 'lightblue' }}>{featuredMain.owner}</a></MainDescription>
                        <MainDescription>Listed Time: {featuredMain.listedTime}</MainDescription>
                      </Col1>
                      <Col2>
                        <MainDescription>Last Sold: {featuredMain.lastSold} <FaEthereum /></MainDescription>
                        <MainDescription>RARITY <RarityMark rarity={featuredMain.rarityLevel} isDarkMode={isDarkMode}>#{featuredMain.rarity}</RarityMark></MainDescription>
                        <MainDescription>Floor Difference: {featuredMain.floorDifference}</MainDescription>
                      </Col2>
                    </TwoColumnSection>
                    <ViewDropsButton isDarkMode={isDarkMode}>View Latest Drops</ViewDropsButton>
                  </FeaturedMainCard>
                </>
              );
            } else {
              const adjustedIndex = rowIndex * columnCount + columnIndex - columnCount * 2;
              const main = otherMains[adjustedIndex];

              if (!main) return null;

              content = (
                <GridItemWrapper key={main.id} style={{ marginTop: '-60px' }}>
                  <MainCard isMobile={isMobile} isDarkMode={isDarkMode}>
                    <MainImage src={main.logo} alt="Main Logo" />
                    <div>
                      <span>
                        <MainOwner>Owner: <a href={`https://etherscan.io/address/${main.owner}`} style={{ color: 'lightblue' }}>{main.owner}</a></MainOwner>
                        <SmallText>RARITY <RarityMark rarity={main.rarityLevel} isDarkMode={isDarkMode}>#{main.rarity}</RarityMark></SmallText>
                        <MainPrice>{main.ethPrice} <FaEthereum /></MainPrice>
                        <MainDescription style={{ fontSize: '0.8em', color: '#999' }}>Last sale: {main.lastSold} <FaEthereum /></MainDescription>
                        <SmallText>{main.listedTime}</SmallText>
                      </span>
                    </div>
                    <PlusButton onClick={() => handleCardClick(main)} />
                  </MainCard>
                </GridItemWrapper>
              );
            }

            return <div style={style}>{content}</div>;
          }}
        </Grid>
      </AnimatedCollectionWrapper>
    </>
  );
};
