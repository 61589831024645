import { PerspectiveCamera, Scroll, ScrollControls, useScroll } from "@react-three/drei";
import { useFrame, useThree } from '@react-three/fiber';
import { val } from "@theatre/core";
import { SheetProvider, editable as e } from "@theatre/r3f";
import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import * as THREE from 'three';
import './Plan.css';
//import { Mannequin } from "./Mannequin";
import w3oBlackLogo from '../../images/w3oBlackLogo.png';
import {
  Mouse,
  MouseBody,
  Paragraph
} from "../../pages/JoinDao/DaoOverlay/OverlayElements";
import { AnimateBubbles, ButtonText, Icon, JoinTeam, TextSpan } from "../Global/GlobalElements";
import { plan1, plan2, plan3, plan4, plan5 } from './Data';
import Mannequin from "./WalkingMannequin";
import { WhiteMannequin } from "./WalkingMannequinWhite";

const EditableCamera = e(PerspectiveCamera, 'perspectiveCamera')
const fadeIn = keyframes`
  0% { opacity: 0; transform: translateY(15px) blur(5px); }
  100% { opacity: 1; transform: translateY(0) blur(0); }
`;

const TextSection = styled.div`
  margin-top: 3rem;
  transition: transform 1s;
  transform-style: preserve-3d;
  margin-top: ${(props) => (props.largeMargin ? '8rem' : '3rem')};
  @media screen and (max-width: 1024px) {
    margin-top: 2rem;
    transform: scale(0.8);
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

`;

const FlippedLogo = styled.div`
  background-image: url(${w3oBlackLogo});
  background-size: cover;
  width: 40px;
  height: 40px;
  opacity: 0;
  transform: translateY(15px) rotateX(90deg) rotateZ(0deg);
  transition: opacity 1s, transform 1s;
  @media screen and (max-width: 1024px) {
    width: 35px;
    height: 35px;
  }
`;

const LineBetween = styled.div`
  width: 1px;
  height: 50px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#e2e8f0' : '#2d3748')};
  margin: 0 10px;
  opacity: 0;

  transform: translateY(15px) rotateX(90deg) rotateZ(0deg) scaleY(0.7);
  transition: opacity 1s, transform 1s;
`;

const TitleSubheaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  opacity: 0;
  transform: translateY(15px) rotateX(90deg) rotateZ(0deg) scaleY(0.7);
  transition: opacity 1s, transform 1s;
`;

const Title = styled.h3`
  color: ${({ isDarkMode }) => (isDarkMode ? '#e2e8f0' : '#2d3748')};
  font-size: 3rem;
  font-weight: 900;
  margin: 0;
  letter-spacing: -2px;
  text-transform: uppercase;
  @media screen and (max-width: 1024px) {
    font-size: 2rem;
    max-width: 300px;
  }
`;

const Subheader = styled.p`
  color: ${({ isDarkMode }) => (isDarkMode ? '#a1a1a1' : '#010906')};
  font-size: 2.5rem; /* Make the subheader the same size as the title */
  margin: 0; /* Remove top and bottom margin */
  font-weight: 900;
  letter-spacing: -2px;
  text-transform: uppercase;
  background: ${({ isDarkMode }) => 
    isDarkMode 
      ? 'linear-gradient(90deg, #101010 0%, #2d3748 25%, #4a4a4a 50%, #4a4a4a 75%, #101010 100%)' 
      : 'linear-gradient(90deg, #101010 0%, #4a4a4a 25%, #4a4a4a 50%, #2d3748 75%, #101010 100%)'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 1024px) {
    font-size: 2rem; /* Adjust for smaller screens */
  }
`;

const Line = styled.div`
  width: 75%;
  height: 1px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#e2e8f0' : '#2d3748')};
  margin: 5px 0 20px 0;
  opacity: 0;
  transform: translateY(-35px) rotateX(90deg) rotateZ(0deg);
  transition: opacity 1s, transform 1s, width 1s;
`;


const ItemSection = styled.div`
  position: relative;
  margin-top: -30px;
 // opacity: 0;
`;



const Item = styled.ul`
 // text-transform: uppercase;
  margin-bottom: 10px; // Add spacing between items
  margin-left: 5%;
  padding-left: 30px;
  font-size: 20px;
  font-weight: 600;
  line-height: 15px;
  white-space: nowrap;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  word-wrap: break-word;
  transform: translateY(15px) blur(5px) rotateZ(0deg);
  transition: opacity 1s, transform 1s, filter 1s;
  text-indent: -20px; 
  &:before {
    content: '◆';
    display: inline-block;
    width: 12px;
    height: 12px;
    left: 0;
    transform: translateY(25%);
    margin-right: 12px;
  }

  @media screen and (max-width: 1024px) {
    line-height: 12.5px;
    font-size: 9.5px;
    margin-bottom: 10px;
  }
`;



const ScreenContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyEnd }) => justifyEnd ? 'flex-end' : 'flex-start'};
  padding: 0 10%; // Add some padding on both sides

  @media screen and (max-width: 1024px) {
 //   justify-content: center;
  }
`;

const ScreenContainer2 = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyEnd }) => justifyEnd ? 'flex-end' : 'flex-start'};
  margin-left: ${({ justifyEnd }) => justifyEnd ? '-7.5%' : '7.5%'};
  padding: 0 5%;

  @media screen and (max-width: 1024px) {
    margin-left: 2.5%;
    margin-left: ${({ justifyEnd }) => justifyEnd ? '0%' : '0%'};
  }
`;

const PageContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column; // Stack children vertically
  align-items: flex-start; // Align text to the left
  justify-content: center; // Center vertically in the viewport
  height: 100vh;
  text-align: center;
`;

const ButtonWrap = styled.div`
  display: grid;
  position: absolute;
`;

const LargeTextSection = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 1024px) {
    transform: translate(-50%, -50%) scale(0.7);
  }
`;

const LargeText = styled.span`
  font-size: 6rem;
  color: #cbd5e1;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 1024px) {
    font-size: 3rem;
  }
`;

const MediumText2 = styled.span`
  font-size: 2.5rem;
  color: #666;
  text-align: center;
  @media screen and (max-width: 600px) {
    min-width: 400px;
    font-size: 1.5rem;
  }
`;

const MediumText = styled.span`
  font-size: 2.5rem;
  color: #666;
  width: 100%;
  text-align: center;
  @media screen and (max-width: 1024px) {
    font-size: 1rem;
  }
`;

const Button = styled.span`
  margin-top: 2.5rem;
  padding: 0.5rem 1.25rem;
  background-color: #e2e8f0;
  color: #4b5563;
  font-weight: normal;
  border-radius: 0.375rem;
`;

const RoadMapContainer = styled.div`
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    top: 10%;
  }
`;

const StyledJoinTeam = styled(JoinTeam)`
  margin-top: 25px;
  position: relative;
  @media screen and (max-width: 600px) {
    margin-top: 25px;
  }
`;

const RoadMapPart = styled.span`
  font-size: 100px;
  color: ${({ isDarkMode }) => (isDarkMode ? '#e2e8f0' : '#2d3748')};
  white-space: nowrap;
  opacity: 0;
  animation: ${({ animation }) => animation} 3s forwards;

  &:first-child {
    margin-right: -4rem;
  }

  @keyframes fadeInSlideRight {
    0% { opacity: 0; transform: translateX(0); }
    50% { opacity: 1; transform: translateX(0); }
    100% { opacity: 1; transform: translateX(-6rem); }
  }

  @keyframes fadeIn {
    0% { opacity: 0; transform: translateX(0); }
    50% { opacity: 1; transform: translateX(0); }
    100% { opacity: 1; transform: translateX(6rem); }
  }

  @media (max-width: 600px) {
    font-size: 35px;
    &:first-child {
      margin-right: -1.5rem;
    }

    @keyframes fadeInSlideRight {
      0% { opacity: 0; transform: translateX(0); }
      50% { opacity: 1; transform: translateX(0); }
      100% { opacity: 1; transform: translateX(-3rem); }
    }

    @keyframes fadeIn {
      0% { opacity: 0; transform: translateX(0); }
      50% { opacity: 1; transform: translateX(0); }
      100% { opacity: 1; transform: translateX(3rem); }
    }
  }
`;

const ZIndexWrapper = styled.div`
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
`;

const ScrollAnimatedTextSection = ({ plan, isDarkMode, index }) => {
  const scrollRef = useRef();
  const [progress, setProgress] = useState(0);
  const { offset } = useScroll();

  useFrame(() => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) return;

    const { top, height } = scrollElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    const start = top + window.scrollY - windowHeight;
    const end = start + height * 0.95;
    const scrollProgress = Math.min(Math.max(0, (window.scrollY - start) / (end - start)), 1);
    setProgress(scrollProgress);
  });

  const applyStyle = (baseProgress, threshold, element, additionalDelay = 0) => {
    const delay = threshold + additionalDelay;
    const progressValue = Math.min(Math.max(0, (progress - delay) / (1 - delay)), 1);
    const zRotation = 10 * (1 - progressValue);
    const isMobile = window.innerWidth <= 600;
    const translateY = isMobile ? 17.5 : 20; // Adjust translateY for mobile
  
    if (element.classList.contains('line')) {
      element.style.opacity = progressValue.toString();
      element.style.transform = `translateY(-${translateY}px) rotateX(${90 * (1 - progressValue)}deg) rotateZ(${zRotation}deg) `;
      element.style.width = progressValue > 0.4 ? '75%' : '0';
    } else if ((element.classList.contains('title-subheader-wrapper')) || (element.classList.contains('line-between')))  {
      element.style.opacity = progressValue.toString();
      element.style.transform = `translateY(${(1 - progressValue) * 15}px) rotateX(${90 * (1 - progressValue)}deg) rotateZ(${zRotation}deg) scaleY(0.75)`;
    } else {
      element.style.opacity = progressValue.toString();
      element.style.transform = `translateY(${(1 - progressValue) * 15}px) rotateX(${90 * (1 - progressValue)}deg) rotateZ(${zRotation}deg)`;
    }
  };
  

  useFrame(() => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) return;

    const numberTitle = scrollElement.querySelector('.numbered-title');
    const lineBetween = scrollElement.querySelector('.line-between');
    const titleWrapper = scrollElement.querySelector('.title-subheader-wrapper');
    const line = scrollElement.querySelector('.line');
    const items = scrollElement.querySelectorAll('.item');

    applyStyle(progress, 0.1, numberTitle);
    applyStyle(progress, 0.2, lineBetween);
    applyStyle(progress, 0.3, titleWrapper);
    applyStyle(progress, 0.4, line, 0.1);

    items.forEach((item, idx) => {
      applyStyle(progress, 0.5 + idx * 0.05, item);
    });
  });

  return (
    <TextSection ref={scrollRef} largeMargin={plan.id === 'plan1'}>
      <TitleWrapper>
        <FlippedLogo className="numbered-title" isDarkMode={isDarkMode}/>
     
    
        <LineBetween className="line-between" isDarkMode={isDarkMode} />
        <TitleSubheaderWrapper className="title-subheader-wrapper">
          <Title isDarkMode={isDarkMode}>
            {plan.title}
          </Title>
          <Subheader isDarkMode={isDarkMode}>
            {plan.subHeader}
          </Subheader>
        </TitleSubheaderWrapper>
      </TitleWrapper>
      <Line className="line" isDarkMode={isDarkMode} />
      <ItemSection>
        {plan.items.map((item, idx) => (
          <Item className="item" isDarkMode={isDarkMode} key={idx}>
            {item}
          </Item>
        ))}
      </ItemSection>
    </TextSection>
  );
};

const AnimatedLargeTextSection = ({ isDarkMode }) => {
  const scrollRef = useRef();
  const [style, setStyle] = useState({ opacity: 0, transform: 'translateY(-100px)' });

  useFrame(() => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) return;

    const { top, height } = scrollElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const start = top + window.scrollY - windowHeight;
    const end = start + height * 0.8;
    const progress = Math.min(Math.max(0, (window.scrollY - start) / (end - start)), 1);

    setStyle({
      width: '80%',
      opacity: progress,
      marginTop: progress,
      transform: `translateY(${(1 - progress) * 100}px)`
    });
  });

  return (
    <div >
      <PageContainer >
        <LargeTextSection isDarkMode={isDarkMode}>
          <span>--</span>
          <MediumText2 ref={scrollRef} style={style}  >
            UNLEASH YOUR POTENTIAL IN A SYMPHONY OF INNOVATION
          </MediumText2>
          <span>--</span>
        </LargeTextSection>
      </PageContainer></div>
  );
};

const AnimatedContentContainer = ({ isDarkMode, setDaoPopup }) => {
  const scrollRef = useRef();
  const [style, setStyle] = useState({ opacity: 0, transform: 'translateY(-100px)' });
  const [buttonHovered, setButtonHovered] = useState(false);

  useFrame(() => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) return;

    const { top, height } = scrollElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const start = top + window.scrollY - windowHeight;
    const end = start + height * 0.8;
    const progress = Math.min(Math.max(0, (window.scrollY - start) / (end - start)), 1);

    setStyle({
      opacity: progress,
      marginTop: progress * 2,
      transform: `translateY(${(1 - progress) * 1000}px)`
    });
  });

  return (
    <ContentContainer ref={scrollRef} >
      <LargeText style={style} isDarkMode={isDarkMode}>Join Our DAO</LargeText>
      <MediumText style={style} isDarkMode={isDarkMode}>
        Your creativity awaits to be explored. Come join a team of greatness and bring reality to your imagination.
      </MediumText>
      <StyledJoinTeam isDarkMode={isDarkMode}>
        <AnimateBubbles
          style={style}
          isDarkMode={isDarkMode}
          primary="true"
          dark="true"
          fontBig="true"
          onClick={() => { setDaoPopup(true) }}
          className={buttonHovered ? 'animate' : ''}
          onMouseEnter={() => setButtonHovered(true)}
          onMouseLeave={() => setButtonHovered(false)}
        >
          <ButtonText>
            <TextSpan isDarkMode={isDarkMode} style={{ marginLeft: '0px' }}>JOIN TEAM</TextSpan>
            <Icon isDarkMode={isDarkMode} />
          </ButtonText>
        </AnimateBubbles>
      </StyledJoinTeam>
    </ContentContainer>
  );
};

const World = ({ project, isDarkMode, setDaoPopup, mannequinKey }) => {
  const w = window.innerWidth;
  const isMobile = w <= 600;
  const sheet = project.sheet('world');
  
  const [buttonHovered, setButtonHovered] = useState(false);
  const [timedDisplay, setTimedDisplay] = useState(true);
  const scroll = useScroll();
  const [scrollExplore, setScrollExplore] = useState(false);
  const { camera } = useThree();
  const mannequinRef = useRef();

  const perspectiveCameraProps = { position: [0, 0, 0] };



  return (
    <>
      <ScrollControls damping={0} eps={0.0004} pages={8}>
        <Scroll html style={{ zIndex: '-9999999' }} >
          {timedDisplay &&
            (<MouseBody
              style={{
                position: 'absolute',
                top: isMobile ? '65vh' : '68.5vh',
                opacity: scrollExplore > 0 ? 0 : 1,
                transition: 'opacity 0.5s ease',
              }}
            >
              <Paragraph>Scroll to explore!</Paragraph>
              <Mouse className="mouse" />
            </MouseBody>)
          }
          <ScreenContainer>
            <RoadMapContainer>
              <RoadMapPart isDarkMode={isDarkMode} animation="fadeInSlideRight">MASTER</RoadMapPart>
              <RoadMapPart isDarkMode={isDarkMode} animation="fadeIn">PLAN</RoadMapPart>
            </RoadMapContainer>
          </ScreenContainer>
          {[plan1, plan2, plan3, plan4, plan5].map((plan, index) => (
            <ScreenContainer2 key={plan.id} justifyEnd={index % 2 === 1}>
              <ScrollAnimatedTextSection
                plan={plan}
                isDarkMode={isDarkMode}
                index={index}
                totalSections={5}
              />
            </ScreenContainer2>
          ))}
          <AnimatedLargeTextSection />
          <ScreenContainer>
            <AnimatedContentContainer setDaoPopup={setDaoPopup} />
          </ScreenContainer>
        </Scroll>
        <SheetProvider style={{ zIndex: '9999999' }} sheet={sheet}>
        <EditableCamera
          theatreKey="camera"
          makeDefault
          {...perspectiveCameraProps}

 
        />
          <Blocks
          sheet={sheet}
          mannequinKey={mannequinKey}
            isDarkMode={isDarkMode}
            scroll={scroll}
            setTimedDisplay={setTimedDisplay}
            scrollExplore={scrollExplore}
            setScrollExplore={setScrollExplore}
            mannequinRef={mannequinRef}
          />
        </SheetProvider>
      </ScrollControls>
    </>
  );
}

const Blocks = React.memo(({ isDarkMode, mannequinRef, setTimedDisplay, scrollExplore, setScrollExplore, sheet, mannequinKey }) => {
  const scroll = useScroll();
 // const sheet = useCurrentSheet();
 const { camera, scene } = useThree();
  useEffect(() => {
    const offset = scroll.offset;
    if (offset < 1) {
      const exploreTimer = setTimeout(() => {
        setScrollExplore(true);
      }, 1000);

      const displayTimer = setTimeout(() => {
        setTimedDisplay(false);
      }, 1500);

      return () => {
        clearTimeout(exploreTimer);
        clearTimeout(displayTimer);
      };
    }
  }, [scroll.offset, scrollExplore, setScrollExplore, setTimedDisplay]);

  useFrame(() => {
    const sequenceLength = val(sheet.sequence.pointer.length);
    sheet.sequence.position = scroll.offset * sequenceLength;

    // Ensure the camera is always facing the mannequin
    const mannequinPosition = mannequinRef.current?.position || new THREE.Vector3(0, 0, 0);
    camera.lookAt(mannequinPosition); // Adjust the camera to look at the mannequin
  });

  return (
    <>
      {isDarkMode ? (
        <Mannequin ref={mannequinRef} mannequinKey="mannequin" isDarkMode={isDarkMode}  />
      ) : (
        <WhiteMannequin ref={mannequinRef} mannequinKey="mannequin" isDarkMode={isDarkMode}   />
      )}
    </>
  );
});

export default World;
