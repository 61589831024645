import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FilterContainer = styled(motion.div)`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ isDarkMode }) => (isDarkMode ? 'rgba(0, 0, 0, 1)' : 'rgba(256, 256, 256, 1)')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  padding: 20px;
  //box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: scroll;
  z-index: 99999999999;
`;

export const FilterWrapper = styled(motion.div)`
  width: 80%;
  height: 100%;
  position: absolute;
  top: 12.5%;
  left: 50%;
  transform: translateX(-50%);
`;

export const FilterSection = styled.div`
  margin-bottom: 20px;
`;

export const FilterTitle = styled.h3`
  margin-bottom: 10px;
`;

export const Dropdown = styled.select`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ccc')};
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#222' : '#f9f9f9')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
`;

export const DropdownOption = styled.option`
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#333' : '#fff')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const PriceRangeInput = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ccc')};
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#222' : '#f9f9f9')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
`;

export const RarityRangeInput = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ccc')};
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#222' : '#f9f9f9')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
`;

export const ApplyButton = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: gold;
  color: black;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: darkorange;
  }
`;





export const FilterDropdown = styled.select`
  width: 100%;
  padding: 8px;
  background-color: ${({ isDarkMode }) => isDarkMode ? '#444' : '#fff'};
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  border: 1px solid ${({ isDarkMode }) => isDarkMode ? '#666' : '#ddd'};
  border-radius: 4px;
`;

export const FilterInput = styled.input`
  width: calc(50% - 10px);
  padding: 8px;
  margin-right: 10px;
  background-color: ${({ isDarkMode }) => isDarkMode ? '#444' : '#fff'};
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  border: 1px solid ${({ isDarkMode }) => isDarkMode ? '#666' : '#ddd'};
  border-radius: 4px;
`;






// Container for the checkerboard
export const CheckerBoardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.size}, 1fr);
  width: 100%;
  max-width: 600px; /* Adjust the max-width to control the size of the checkerboard */
  margin: 0 auto;
  border: 2px solid #333; /* Add a border around the checkerboard */
`;

// Individual square on the checkerboard
export const CheckerSquare = styled.div`
  width: 100%;
  padding-bottom: 100%; /* Ensures the square is always a square */
  background-color: ${props => (props.isBlack ? '#333' : '#fff')};
`;




