import { motion } from 'framer-motion';
import { MdCancel } from 'react-icons/md';
import styled from 'styled-components';

export const ProjectPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center; /* Center the profile image vertically */
  z-index: 9999999999;
  width: 80%;
`;

export const ProjectGraphic = styled.img`
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-right: 20px;
  object-fit: cover;
  border-radius: 100%;
`;

export const ProjectDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const ProjectTitle = styled.h2`
  margin-bottom: 10px;
  text-align: left;
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  margin-left: 10px;

  svg {
    margin-left: 5px;
    cursor: pointer;
    color: #6c757d; /* Dark silver/gray */

    &:hover {
      color: #adb5bd; /* Lighter color on hover */
    }
  }
`;

export const ProjectDescription = styled.p`
  margin: 0px 0;
  text-align: left;

  & strong {
    font-size: 1.1rem; /* Slightly larger and bolder for first descriptions */
    font-weight: bold;
  }
`;

export const ProjectInfoGrid = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  &.smaller-text {
    font-size: 0.75rem; /* Even smaller text for better fit */
  }

  .dot-divider {
    margin: 0 5px; /* Reduced margin for closer columns */
    display: flex;
    align-items: center;
  }
`;

export const ProjectInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  & strong {
    font-size: 1rem; /* Strong text larger in second grid */
    font-weight: bolder;
  }
`;

export const ExitIcon = styled(MdCancel)` 
  cursor: pointer;
  border-radius: 100%;
  transition: transform 3300ms ease-in-out;
  font-size: 32px;
  transform-origin: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    transform: rotate(180deg);
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    filter: drop-shadow(0 0 5px white);
  }
`;

export const PExit = styled(motion.div)`
  position: absolute;
  right: 2.5%;
  top: 2.5%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};

  &:hover {
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  }
`;

export const ExitIconWrap = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  cursor: pointer;
`;
