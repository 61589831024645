import { motion } from 'framer-motion';
import { BsTools } from 'react-icons/bs';
import { RiLightbulbFlashFill } from "react-icons/ri";
import { TbSwitch2 } from 'react-icons/tb';
import styled, { keyframes } from 'styled-components';

export const PopUpText = styled.div`

  color: #000;
  height: 5px;
  color: #fff;
  width: 240px;
  width: 240px;
  text-align: center;
  line-height: 44px;
  border-radius: 3px;
  cursor: pointer;
`;

export const PopUpBox = styled.div`
  //position: absolute;
  margin-top: -75%;
  margin-left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 150px;
  padding: 5px 5px;
  border-radius: 20px;
  z-index: 99999999;
  position: absolute;
  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;

  &:before {
    content: "";
    width: 0;
    height: 0;
    left: 150px;
    top: 80px;
    position: absolute;

    z-index: 99999;
    border: 10px solid transparent;
    transform: rotate(315deg);
    transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;

    @media screen and (max-width: 1024px) {
    top: 125px;
    }
  }

  @media screen and (max-width: 1024px) {
    zoom: 0.5;
}

`;

export const PopUpCard = styled.div`
  position: relative;

`;
export const OverlayContainer = styled(motion.div)`
   
   
    width: 100%;
    justify-content: center;
    align-items: center;
    display: grid;
    //background: #FFFFFF;
    position: fixed;
   // padding: 0 50px;
   // overflow: hidden;
    //overflow: hidden;
    z-index: 99999;
   
    bottom: 10%;
    left: 0;
    right: 0;
    @media screen and (max-width: 870px) {
          //transform: scale(0.85);
          //zoom: 0.85;
          bottom: 11%;
    }
  
 // box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for a subtle effect */
`;

export const OverlayButtonWrapper = styled.div`
   
   display: flex;
    
    z-index: 1;
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
    justify-content: center;
 
    @media screen and (max-width: 1024px) {
      display: block;
    }

`
export const OverlayButtonRow = styled.div`


`
export const OverlaySwitchSpan = styled.span`
  
    position: absolute;
    margin-left: -20%;
    transform: translateX(-5%);

   
    color: black;
    margin-top: 10px;
    font-family: 'Inter';
    font-weight: 900;
    font-size: 12px;
    line-height: 26px;
    @media screen and (max-width: 1024px) {
  
      margin-left: 15%;
    }
    /* or 81% */
`

export const OverlaySwitchSpan2 = styled.span`
    
    white-space: nowrap;
    position: absolute;
    margin-left: 105%;
    transform: translateX(-5%);

   
    color: black;
    margin-top: 4px;
    color: black;
    font-family: 'Inter';
    font-weight: 900;
    font-size: 12px;
    line-height: 26px;
    @media screen and (max-width: 1024px) {
  
        margin-left: 68.5%;
}
    /* or 81% */
`
export const ModelSwitch = styled.div`
   position: absolute;
   //zoom: 0.85;
   left: 50%;
   transform: translateX(-50%) scale(0.95);

   & :hover + ${PopUpBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: 230px;
    padding: 8px 8px;

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
  @media screen and (max-width: 1024px) {
    transform: translateX(-50%) scale(0.65);
    margin-top: 0.5%;
      }
      
`;
export const ModelSwitchIcon = styled(TbSwitch2)`
 // background-color: #fff;
 
  border-radius: 100%;
  padding: 8px; /* Adjust the padding as per your requirements */
  margin-top: 5px;
  margin-left: 20px;
  transform: translateY(12.5px) matrix(1, 0, 0, 1.2, 0, 0);
  font-size: 40px; /* Adjust the font size as per your requirements */
 

      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    //display: flex;
    &:hover {
    background-color: #000;

      filter: drop-shadow(0 0 10px white);

}
@media screen and (max-width: 1024px) {
  &:hover {
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};

}
   }

`;

export const Roadmap = styled.div`
  position: absolute;
  right: 7.5%;
  //zoom: 0.85;
  transform: scale(0.85);
  & :hover + ${PopUpBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: 230px;
    padding: 8px 8px;

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
  @media screen and (max-width: 1024px) {
      //width: 175px;
      //zoom: 0.75;
      transform: scale(0.75);

        right: 10%;

   }
`;


export const RoadmapIcon = styled(RiLightbulbFlashFill)`
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
   
   border-radius: 50%;
   padding: 8px; /* Adjust the padding as per your requirements */
   margin-top: 5px;
   margin-left: 20px;
   transform: translateY(12.5px) matrix(1, 0, 0, 1.2, 0, 0);
   font-size: 40px; /* Adjust the font size as per your requirements */
   
     &:hover {
 
 
 }
 @media screen and (max-width: 1024px) {
   border-radius: initial;
   padding: initial; /* Adjust the padding as per your requirements */
   margin-top: initial;
   margin-left: initial;
   font-size: initial; 
 position: absolute;
 
left: 50%;
       top: 50%;
      background: none;
      transform: translate(-50%, -50%) matrix(2, 0, 0, 2, 0, 0);
      background: none;
     }
`;
export const Utilities = styled.div`
  position: absolute;
  left: 7.5%;
  transform: scale(0.85);
  & :hover + ${PopUpBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: 230px;
    padding: 8px 8px;

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
    @media screen and (max-width: 1024px) {
      //width: 175px;
      //zoom: 0.75;
      transform: scale(0.75);
        left: 10%;

   }
`;


export const UtilitiesIcon = styled(BsTools)`
  color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
   
   border-radius: 50%;
   padding: 8px; /* Adjust the padding as per your requirements */
   margin-top: 5px;
   margin-left: 20px;
   transform: translateY(12.5px) matrix(1, 0, 0, 1.2, 0, 0);
   font-size: 40px; /* Adjust the font size as per your requirements */
   
     &:hover {
 
 
 }
 @media screen and (max-width: 1024px) {
   border-radius: initial;
   padding: initial; /* Adjust the padding as per your requirements */
   margin-top: initial;
   margin-left: initial;
   font-size: initial; 
    position: absolute;
    
      left: 50%;
      top: 50%;
      background: none;
      transform: translate(-50%, -50%) matrix(1.5, 0, 0, 1.7, 0, 0);
      background: none;
     }
`;




export const TextSpan = styled.span`
    color: #fff;
    padding: 10px 0px 30px 15px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    @media screen and (max-width: 1024px) {
          //display: none;
          color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
          position: absolute;
          white-space: nowrap;
          top: 100%;
          left: 50%;
   
          //zoom: 0.8;
      
          padding: 0px 0px 0px 0px;
          transform: translate(-50%, 100%) scale(0.8);
          align-items: center;
          text-align: center;
          justify-content: center;
    }
`







export const ButtonText = styled.div`
   position: relative; /* Added position: relative */
   transition: transform 0.2s ease-in-out;
   // transform: ; /* Adjust the value as per your requirements */
    padding: 5px 0px 0px 0px;
    //transform: matrix(0,0,0,0,0,0);

    transform: translateY(-12px) matrix(1, 0, 0, .8, 0, 0);
    @media screen and (max-width: 1024px) {
      //top: 50%;
      
      padding: 25px 0px 0px 0px;
    transform:  matrix(1, 0, 0, .8, 0, 0);
    }
`
export const TextSpan2 = styled.span`
    color: #fff;
    padding: 10px 0px 30px 15px;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};

`
export const ButtonText2 = styled.div`
   position: relative; /* Added position: relative */
   transition: transform 0.2s ease-in-out;
   // transform: ; /* Adjust the value as per your requirements */
    padding: 5px 0px 0px 0px;
    //transform: matrix(0,0,0,0,0,0);

    transform: translateY(-12px) matrix(1, 0, 0, .8, 0, 0);

`
export const Button = styled.div`
     display: inline-block;
    width: 240px;
    height: 65px;
    backdrop-filter: blur(10px);
    border-radius: 90px;
    box-sizing: border-box;
    box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    white-space: nowrap;

    /* text edit */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
  /* or 81% */

    color: #000;



  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${RoadmapIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(2px 2px 2px white);
    }
    ${UtilitiesIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(2px 2px 2px white);
    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

    }
    ${ModelSwitchIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(2px 2px 2px white);
    }
  }


  @media screen and (max-width: 1024px) {
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${RoadmapIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(0px 0px 0px white);
    }
    ${UtilitiesIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(0px 0px 0px white);
    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

    }
    ${ModelSwitchIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(0px 0px 0px white);
    }
  }
  }
  @media screen and (max-width: 870px) {
          //transform: scale(0.7);
          top: 10px;
          //left: 75%;
    }
`;


export const slideInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

// Wrapper component for the buttons
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${slideInAnimation} 0.5s ease-in-out forwards;
`;



///
/// Fancy Button
///



const black = "#000";
const white = "#fff";
const silver = "#c0c0c0";

export const Body = styled.body`
  font-size: 16px;
  font-family: 'Helvetica', 'Arial', sans-serif;
  text-align: center;

  z-index: 999999999999;
  background-color: ${silver};
`;

export const BubblyButton = styled.button`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
      display: inline-block;
      //zoom: 1.25;
      -webkit-appearance: none;
      appearance: none;


      background-color: ${({ isDarkMode }) => (isDarkMode ? white : black)};
      
      border-radius: 4px;
      border: none;
      cursor: pointer;
      position: relative;
      transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
      box-shadow: 0 2px 25px rgba(192, 192, 192, 0.5);
      width: 200px;
      height: 50px;
      &:focus {
        outline: 0;
      }

      &:before,
      &:after {
        position: absolute;
        content: '';
        display: block;
        width: 140%;
        height: 100%;
        left: -20%;
        z-index: -1000;
        transition: all ease-in-out 0.5s;
        background-repeat: no-repeat;
      }

      &:before {
        display: none;
        top: -120%;
        margin-top: -20px;
       // margin-left: 30px;
        zoom: 1.25;
        transform: scale(1.5);
        background-image:
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, transparent 20%, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 30%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, transparent 10%, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 15%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%);
        background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
      }

      &:after {
        display: none;
        zoom: 1.25;
        //margin-left: 30px;
        transform: scale(1.5);
        bottom: -120%;
        margin-top: 0px;
        background-image:
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, transparent 10%, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 15%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%),
          radial-gradient(circle, ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')} 20%, transparent 20%);
        background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
      }
      &:active {

        transform: scale(0.9);
        background-color: ${black};
        box-shadow: 0 2px 25px rgba(192, 192, 192, 0.2);
  }
`;





export const animate = keyframes`
  0% {
    top: -30px;
    left: 20px;
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    top: -30px;
    left: 20px;
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    top: -30px;
    left: 20px;
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
`;

export const AnimateBubbles = styled(BubblyButton)`


    cursor: pointer;
    display: inline-block;
   
    width: 240px;
    height: 65px;
    border-radius: 90px;
    box-sizing: border-box;
    box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    white-space: nowrap;

    /* text edit */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
  /* or 81% */

    color: #000;



  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  /*
  &:before {
    background-color: initial;
    background-image: ${({ isDarkMode }) =>
      isDarkMode
        ? 'linear-gradient(#A9A9A9 0, rgba(0, 0, 0, 0) 100%)' // Black background
        : 'linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%)' // Original white background
    };
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    width: 92%;
  }


*/
  
  &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
    transform: scale(1.05);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${RoadmapIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      color: #fff;
      //filter: drop-shadow(2px 2px 2px white);
    }
    ${UtilitiesIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      //filter: drop-shadow(2px 2px 2px white);
    }
    ${TextSpan2}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};

    }
    ${ModelSwitchIcon} {
      box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};
     color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    }
  }


  @media screen and (max-width: 1024px) {
    /*
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    */
    &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${RoadmapIcon} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : 'none')};
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      filter: drop-shadow(0px 0px 0px white);
      background: none;
    }
    ${UtilitiesIcon} {
      /* styles for the container when button is hovered */
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      filter: drop-shadow(0px 0px 0px white);
      background: none;
    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

    }
    ${ModelSwitchIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(0px 0px 0px white);
    }
  }
  }
  @media screen and (max-width: 1024px) {
          //transform: scale(0.7);
          //top: 10px;
       
          //left: 75%;
    }

  
  &.animate {
    &:before {
      display: block;
      animation: ${animate} ease-in-out 0.75s forwards;
    }
    &:after {
      display: block;
      animation: ${animate} ease-in-out 0.75s forwards;
    }
  }

`;


export const BubblyButton2 = styled.button`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
      display: inline-block;
      //zoom: 1.25;
      -webkit-appearance: none;
      appearance: none;

      background-color: #000;
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      //background-color: ${({ isDarkMode }) => (isDarkMode ? white : black)};
      
      border-radius: 4px;
      border: none;
      cursor: pointer;
      position: relative;
      transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
      //box-shadow: 0 2px 25px rgba(192, 192, 192, 0.5);
      width: 200px;
      height: 50px;
      
      &:focus {
        outline: 0;
      }

      &:before,
      &:after {
        position: absolute;
        content: '';
        display: block;
        width: 140%;
        height: 100%;
        left: -20%;
        z-index: -1000;
        transition: all ease-in-out 0.5s;
        background-repeat: no-repeat;
      }




`;
export const AnimateBubbles2 = styled(BubblyButton2)`


cursor: pointer;
    display: inline-block;
   
    width: 240px;
    height: 65px;
    border-radius: 90px;
    box-sizing: border-box;
    box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.2);
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    white-space: nowrap;

    /* text edit */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -1px;
    text-transform: uppercase;
  /* or 81% */

    color: #000;



  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  /*
  &:before {
    background-color: initial;
    background-image: ${({ isDarkMode }) =>
      isDarkMode
        ? 'linear-gradient(#A9A9A9 0, rgba(0, 0, 0, 0) 100%)' // Black background
        : 'linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%)' // Original white background
    };
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    width: 92%;
  }


*/

  /*
  &:before {
    background-color: initial;
    background-image: ${({ isDarkMode }) =>
      isDarkMode
        ? 'linear-gradient(#A9A9A9 0, rgba(0, 0, 0, 0) 100%)' // Black background
        : 'linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%)' // Original white background
    };
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    width: 92%;
  }
*/


  &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
    transform: scale(1.05);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${RoadmapIcon} {
      box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};
     color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      //filter: drop-shadow(2px 2px 2px white);
    }
    ${UtilitiesIcon} {
      box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};
     color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    }
    ${TextSpan}{
      box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};

    }
    ${ModelSwitchIcon} {
      /* styles for the container when button is hovered */
      box-shadow: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 10px white)' : 'drop-shadow(0 0 10px black)')};
     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0 0 1.5px black)' : 'drop-shadow(0 0 5px white)')};

    }
  }


  

  @media screen and (max-width: 1024px) {
    width: 55px;
    height: 55px;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    transition: all 0.5s ease;
    display: flex;
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    &:hover  {
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);
    color: ${({ dark }) => (dark ? '#fff' : '#fff')};

    ${RoadmapIcon} {
      /* styles for the container when button is hovered */
      background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : 'none')};
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      filter: drop-shadow(0px 0px 0px white);
      background: none;
    }
    ${UtilitiesIcon} {
      /* styles for the container when button is hovered */
      color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
      filter: drop-shadow(0px 0px 0px white);
      background: none;
    }
    ${TextSpan}{
      /* styles for the container when button is hovered */
     // background-color: #000;

     filter: ${({ isDarkMode }) => (isDarkMode ? 'drop-shadow(0px 0px 10px #fff)' : 'drop-shadow(0px 0px 10px #000)')};

    }
    ${ModelSwitchIcon} {
      /* styles for the container when button is hovered */
      background-color: #000;
      color: #fff;
      filter: drop-shadow(0px 0px 0px white);
    }
  }
  }
  @media screen and (max-width: 1024px) {
          //transform: scale(0.7);
          top: 10px;
          
          //left: 75%;
    }

  
  &.animate {
    &:before {
      display: block;
      animation: ${animate} ease-in-out 0.75s forwards;
    }
    &:after {
      display: block;
      animation: ${animate} ease-in-out 0.75s forwards;
    }
  }

`;




///
/// Scroll to begin
///
export const colorBg = '#1E2A33'; // A dark blueish background
export const colorOutline = '#FF8C42'; // An orange outline color
export const colorOutlineFade = '#596268'; // A muted teal color for faded outlines


export const widthMouse = '52px';
export const heightMouse = '88px';
export const borderMouse = '6px';

export const posMouse = '8px';
export const posText = '2px';

export const sizeTrackball = '10px';
export const posTrackball = '20px';
export const shrinkTrackball = '0.4';

export const animDuration = '5s';



export const colorSlide = keyframes`
  0% { background-position: 0% 100%; }
  20% { background-position: 0% 0%; }
  21% { background-color: ${colorOutlineFade}; }
  29.99% { 
    background-color: ${colorOutline};
    background-position: 0% 0%;
  }
  30% { 
    background-color: ${colorOutlineFade};
    background-position: 0% 100%;
  }
  50% { background-position: 0% 0%; }
  51% { background-color: ${colorOutlineFade}; }
  59% { 
    background-color: ${colorOutline};
    background-position: 0% 0%;
  }
  60% { 
    background-color: ${colorOutlineFade};
    background-position: 0% 100%;
  }
  80% { background-position: 0% 0%; }
  81% { background-color: ${colorOutlineFade}; }
  90%, 100% { background-color: ${colorOutline}; }
`;

export const trackBallSlide = keyframes`
  0% {
    transform: scale(1) translateY(-${posTrackball});
    opacity: 1;
  }
  6% { 
    opacity: 1;
    transform: scale(0.9) translateY(${posTrackball / 4});
  }
  14% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateY(${posTrackball * 2});
  }
  15%, 19% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateY(-${posTrackball});
  }
  28%, 29.99% {
    opacity: 1;
    transform: scale(1) translateY(-${posTrackball});
  }
  30% {
    opacity: 1;
    transform: scale(1) translateY(-${posTrackball});
  }
  36% { 
    opacity: 1;
    transform: scale(0.9) translateY(${posTrackball / 4});
  }
  44% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateY(${posTrackball * 2});
  }
  45%, 49% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateY(-${posTrackball});
  }
  58%, 59.99% {
    opacity: 1;
    transform: scale(1) translateY(-${posTrackball});
  }
  60% {
    opacity: 1;
    transform: scale(1) translateY(-${posTrackball});
  }
  66% { 
    opacity: 1;
    transform: scale(0.9) translateY(${posTrackball / 4});
  }
  74% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateY(${posTrackball * 2});
  }
  75%, 79% {
    opacity: 0;
    transform: scale(${shrinkTrackball}) translateY(-${posTrackball});
  }
  88%, 100% {
    opacity: 1;
    transform: scale(1) translateY(-${posTrackball});
  }
`;

export const nudgeMouse = keyframes`
  0% { transform: translateY(0); }
  20% { transform: translateY(${posMouse}); }
  30% { transform: translateY(0); }
  50% { transform: translateY(${posMouse}); }
  60% { transform: translateY(0); }
  80% { transform: translateY(${posMouse}); }
  90% { transform: translateY(0); }
`;

export const nudgeText = keyframes`
  0% { transform: translateY(0); }
  20% { transform: translateY(${posText}); }
  30% { transform: translateY(0); }
  50% { transform: translateY(${posText}); }
  60% { transform: translateY(0); }
  80% { transform: translateY(${posText}); }
  90% { transform: translateY(0); }
`;

export const colorText = keyframes`
  21% { color: ${colorOutlineFade}; }
  30% { color: ${colorOutline}; }
  51% { color: ${colorOutlineFade}; }
  60% { color: ${colorOutline}; }
  81% { color: ${colorOutlineFade}; }
  90% { color: ${colorOutline}; }
`;

const bgGradient = () => `
  background:
    ${colorOutlineFade}
    linear-gradient(
      transparent 0%,
      transparent 50%,
      ${colorOutline} 50%,
      ${colorOutline} 100%
    );
`;


export const BlurContainer = styled.div`
  backdrop-filter: blur(10px); // Adjust the blur value as needed
  border-radius: 50%; // Optional: to give a rounded corner to the blur container
  //padding: 10px; // Optional: to give some space inside the blur container
`;

export const MouseBody = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
      z-index: -1;
  left: 50%;
  white-space: nowrap;
  transform: translateX(-50%) scale(0.35);

`;

export const Paragraph = styled.p`
  margin-top: 50px;
  font-family: "Cabin", sans-serif;
  letter-spacing: 12px;
  text-indent: 12px;
  font-size: 32px;
  font-weight: 900;
  color: ${colorOutline};
  animation: 
    ${colorText} ${animDuration} ease-out infinite,
    ${nudgeText} ${animDuration} ease-out infinite;
`;


export const WelcomeContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: -1;
 // left: 50%;
  white-space: nowrap;
 // position: fixed;
  //transform: translateX(-50%);
  padding: 0 20px; /* Add padding to prevent overflow */

  //backdrop-filter: blur(1px);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const WelcomeMessage = styled(motion.p)`
  margin-top: 100px;
  font-family: "Cabin", sans-serif;
  letter-spacing: 12px;
  text-indent: 12px;

  font-weight: 900;
  letter-spacing: -2.5px;
  text-transform: uppercase;
  margin-bottom: -100px;

  backdrop-filter: blur(1px);
 // color: ${colorOutline};
 color: #1E2A33;
 width: 250px;
  text-align: left; /* Center text */
  white-space: nowrap; /* Allow wrapping */
  //animation: 
   // ${colorText} ${animDuration} ease-out infinite,
   // ${nudgeText} ${animDuration} ease-out infinite;


`;


export const Mouse = styled.div`
  ${bgGradient()}
  position: relative;
  will-change: background-position, transform;
  width: ${widthMouse};
  height: ${heightMouse};
  zoom: 0.7;
  border-radius: 100px;
  background-size: 100% 200%;
  animation: 
    ${colorSlide} ${animDuration} linear infinite,
    ${nudgeMouse} ${animDuration} ease-out infinite;
    will-change: transform, background-color;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: auto;
  }
  
  &:before {
    width: calc(${widthMouse} - ${borderMouse});
    height: calc(${heightMouse} - ${borderMouse});
    background-color: ${colorBg};
    border-radius: 100px;
  }
  
  &:after {
    background-color: ${colorOutline};
    width: ${sizeTrackball};
    height: ${sizeTrackball};
    border-radius: 100%;
    animation: ${trackBallSlide} ${animDuration} linear infinite;
  }
`;

export const ScrollEffect = styled.div`
  opacity: ${props => props.opacity};
  transition: opacity 0.3s ease-in-out;
`;