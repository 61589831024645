import { a } from "@react-spring/three";
import { Html, useCursor, useGLTF } from '@react-three/drei';
import { useFrame } from "@react-three/fiber";
import { Select } from "@react-three/postprocessing";
import React, { useEffect, useRef, useState } from 'react';
import { EnvDesktop } from "./ClosetModel";
import { ItemDetailsCardHTML } from "./Components/3DItemComponents/ItemHtmlCard";
// Closet Item Imports
import Chain from "./ClosetItems/Chain";
import FloorBag from "./ClosetItems/FloorBag";
import GlassBalls from "./ClosetItems/GlassBalls";
import Hat from "./ClosetItems/Hat";
import MiddleBag from "./ClosetItems/MiddleBag";
import MiddleShirt from "./ClosetItems/MiddleShirt";
import Mirror from "./ClosetItems/Mirror";
import Perfume from "./ClosetItems/Perfume";
import RedShirt from "./ClosetItems/RedShirt";
import RightShirt from "./ClosetItems/RightShirt";
import Shoes from "./ClosetItems/Shoes";
import TopBag from "./ClosetItems/TopBag";

import {
  CircleIndicator,
  CircleIndicatorContainer
} from './Components/3DItemComponents/ItemElements';

// Extend the Three.js namespace to include TextGeometry


const fadeInScaleAnimation = {
  hidden: { opacity: 0, scale: 0, filter: 'blur(15px)' },
  visible: { opacity: 1, scale: 1, filter: 'blur(0)' },
};


export const itemModels = {
  Chain: Chain,
  FloorBag: FloorBag,
  GlassBalls: GlassBalls,
  Hat: Hat,
  MiddleBag: MiddleBag,
  MiddleShirt: MiddleShirt,
  Mirror: Mirror,
  Perfume: Perfume,
  RedShirt: RedShirt,
  RightShirt: RightShirt,
  Shoes: Shoes,
  TopBag: TopBag,
};






const traits = [
  { kind: 'BACKGROUND', description: 'M1 Aquamarine', price: 'Floor: 1.65 ETH' },
  { kind: 'CLOTHES', description: 'M1 Tanktop', price: 'Floor: 1.65 ETH' },
  { kind: 'EYES', description: 'M1 3d', price: 'Floor: 1.65 ETH' },
  { kind: 'FUR', description: 'M1 Black', price: 'Floor: 1.65 ETH' },
  { kind: 'HAT', description: 'M1 Fez', price: 'Floor: 1.65 ETH' },
  { kind: 'MOUTH', description: 'M1 Bored Cigarette', price: 'Floor: 1.65 ETH' },
  { kind: 'ACCESSORY', description: 'M1 Gold Chain', price: 'Floor: 1.65 ETH' },
  { kind: 'STYLE', description: 'M1 Minimalistic', price: 'Floor: 1.65 ETH' },
];

function getRandomTraits() {
  const shuffled = traits.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, Math.floor(Math.random() * 7) + 2);
}

const itemDetailsList = [
  {
    group: 'Mirror',
    header: 'Sample Closet',
    name: 'Mirror',
    description: 'Reflect your style with elegance',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 172',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the Mirror NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'TopBag',
    header: 'Sample Closet',
    name: 'TopBag',
    description: 'Carry your essentials in style',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 173',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the TopBag NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'Perfume',
    header: 'Sample Closet',
    name: 'Perfume',
    description: 'Scent that defines your presence',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 174',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the Perfume NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'RedShirt',
    header: 'Sample Closet',
    name: 'Red Shirt',
    description: 'Bold and stylish fashion choice',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 175',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the RedShirt NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'MiddleShirt',
    header: 'Sample Closet',
    name: 'Middle Shirt',
    description: 'Comfort and style in one',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 176',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the MiddleShirt NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'RightShirt',
    header: 'Sample Closet',
    name: 'Right Shirt',
    description: 'Perfect fit for any occasion',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 177',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the RightShirt NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'MiddleBag',
    header: 'Sample Closet',
    name: 'MiddleBag',
    description: 'Stylish bag for every day',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 178',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the MiddleBag NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'FloorBag',
    header: 'Sample Closet',
    name: 'FloorBag',
    description: 'Trendy and spacious bag',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 179',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the FloorBag NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'GlassBalls',
    header: 'Sample Closet',
    name: 'GlassBalls',
    description: 'Decorate with chic glass balls',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 180',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the GlassBalls NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'Hat',
    header: 'Sample Closet',
    name: 'Merch Middle Hat',
    description: 'Top off your look with flair',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 181',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the Hat NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'Chain',
    header: 'Sample Closet',
    name: 'Merch Top',
    description: 'Add a touch of class',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 182',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the Chain NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  },
  {
    group: 'Shoes',
    header: 'Sample Closet',
    name: 'Merch Bottom Shoes',
    description: 'Step out in ultimate style',
    address: '0x3d0c...46f9',
    tokenId: 'Token ID - 183',
    tokenStandard: 'Token Standard - ERC-721',
    chain: 'Chain - Ethereum',
    earnings: 'Creator Earnings - 5%',
    aboutDescription: 'This is a detailed description about the Shoes NFT. It includes information about its origin, uniqueness, and value.',
    artType: 'Kind: Art Type',
    detailDescription: 'Description: Generative 100%',
    detailPrice: 'Price: 2.5 floor',
    traits: getRandomTraits()
  }
];





export default function W3oDefaultCloset({
  BLOOM_LAYER,
  setIsScrolling,
  isScrolling,
  scroll,
  onClickItem,
  focusedItem,
  setWhitelistPopup,
  canvasRef,
  setIsMouseVisible,
  setButtonPopup,
  setIsFooterVisible,
  isDarkMode,
  buttonPopup,
  setProgress,
  openWhitelistLink,
  scale,
  cardToggle,
  setCurrentIndex,
  perfSucks, orbitControlsEnabled,
  isSelected, setIsSelected,
  onPrev, onNext, onClose, currentIndex,
  mobile3Dcollecion, setMobile3Dcollecion,
  browseMode, setBrowseMode,
  setHoveredGroup, hoveredGroup,
  selectedObject, setSelectedObject,
  props
}) {
  const group = useRef();
  const [hoveredItemDetails, setHoveredItemDetails] = useState(null);


  const [lastClickedItemName, setLastClickedItemName] = useState(null);

  const ClothesRef = useRef();
  const MirrorRef = useRef();
  const TopBagRef = useRef();
  const MiddleBagRef = useRef();
  const FloorBagRef = useRef();
  const GlassBallsRef = useRef();
  const MerchMiddleHatRef = useRef();
  const MerchMiddleRef = useRef();
  const MerchTopRef = useRef();
  const ShoesRef = useRef();
  const RedShirtRef = useRef();
  const PerfumeRef = useRef();
  const ChainRef = useRef();
  const HatRef = useRef();
  const MiddleShirtRef = useRef();
  const RightShirtRef = useRef();
  const hoveredGroupRef = useRef();

  const handleHover = (group, objectRef) => {
    setHoveredGroup(group);
    document.body.style.cursor = 'pointer';

    let details;

    switch (group) {
      case 'Mirror':
        details = {
          header: 'Sample Closet',
          name: 'Mirror',
          description: 'Reflect your style with elegance'
        };
        hoveredGroupRef.current = MirrorRef.current;
        break;

      case 'TopBag':
        details = {
          header: 'Sample Closet',
          name: 'TopBag',
          description: 'Carry your essentials in style'
        };
        hoveredGroupRef.current = TopBagRef.current;
        break;
      case 'Perfume':
        details = {
          header: 'Sample Closet',
          name: 'Perfume',
          description: 'Scent that defines your presence'
        };
        hoveredGroupRef.current = PerfumeRef.current;
        break;

      case 'RedShirt':
        details = {
          header: 'Sample Closet',
          name: 'Red Shirt',
          description: 'Bold and stylish fashion choice'
        };
        hoveredGroupRef.current = RedShirtRef.current;
        break;

      case 'MiddleShirt':
        details = {
          header: 'Sample Closet',
          name: 'Middle Shirt',
          description: 'Comfort and style in one'
        };
        hoveredGroupRef.current = MiddleShirtRef.current;
        break;

      case 'RightShirt':
        details = {
          header: 'Sample Closet',
          name: 'Right Shirt',
          description: 'Perfect fit for any occasion'
        };
        hoveredGroupRef.current = RightShirtRef.current;
        break;

      case 'MiddleBag':
        details = {
          header: 'Sample Closet',
          name: 'MiddleBag',
          description: 'Stylish bag for every day'
        };
        hoveredGroupRef.current = MiddleBagRef.current;
        break;

      case 'FloorBag':
        details = {
          header: 'Sample Closet',
          name: 'FloorBag',
          description: 'Trendy and spacious bag'
        };
        hoveredGroupRef.current = FloorBagRef.current;
        break;

      case 'GlassBalls':
        details = {
          header: 'Sample Closet',
          name: 'GlassBalls',
          description: 'Decorate with chic glass balls'
        };
        hoveredGroupRef.current = GlassBallsRef.current;
        break;

      case 'Hat':
        details = {
          header: 'Sample Closet',
          name: 'Merch Middle Hat',
          description: 'Top off your look with flair'
        };
        hoveredGroupRef.current = MerchMiddleHatRef.current;
        break;

      case 'Chain':
        details = {
          header: 'Sample Closet',
          name: 'Merch Top',
          description: 'Add a touch of class'
        };
        hoveredGroupRef.current = MerchTopRef.current;
        break;

      case 'Shoes':
        details = {
          header: 'Sample Closet',
          name: 'Merch Bottom Shoes',
          description: 'Step out in ultimate style'
        };
        hoveredGroupRef.current = ShoesRef.current;
        break;

      default:
        details = null;
    }

    setHoveredItemDetails(details);
  };

  const handleUnhover = (objectRef) => {
    if (hoveredGroup) {
      setHoveredGroup(null);
      setHoveredItemDetails(null);
      document.body.style.cursor = 'default';

      if (objectRef.current) {
        objectRef.current.traverse((child) => {
          if (child.isMesh) {
            child.layers.disable(BLOOM_LAYER);
          }
        });
      }
    }
  };
  const handleItemClick = (itemName) => {
    const index = itemDetailsList.findIndex(item => item.group === itemName);
    if (index !== -1) {
      setCurrentIndex(index);
      setHoveredGroup(null); // Clear the hover state
      setHoveredItemDetails(null); // Clear the hovered item details
      setIsSelected(true); // Set the selected item name
      setSelectedObject(itemName);
      // Check if the clicked item is the same as the last clicked item
      if (itemName === lastClickedItemName) {
        setIsSelected(false);
        setHoveredGroup(null); // Clear the hover state
        setHoveredItemDetails(null); // Clear the hovered item details
        //homeToggle();
        if (isSelected === false) {
          setIsSelected(true); 
          setIsSelected(itemName); // Set the selected item name
          setSelectedObject(itemName);
        }

        if (browseMode === true) {
          setIsSelected(true); 
          setIsSelected(itemName); // Set the selected item name
          setSelectedObject(itemName);
          setBrowseMode(false)
        }


      } else {
        setIsSelected(true);
        setIsSelected(itemName); // Set the selected item name
        setSelectedObject(itemName);
        setBrowseMode(false);
      }
  
      // Update the last clicked item name
      setLastClickedItemName(itemName);
    }
  };
  
  const handlePointerOver = (e, name, objectRef) => {
    e.stopPropagation();
    if (!isSelected || selectedObject !== name) {
      setSelectedObject(name);
      handleHover(name, objectRef);
      document.body.style.cursor = `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0zMC41IDE2QzMwLjUgMjQuMDA4MSAyNC4wMDgxIDMwLjUgMTYgMzAuNUM3Ljk5MTg3IDMwLjUgMS41IDI0LjAwODEgMS41IDE2QzEuNSA3Ljk5MTg3IDcuOTkxODcgMS41IDE2IDEuNUMyNC4wMDgxIDEuNSAzMC41IDcuOTkxODcgMzAuNSAxNloiIHN0cm9rZT0iZ29sZCIgdHJhbnNmb3JtPSJyb3RhdGUoMTgwZGVnKSIvPgo8cGF0aCBkPSJNNiA5QzcLjY1Njg1IDkgOS43NjU2ODUgOSAyQzcgNCAuMjUzMTUgMyA2IDNEMiAzIDMuNTY4MSAyIDkgNiAxN2w1IDEyIDIuNDAzMTUgMTguNDIxNTU3QzYgMyA3LjY1Njg1IDkuMzQzMTUgNiA5WiIgZmlsbD0iZ29sZCIgdHJhbnNmb3JtPSJyb3RhdGUoMTgwZGVnKSIvPgo8L3N2Zz4K"), auto`;
    }
  };
  
  const handlePointerOut = (e, objectRef) => {
    e.stopPropagation();
    
    // If the item is hovered but not selected, unhover it
    if (!isSelected || (isSelected && selectedObject !== objectRef.current?.name)) {
      setSelectedObject(null);
      handleUnhover(objectRef);
      document.body.style.cursor = 'default';
    }
  };
  
  







  const { nodes, materials } = useGLTF('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/w3oUserDefaultCloset.glb');
  const [hovered, set] = useState(false);
  useCursor(hovered, `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMwLjUgMTZDMzAuNSAyNC4wMDgxIDI0LjAwODEgMzAuNSAxNiAzMC41QzcuOTkxODcgMzAuNSAxLjUgMjQuMDA4MSAxLjUgMTZDMS41IDcuOTkxODcgNy45OTE4NyAxLjUgMTYgMS41QzI0LjAwODEgMS41IDMwLjUgNy45OTE4NyAzMC41IDE2WiIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNNiA5QzcuNjU2ODUgOSA5IDcuNjU2ODUgOSA2QzkgNC4zNDMxNSA3LjY1Njg1IDMgNiAzQzQuMzQzMTUgMyAzIDQuMzQzMTUgMyA2QzMgNy42NTY4NSA0LjM0MzE1IDkgNiA5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="), auto`);

  useFrame((state, delta) => {
    const offset = 1 - scroll.offset;

    if (scroll.offset === 0 || scroll.offset === 1) {
      // No spinning at the top or bottom
    } else if (offset < scroll.offset) {
      setIsFooterVisible(false);
    } else if (offset > scroll.offset) {
      setIsFooterVisible(false);
    } else if (scroll.offset < 1) {
      setIsFooterVisible(false);
    }
    if (scroll.offset === 1) {
      // Show the footer when scrolling down (original condition)
    }
    if (scroll.offset === 0) {
      setIsFooterVisible(false);
      setIsScrolling(false);
    }
    if (scroll.offset !== 0) {
      setIsScrolling(true);
    }
  });

  const width = window.innerWidth;
  const isMobile = width <= 600; // Check if the window width is 600 or less

  const orbitControlsRef = useRef(); // Assuming you are using OrbitControls for camera manipulation


  useEffect(() => {
    const timeout = setTimeout(() => {
      document.querySelectorAll('.circle-indicator').forEach(indicator => {
        indicator.style.animation = `${fadeInScaleAnimation} 0.5s ease-in-out forwards`;
      });
    }, 3000); // Delay of 3 seconds after the model is loaded

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
         <group>


      { isSelected ? (      
     <></>): (<></>)}

     { !isSelected && !browseMode &&          <Html 
            center
            style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none' }}
            zIndexRange={[100, 0]}
          >
            <ItemDetailsCardHTML itemDetailsList={itemDetailsList} isDarkMode={isDarkMode} hoveredGroup={hoveredGroup} details={hoveredItemDetails} objectRef={hoveredGroupRef} />
          </Html>}

 </group>
    <group>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ;;;;6SW"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      Ae38                                                                                                                                
      <pointLight position={[0, 10, 10]} intensity={0.1} />
  
      <group ref={group} {...props} onPointerOver={(e) => set(true)} onPointerOut={() => set(false)} scale={.13} dispose={null}>
        <mesh geometry={nodes.Cylinder004_Material_06936.geometry} material={materials['Material.092']} />
        <mesh geometry={nodes.Cylinder004_Material_06936_1.geometry} material={materials['Material.093']} />
        <mesh geometry={nodes.Cylinder004_Material_06936_2.geometry} material={nodes.Cylinder004_Material_06936_2.material} />
        <mesh geometry={nodes.Cylinder004_Material_06936_3.geometry} material={materials['Material.094']} />
        <mesh geometry={nodes.Cylinder004_Material_06936_4.geometry} material={materials['Material.095']} />
        <mesh geometry={nodes.Cylinder004_Material_06936_5.geometry} material={materials['Material.096']} />
        <mesh geometry={nodes.Cylinder004_Material_06936_6.geometry} material={materials['Material.097']} />
        <mesh geometry={nodes.Cylinder004_Material_06936_7.geometry} material={materials['Material.098']} />
        <mesh geometry={nodes.Cylinder004_Material_06935.geometry} material={materials['Material.086']} />
        <mesh geometry={nodes.Cylinder004_Material_06935_1.geometry} material={nodes.Cylinder004_Material_06935_1.material} />
        <mesh geometry={nodes.Cylinder004_Material_06935_2.geometry} material={materials['PaletteMaterial001.011']} />
        <mesh geometry={nodes.Cylinder004_Material_06935_3.geometry} material={materials['cupbord2.017']} />
        <mesh geometry={nodes.Cylinder004_Material_06935_4.geometry} material={materials['cupbord2.018']} />
        <mesh geometry={nodes.Cylinder004_Material_06935_5.geometry} material={materials['cupbord2.019']} />
        <mesh geometry={nodes.Cylinder004_Material_06935_6.geometry} material={materials['cupbord2.020']} />

        <Select enabled={(selectedObject === 'FloorBag') || (isSelected && itemDetailsList[currentIndex]?.group === 'FloorBag')}>
  <a.group name="FloorBag" onPointerOver={(e) => handlePointerOver(e, 'FloorBag', FloorBagRef)} onPointerOut={(e) => handlePointerOut(e, FloorBagRef)} onClick={() => handleItemClick('FloorBag')}>
    <Html position={[-3.94, 0.98, 5.04]} zIndexRange={[100, 0]}>
      <CircleIndicatorContainer>
        <CircleIndicator
          isSelected={selectedObject === 'FloorBag' || (isSelected && itemDetailsList[currentIndex]?.group === 'FloorBag')}
          animate={{
            scale: selectedObject === 'FloorBag' || (isSelected && itemDetailsList[currentIndex]?.group === 'FloorBag') ? [1.2, 1.2] : 1,
            rotate: selectedObject === 'FloorBag' || (isSelected && itemDetailsList[currentIndex]?.group === 'FloorBag') ? 360 : 0,
          }}
          transition={{
            duration: 0.5,
            ease: 'easeInOut',
          }}
          className="circle-indicator"
        />
      </CircleIndicatorContainer>
    </Html>
    <group position={[-3.94, 0.98, 5.04]} rotation={[Math.PI, -0.86, Math.PI]} scale={[0.03, 0.03, 0.03]}>
      <mesh geometry={nodes.bag_bag_0004.geometry} material={materials['material.002']} />
      <mesh geometry={nodes.bag_bag_0004_1.geometry} material={materials['belt.002']} />
      <mesh geometry={nodes.bag_bag_0004_2.geometry} material={materials['metal.002']} />
    </group>
  </a.group>
</Select>

<Select enabled={(selectedObject === 'MiddleBag') || (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleBag')}>
  <a.group name="Middle Bag" onPointerOver={(e) => handlePointerOver(e, 'MiddleBag', MiddleBagRef)} onPointerOut={(e) => handlePointerOut(e, MiddleBagRef)} onClick={() => handleItemClick('MiddleBag')}>
    <Html position={[-8.5, 4.5, 7.5]} zIndexRange={[100, 0]}>
      <CircleIndicatorContainer>
        <CircleIndicator
          isSelected={selectedObject === 'MiddleBag' || (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleBag')}
          animate={{
            scale: selectedObject === 'MiddleBag' || (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleBag') ? [1.2, 1.2] : 1,
            rotate: selectedObject === 'MiddleBag' || (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleBag') ? 360 : 0,
          }}
          transition={{
            duration: 0.5,
            ease: 'easeInOut',
          }}
          className="circle-indicator"
        />
      </CircleIndicatorContainer>
    </Html>
    <mesh geometry={nodes.Middle_Bag005.geometry} material={materials['map_BagWomen_01.006']} />
  </a.group>
</Select>

<Select enabled={(selectedObject === 'Perfume') || (isSelected && itemDetailsList[currentIndex]?.group === 'Perfume')}>
  <a.group name="Perfume" onPointerOver={(e) => handlePointerOver(e, 'Perfume', PerfumeRef)} onPointerOut={(e) => handlePointerOut(e, PerfumeRef)} onClick={() => handleItemClick('Perfume')}>
    <Html position={[8, 5, -9.5]} zIndexRange={[100, 0]}>
      <CircleIndicatorContainer>
        <CircleIndicator
          isSelected={selectedObject === 'Perfume' || (isSelected && itemDetailsList[currentIndex]?.group === 'Perfume')}
          animate={{
            scale: selectedObject === 'Perfume' || (isSelected && itemDetailsList[currentIndex]?.group === 'Perfume') ? [1.2, 1.2] : 1,
            rotate: selectedObject === 'Perfume' || (isSelected && itemDetailsList[currentIndex]?.group === 'Perfume') ? 360 : 0,
          }}
          transition={{
            duration: 0.5,
            ease: 'easeInOut',
          }}
          className="circle-indicator"
        />
      </CircleIndicatorContainer>
    </Html>
    <mesh geometry={nodes.Perfume005.geometry} material={materials['PaletteMaterial004.003']} />
  </a.group>
</Select>

<Select enabled={(selectedObject === 'RedShirt') || (isSelected && itemDetailsList[currentIndex]?.group === 'RedShirt')}>
  <a.group name="Red Shirt" onPointerOver={(e) => handlePointerOver(e, 'RedShirt', RedShirtRef)} onPointerOut={(e) => handlePointerOut(e, RedShirtRef)} onClick={() => handleItemClick('RedShirt')}>
    <Html position={[-7, 6.5, -4.5]} zIndexRange={[100, 0]}>
      <CircleIndicatorContainer>
        <CircleIndicator
          isSelected={selectedObject === 'RedShirt' || (isSelected && itemDetailsList[currentIndex]?.group === 'RedShirt')}
          animate={{
            scale: selectedObject === 'RedShirt' || (isSelected && itemDetailsList[currentIndex]?.group === 'RedShirt') ? [1.2, 1.2] : 1,
            rotate: selectedObject === 'RedShirt' || (isSelected && itemDetailsList[currentIndex]?.group === 'RedShirt') ? 360 : 0,
          }}
          transition={{
            duration: 0.5,
            ease: 'easeInOut',
          }}
          className="circle-indicator"
        />
      </CircleIndicatorContainer>
    </Html>
    <group position={[0.39, 1.36, -3.59]} scale={[11.48, 11.48, 12.94]}>
      <mesh geometry={nodes.Plane249.geometry} material={materials['tshirtonhanger3.010']} />
      <mesh geometry={nodes.Plane249_1.geometry} material={materials['tshirtonhanger3.011']} />
    </group>
  </a.group>
</Select>

<Select enabled={(selectedObject === 'TopBag') || (isSelected && itemDetailsList[currentIndex]?.group === 'TopBag')}>
  <a.group name="Top Bag" onPointerOver={(e) => handlePointerOver(e, 'TopBag', TopBagRef)} onPointerOut={(e) => handlePointerOut(e, 'TopBag')} onClick={() => handleItemClick('TopBag')}>
    <Html position={[-8.5, 11.25, 7]} zIndexRange={[100, 0]}>
      <CircleIndicatorContainer>
        <CircleIndicator
          isSelected={selectedObject === 'TopBag' || (isSelected && itemDetailsList[currentIndex]?.group === 'TopBag')}
          animate={{
            scale: selectedObject === 'TopBag' || (isSelected && itemDetailsList[currentIndex]?.group === 'TopBag') ? [1.2, 1.2] : 1,
            rotate: selectedObject === 'TopBag' || (isSelected && itemDetailsList[currentIndex]?.group === 'TopBag') ? 360 : 0,
          }}
          transition={{
            duration: 0.5,
            ease: 'easeInOut',
          }}
          className="circle-indicator"
        />
      </CircleIndicatorContainer>
    </Html>
    <mesh geometry={nodes.Top_Bag005.geometry} material={materials['bag1.006']} />
  </a.group>
</Select>

<Select enabled={(selectedObject === 'RightShirt') || (isSelected && itemDetailsList[currentIndex]?.group === 'RightShirt')}>
  <a.group name="Right Shirt" onPointerOver={(e) => handlePointerOver(e, 'RightShirt', RightShirtRef)} onPointerOut={(e) => handlePointerOut(e, RightShirtRef)} onClick={() => handleItemClick('RightShirt')}>
    <Html position={[-6, 11, -6.5]} zIndexRange={[100, 0]}>
      <CircleIndicatorContainer>
        <CircleIndicator
          isSelected={selectedObject === 'RightShirt' || (isSelected && itemDetailsList[currentIndex]?.group === 'RightShirt')}
          animate={{
            scale: selectedObject === 'RightShirt' || (isSelected && itemDetailsList[currentIndex]?.group === 'RightShirt') ? [1.2, 1.2] : 1,
            rotate: selectedObject === 'RightShirt' || (isSelected && itemDetailsList[currentIndex]?.group === 'RightShirt') ? 360 : 0,
          }}
          transition={{
            duration: 0.5,
            ease: 'easeInOut',
          }}
          className="circle-indicator"
        />
      </CircleIndicatorContainer>
    </Html>
    <mesh geometry={nodes.Chain002.geometry} material={nodes.Chain002.material} />
    <mesh geometry={nodes.Chain002_1.geometry} material={nodes.Chain002_1.material} />
    <mesh geometry={nodes.Chain002_2.geometry} material={materials['tsh3.008']} />
  </a.group>
</Select>

<Select enabled={(selectedObject === 'Mirror') || (isSelected && itemDetailsList[currentIndex]?.group === 'Mirror')}>
  <a.group name="Mirror" onPointerOver={(e) => handlePointerOver(e, 'Mirror', MirrorRef)} onPointerOut={(e) => handlePointerOut(e, MirrorRef)} onClick={() => handleItemClick('Mirror')}>
    <Html position={[-2.5, 2.25, -7.75]} zIndexRange={[100, 0]}>
      <CircleIndicatorContainer>
        <CircleIndicator
          isSelected={selectedObject === 'Mirror' || (isSelected && itemDetailsList[currentIndex]?.group === 'Mirror')}
          animate={{
            scale: selectedObject === 'Mirror' || (isSelected && itemDetailsList[currentIndex]?.group === 'Mirror') ? [1.2, 1.2] : 1,
            rotate: selectedObject === 'Mirror' || (isSelected && itemDetailsList[currentIndex]?.group === 'Mirror') ? 360 : 0,
          }}
          transition={{
            duration: 0.5,
            ease: 'easeInOut',
          }}
          className="circle-indicator"
        />
      </CircleIndicatorContainer>
    </Html>
    <mesh geometry={nodes.Chain004.geometry} material={nodes.Chain004.material} />
    <mesh geometry={nodes.Chain004_1.geometry} material={materials['PaletteMaterial003.003']} />
  </a.group>
</Select>

<Select enabled={(selectedObject === 'Hat') || (isSelected && itemDetailsList[currentIndex]?.group === 'Hat')}>
  <a.group name="Hat" onPointerOver={(e) => handlePointerOver(e, 'Hat', HatRef)} onPointerOut={(e) => handlePointerOut(e, HatRef)} onClick={() => handleItemClick('Hat')}>
    <Html position={[4.85, 5.25, -8.5]} zIndexRange={[100, 0]}>
      <CircleIndicatorContainer>
        <CircleIndicator
          isSelected={selectedObject === 'Hat' || (isSelected && itemDetailsList[currentIndex]?.group === 'Hat')}
          animate={{
            scale: selectedObject === 'Hat' || (isSelected && itemDetailsList[currentIndex]?.group === 'Hat') ? [1.2, 1.2] : 1,
            rotate: selectedObject === 'Hat' || (isSelected && itemDetailsList[currentIndex]?.group === 'Hat') ? 360 : 0,
          }}
          transition={{
            duration: 0.5,
            ease: 'easeInOut',
          }}
          className="circle-indicator"
        />
      </CircleIndicatorContainer>
    </Html>
    <mesh geometry={nodes.Hat003.geometry} material={nodes.Hat003.material} />
  </a.group>
</Select>

<Select enabled={(selectedObject === 'GlassBalls') || (isSelected && itemDetailsList[currentIndex]?.group === 'GlassBalls')}>
  <a.group name="Glass Balls" onPointerOver={(e) => handlePointerOver(e, 'GlassBalls', GlassBallsRef)} onPointerOut={(e) => handlePointerOut(e, GlassBallsRef)} onClick={() => handleItemClick('GlassBalls')}>
    <Html position={[7, 12.25, -10.5]} zIndexRange={[100, 0]}>
      <CircleIndicatorContainer>
        <CircleIndicator
          isSelected={selectedObject === 'GlassBalls' || (isSelected && itemDetailsList[currentIndex]?.group === 'GlassBalls')}
          animate={{
            scale: selectedObject === 'GlassBalls' || (isSelected && itemDetailsList[currentIndex]?.group === 'GlassBalls') ? [1.2, 1.2] : 1,
            rotate: selectedObject === 'GlassBalls' || (isSelected && itemDetailsList[currentIndex]?.group === 'GlassBalls') ? 360 : 0,
          }}
          transition={{
            duration: 0.5,
            ease: 'easeInOut',
          }}
          className="circle-indicator"
        />
      </CircleIndicatorContainer>
    </Html>
    <mesh geometry={nodes.Glass_Balls_Hanger001.geometry} material={nodes.Glass_Balls_Hanger001.material} />
    <mesh geometry={nodes.GlassBalls003.geometry} material={nodes.GlassBalls003.material} />
  </a.group>
</Select>

<Select enabled={(selectedObject === 'Shoes') || (isSelected && itemDetailsList[currentIndex]?.group === 'Shoes')}>
  <a.group name="Shoes" onPointerOver={(e) => handlePointerOver(e, 'Shoes', ShoesRef)} onPointerOut={(e) => handlePointerOut(e, ShoesRef)} onClick={() => handleItemClick('Shoes')}>
    <Html position={[7, 3.25, -9]} zIndexRange={[100, 0]}>
      <CircleIndicatorContainer>
        <CircleIndicator
          isSelected={selectedObject === 'Shoes' || (isSelected && itemDetailsList[currentIndex]?.group === 'Shoes')}
          animate={{
            scale: selectedObject === 'Shoes' || (isSelected && itemDetailsList[currentIndex]?.group === 'Shoes') ? [1.2, 1.2] : 1,
            rotate: selectedObject === 'Shoes' || (isSelected && itemDetailsList[currentIndex]?.group === 'Shoes') ? 360 : 0,
          }}
          transition={{
            duration: 0.5,
            ease: 'easeInOut',
          }}
          className="circle-indicator"
        />
      </CircleIndicatorContainer>
    </Html>
    <mesh geometry={nodes.Chain012.geometry} material={nodes.Chain012.material} />
    <mesh geometry={nodes.Chain012_1.geometry} material={materials['blinn5SG.006']} />
    <mesh geometry={nodes.Chain012_2.geometry} material={materials['blinn1SG.008']} />
    <mesh geometry={nodes.Chain012_3.geometry} material={materials['PaletteMaterial005.003']} />
    <mesh geometry={nodes.Chain012_4.geometry} material={materials['PaletteMaterial006.003']} />
    <mesh geometry={nodes.Chain012_5.geometry} material={materials['blinn3SG.006']} />
    <mesh geometry={nodes.Chain012_6.geometry} material={materials['blinn2SG.008']} />
    <mesh geometry={nodes.Chain012_7.geometry} material={nodes.Chain012_7.material} />
  </a.group>
</Select>

<Select enabled={(selectedObject === 'Chain') || (isSelected && itemDetailsList[currentIndex]?.group === 'Chain')}>
  <a.group name="Chain" onPointerOver={(e) => handlePointerOver(e, 'Chain', ChainRef)} onPointerOut={(e) => handlePointerOut(e, ChainRef)} onClick={() => handleItemClick('Chain')}>
    <Html position={[7.15, 7.25, -8.5]} zIndexRange={[100, 0]}>
      <CircleIndicatorContainer>
        <CircleIndicator
          isSelected={selectedObject === 'Chain' || (isSelected && itemDetailsList[currentIndex]?.group === 'Chain')}
          animate={{
            scale: selectedObject === 'Chain' || (isSelected && itemDetailsList[currentIndex]?.group === 'Chain') ? [1.2, 1.2] : 1,
            rotate: selectedObject === 'Chain' || (isSelected && itemDetailsList[currentIndex]?.group === 'Chain') ? 360 : 0,
          }}
          transition={{
            duration: 0.5,
            ease: 'easeInOut',
          }}
          className="circle-indicator"
        />
      </CircleIndicatorContainer>
    </Html>
    <mesh geometry={nodes.Chain014.geometry} material={nodes.Chain014.material} />
    <mesh geometry={nodes.Chain014_1.geometry} material={nodes.Chain014_1.material} />
  </a.group>
</Select>

<Select enabled={(selectedObject === 'MiddleShirt') || (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleShirt')}>
  <a.group name="MiddleShirt" onPointerOver={(e) => handlePointerOver(e, 'MiddleShirt', MiddleShirtRef)} onPointerOut={(e) => handlePointerOut(e, MiddleShirtRef)} onClick={() => handleItemClick('MiddleShirt')}>
    <Html position={[-6.5, 8.75, -5.35]} zIndexRange={[100, 0]}>
      <CircleIndicatorContainer>
        <CircleIndicator
          isSelected={selectedObject === 'MiddleShirt' || (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleShirt')}
          animate={{
            scale: selectedObject === 'MiddleShirt' || (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleShirt') ? [1.2, 1.2] : 1,
            rotate: selectedObject === 'MiddleShirt' || (isSelected && itemDetailsList[currentIndex]?.group === 'MiddleShirt') ? 360 : 0,
          }}
          transition={{
            duration: 0.5,
            ease: 'easeInOut',
          }}
          className="circle-indicator"
        />
      </CircleIndicatorContainer>
    </Html>
    <mesh geometry={nodes.Cylinder004_Material_06042.geometry} material={materials['default.006']} />
    <mesh geometry={nodes.Cylinder004_Material_06042_1.geometry} material={nodes.Cylinder004_Material_06042_1.material} />
    <mesh geometry={nodes.Cylinder004_Material_06042_2.geometry} material={nodes.Cylinder004_Material_06042_2.material} />
  </a.group>
</Select>



        <mesh geometry={nodes.Floor_Top001.geometry} material={materials['Material.071']} />
        <mesh geometry={nodes.Stand_Top001.geometry} material={nodes.Stand_Top001.material} />
        <mesh geometry={nodes.FloorBottom001.geometry} material={nodes.FloorBottom001.material} />
        <mesh geometry={nodes.Clothes_Rod001.geometry} material={nodes.Clothes_Rod001.material} />
        <mesh geometry={nodes.Stand001.geometry} material={nodes.Stand001.material} />
        <mesh geometry={nodes.Plane379.geometry} material={materials['cupbord2.012']} />
        <mesh geometry={nodes.Plane379_1.geometry} material={materials['Material.075']} />
        <mesh geometry={nodes.Plane379_2.geometry} material={materials['PaletteMaterial001.009']} />
        <mesh geometry={nodes.Plane379_3.geometry} material={materials['Material.076']} />
        <mesh geometry={nodes.Plane379_4.geometry} material={nodes.Plane379_4.material} />
        <mesh geometry={nodes.Plane379_5.geometry} material={materials['PaletteMaterial001.010']} />
        <mesh geometry={nodes.Plane379_6.geometry} material={materials['Material.077']} />
      </group>
    </group>
    <EnvDesktop BLOOM_LAYER={BLOOM_LAYER} orbitControlsEnabled={orbitControlsEnabled} perfSucks={perfSucks} />
    </>
  );
}

useGLTF.preload('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/w3oSampleCloset/w3oUserDefaultCloset.glb');

