import { a, useSpring } from "@react-spring/three";
import {
  Billboard,
  OrbitControls,
  PerformanceMonitor,
  Scroll,
  ScrollControls,
  Sparkles,
  Text,
  useIntersect,
  useTexture
} from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import * as THREE from 'three';
import {
  Mouse,
  MouseBody,
  Paragraph,
  WelcomeContainer,
  WelcomeMessage
} from '../JoinDao/DaoOverlay/OverlayElements';
import Network from '../JoinDao/Network';

// Word Globe Titles
const titles = [
  "Artists", "Graphic Designer", "Painter", "Social Media Marketer", "Digital Creator",
  "3D Modeler", "Innovator", "Clothing Designer", "Influencer", "Illustrator",
  "Web Developer", "UI/UX Designer", "Photographer", "Videographer", "Copywriter",
  "Music Producer", "Animator", "Game Developer", "Architect", "Interior Designer",
  "Fashion Stylist", "Brand Strategist", "Creative Director", "Art Director", "Product Designer",
  "Film Director", "Screenwriter", "Cinematographer", "Editor", "Sound Engineer",
  "Set Designer", "Motion Graphics Designer", "Printmaker", "Sculptor", "Ceramic Artist",
  "Jewelry Designer", "Textile Artist", "Concept Artist", "Performance Artist", "Muralist",
  "Installation Artist", "Visual Effects Artist", "Voice Actor", "Composer", "Playwright",
  "Choreographer", "Dance Instructor", "Graphic Novelist", "Cartoonist", "Character Designer",
  "Voiceover Artist", "Podcast Host", "SEO Specialist", "Content Strategist", "Marketing Consultant",
  "Public Relations Specialist", "Advertising Executive", "Event Planner", "Museum Curator", "Gallery Owner",
  "Fashion Photographer", "Photo Editor", "Makeup Artist", "Hair Stylist", "Nail Artist",
  "Costume Designer", "Prop Maker", "Art Therapist", "Music Therapist", "Sound Designer",
  "Lighting Designer", "Stage Manager", "Production Coordinator", "Broadcast Engineer", "Radio Producer",
  "Multimedia Artist", "3D Animator", "Environmental Designer", "Urban Planner", "Landscape Architect",
  "Industrial Designer", "Automotive Designer", "Aerospace Designer", "Marine Designer", "Package Designer"
];

// Word Component
function Word({ isMobile, children, ...props }) {
  const color = new THREE.Color();
  const fontProps = { font: '/Inter-Bold.woff', fontSize: isMobile ? 0.05 : 0.085, letterSpacing: -0.05, lineHeight: 1, 'material-toneMapped': false };
  const ref = useRef();
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (hovered) document.body.style.cursor = 'pointer';
    return () => (document.body.style.cursor = 'auto');
  }, [hovered]);

  useFrame(() => {
    ref.current.material.color.lerp(color.set(hovered ? '#a76032' : 'black'), 0.1);
  });

  const handlePointerOver = (e) => {
    e.stopPropagation();
    setHovered(true);
  };

  return (
    <Billboard {...props} onPointerOver={handlePointerOver} onPointerOut={() => setHovered(false)}>
      <Text ref={ref} onClick={() => console.log('clicked')} {...fontProps}>
        {children}
      </Text>
    </Billboard>
  );
}

// Cloud Component
function Cloud({ count, radius, isMobile, isVisible }) {
  const [spring, api] = useSpring(() => ({
    scale: 0,
    blur: 15,
    positionY: 0,
    config: { mass: 1, tension: 170, friction: 26 },
  }));

  useEffect(() => {
    if (isVisible) {
      api.start({ scale: 1, blur: 0, positionY: isMobile ? 0.15 : 0 });
    } else {
      api.start({ scale: 0, blur: 15, positionY: 0 });
    }
  }, [api, isMobile, isVisible]);

  const words = useMemo(() => {
    const temp = [];
    const spherical = new THREE.Spherical();
    const phiSpan = Math.PI / (count + 1);
    const thetaSpan = (Math.PI * 2) / count;

    for (let i = 1; i <= count; i++) {
      for (let j = 0; j < count; j++) {
        const title = titles[(i * count + j) % titles.length];
        temp.push([new THREE.Vector3().setFromSpherical(spherical.set(radius, phiSpan * i, thetaSpan * j)), title]);
      }
    }

    return temp;
  }, [count, radius]);

  return (
    <a.group scale={spring.scale} position-y={spring.positionY}>
      {words.map(([pos, word], index) => <Word isMobile={isMobile} key={index} position={pos}>{word}</Word>)}
    </a.group>
  );
}

// Utility function to shuffle an array
function shuffleArray2(array) {
  let currentIndex = array.length, randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
}

// ImageNetwork Component
// ImageNetwork Component
function ImageNetwork({ category, baseImageUrl, scale, position, isDarkMode, scaledFactor }) {
  const count = 6;
  const imagesSetCount = 30;
  const textureLoader = new THREE.TextureLoader();

  // Store random scales and indexes without shuffling
  const randomScales = useMemo(() => Array.from({ length: count }, () => Math.random()), [count]);
  const shuffledIndexes = useMemo(() => Array.from({ length: imagesSetCount }, (_, i) => i + 1), [imagesSetCount]);

  const images = [];
  const lines = [];

  if (shuffledIndexes.length > 0) {
    const centralImageUrl = `${baseImageUrl}${category}/${category}${shuffledIndexes[0]}.png`;
    const centralTexture = textureLoader.load(centralImageUrl);
    images.push(
      <mesh key="central" position={[0, 0, 0]}>
        <circleGeometry args={[scale * scaledFactor * 0.35, 32]} />
        <meshBasicMaterial map={centralTexture} />
      </mesh>
    );

    const radiusBase = scale * 0.5 * scaledFactor;
    const angleStep = (2 * Math.PI) / (count - 1);
    randomScales.forEach((randomScale, i) => {
      if (i === 0) return;

      const angle = angleStep * i;
      const radius = radiusBase * (1 + randomScale * 0.15);
      const x = Math.cos(angle) * radius;
      const y = Math.sin(angle) * radius;
      const imageUrl = `${baseImageUrl}${category}/${category}${shuffledIndexes[i]}.png`;
      const texture = textureLoader.load(imageUrl);
      images.push(
        <mesh key={i} position={[x, y, 0]}>
          <circleGeometry args={[scale * scaledFactor * (0.075 + randomScale * 0.075), 32]} />
          <meshBasicMaterial map={texture} />
        </mesh>
      );

      const lineColor = isDarkMode ? 0xffffff : 0x000000;
      const material = new THREE.LineBasicMaterial({ color: lineColor, linewidth: 2 });
      const points = [new THREE.Vector3(0, 0, 0), new THREE.Vector3(x, y, -0.001)];
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      lines.push(<line key={`line-${i}`} geometry={geometry} material={material} />);
    });
  }

  return (
    <group position={position}>
      {images}
      {lines}
    </group>
  );
}






// Utility function to create a circular mask texture
function createCircularMaskTexture() {
  const size = 512;
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext('2d');
  const centerX = size / 2;
  const centerY = size / 2;
  const radius = size / 2;

  context.fillStyle = 'transparent';
  context.fillRect(0, 0, size, size);

  context.beginPath();
  context.arc(centerX, centerY, radius, 0, Math.PI * 2);
  context.closePath();
  context.clip();

  context.fillStyle = 'white';
  context.fill();

  return new THREE.CanvasTexture(canvas);
}

// Item2 Component
function Item({ url, scale, daoImages, ...props }) {
  const visible = useRef(false);
  const [hovered, hover] = useState(false);
  const ref = useIntersect((isVisible) => (visible.current = isVisible));
  const { height } = useThree((state) => state.viewport);
  const circularMaskTexture = useMemo(() => createCircularMaskTexture(), []);

  const opacitySpring = useSpring({
    opacity: daoImages ? 0 : 1,
    config: { duration: 1000 },
  });

  useFrame((state, delta) => {
    ref.current.position.y = THREE.MathUtils.damp(
      ref.current.position.y,
      visible.current ? 0 : -height / 2 + 1,
      0,
      delta
    );
    ref.current.material.zoom = THREE.MathUtils.damp(
      ref.current.material.zoom,
      visible.current ? 1 : 1.5,
      4,
      delta
    );
    ref.current.material.grayscale = THREE.MathUtils.damp(
      ref.current.material.grayscale,
      hovered ? 1 : 0,
      4,
      delta
    );
  });

  return (
    <group {...props}>
      <mesh
        ref={ref}
        onPointerOver={() => hover(true)}
        onPointerOut={() => hover(false)}
        scale={scale}
      >
        <circleGeometry args={[0.5, 64]} />
        <a.meshBasicMaterial map={circularMaskTexture} side={THREE.DoubleSide} transparent />
        <a.meshBasicMaterial
          map={useTexture(url)}
          side={THREE.DoubleSide}
          transparent
          opacity={opacitySpring.opacity}
        />
      </mesh>
    </group>
  );
}

// Utility function to shuffle an array
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

// AnimatedItem Component
function AnimatedItem({ daoImages, position, imageUrl, scale, started }) {
  const itemAnimation = useSpring({
    from: {
      position: [0, 0, 0],
      opacity: 0,
      scale: 0,
    },
    to: {
      position,
      opacity: 1,
      scale: 1,
    },
    config: { duration: 250 },
  });

  return (
    started && (
      <a.group scale={itemAnimation.scale} opacity={itemAnimation.opacity} position={position}>
        <Item daoImages={daoImages} url={imageUrl} scale={scale} position={position} />
      </a.group>
    )
  );
}

// Items Component
// Items Component
function Items({ started, daoImages, isDarkMode }) {
  const { width: w, height: h } = useThree((state) => state.viewport);
  const width = window.innerWidth;
  const isMobile = width <= 600;
  const scaledCreatives = isMobile ? 1.6 : 2.75;
  const scaledFactor = isMobile ? 2 : 1;
  const scaledCreativesPosition = isMobile ? 3 : 1;
  const scrollPosition = isMobile ? 0.125 : 0.35;
  const shiftedCreatives = isMobile ? 0.5 : 0.5;
  const imageUrlBase = "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoHeroImages/";
  const scale = [10 / 24 * scaledCreatives, 10 / 24 * scaledCreatives, 0.5 * 0.25];
  const baseImageUrl = "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/";
  const numItems = 16;
  const radius = isMobile ? 0.95 : 1.75;
  const creativePosition = isMobile ? 0.5 : 0;
  const angleStep = (2 * Math.PI) / numItems;

  // Generate a sequence of unique random numbers
  const uniqueRandomNumbers = useMemo(() => Array.from({ length: numItems }, (_, index) => index + 1), [numItems]);

  const CreativeItems = uniqueRandomNumbers.map((_, index) => {
    const angle = (index / numItems) * 2 * Math.PI;
    const x = Math.cos(angle) * radius;
    const y = Math.sin(angle) * radius;
    const position = [x, y, 0];
    const randomImageIndex = index; // Use the index directly to prevent shuffling
    return started ? (
      <AnimatedItem
        key={index}
        scale={scale}
        position={position}
        imageUrl={`${imageUrlBase}CreativesCirc${randomImageIndex + 1}.png`}
        started={started}
        daoImages={daoImages}
      />
    ) : null;
  });

  const imageUrls = [
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Artist1.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Artist2.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Graphic1.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Graphic2.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Digital1.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Digital2.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Innovate1.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Innovate2.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Social1.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Social2.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Influence1.jpg",
    "https://w3omedia.s3.us-east-2.amazonaws.com/w3o+Images/w3oDaoImages/w3oDaoPageImages/Influence2.jpg",
  ];

  const yPositions = [
    -h * 1.6,
    -h * 1.3,
    -h * 1.95,
    -h * 2.35,
    -h * 2.8,
    -h * 3.2,
    -h * 3.7,
    -h * 4.0,
    -h * 4.2,
    -h * 4.7,
    -h * 5.6,
    -h * 6.05,
  ];

  return (
    <Scroll>
      <a.group position={[0, -2 + creativePosition + shiftedCreatives, -10]}>
        {CreativeItems}
      </a.group>
      <group position={[scrollPosition, 0, 0]}>
        {imageUrls.map((imageUrl, index) => (
          <Item
            key={index}
            url={imageUrl}
            scale={[w * 1.25 / 12 * scaledFactor, w * 1.25 / 12 * scaledFactor, 0.25]}
            position={[
              index % 2 === 0 ? w / 32 : -w / 8,
              yPositions[index],
              5,
            ]}
          />
        ))}
      </group>
    </Scroll>
  );
}


const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  //background: #000;
  @media (max-width: 600px) {
    .model {
      transform: scale(0.5);
    }
  }

  @media (min-width: 601px) and (max-width: 1200px) {
    .model {
      transform: scale(0.8);
    }
  }
`;

const innerMaterial = new THREE.MeshStandardMaterial({
  transparent: true,
  opacity: 1,
  color: 'black',
  roughness: 0,
  side: THREE.FrontSide,
  blending: THREE.AdditiveBlending,
  polygonOffset: true,
  polygonOffsetFactor: 1,
  envMapIntensity: 2
});

// Scene Component
function Scene({
  setIsCloudVisible,
  welcomeMessage2,
  setWelcomeMessage2,
  welcomeMessage,
  setWelcomeMessage,
  decreaseShiftedModel,
  damping,
  setDamping,
  isScrolled,
  setIsScrolled,
  shiftedModel,
  isMobile,
  daoImages,
  scrollExplore,
  setDaoImages,
  setScrollExplore,
  isDarkMode,
  setIsFooterVisible,
  isFooterVisible,
  config,
  scroll
}) {
  const { size } = useThree();
  const [scale, setScale] = useState(1);
  const [scale2, setScale2] = useState(1);

  useEffect(() => {
    const updateScale = () => {
      const width = size.width;
      let newScale = 1;

      if (width <= 600) {
        newScale = 1;
      } else if (width > 600 && width <= 1200) {
        newScale = 0.71428571;
      }
      setScale2(newScale);
      setScale(newScale);
    };

    updateScale();

    window.addEventListener('resize', updateScale);

    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, [size?.width]);

  const [position, setPosition] = useState([0, -2.5, 0]);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width <= 600) {
        setPosition([0, 1, 0]);
      } else {
        setPosition([0, -2.15, 0]);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Network
        setWelcomeMessage={setWelcomeMessage}
        setIsCloudVisible={setIsCloudVisible}
        damping={damping}
        setDamping={setDamping}
        decreaseShiftedModel={decreaseShiftedModel}
        shiftedModel={shiftedModel}
        isMobile={isMobile}
        daoImages={daoImages}
        scrollExplore={scrollExplore}
        setDaoImages={setDaoImages}
        welcomeMessage={welcomeMessage}
        setScrollExplore={setScrollExplore}
        isFooterVisible={isFooterVisible}
        setIsFooterVisible={setIsFooterVisible}
        welcomeMessage2={welcomeMessage2}
        setWelcomeMessage2={setWelcomeMessage2}
        scroll={scroll}
        isDarkMode={isDarkMode}
        scale={scale}
        isScrolled={isScrolled}
        setIsScrolled={setIsScrolled}
      />
    </>
  );
}

// DaoModel Component
function DaoModel({
  setShiftedModel,
  scroll,
  daoImages,
  scrollExplore,
  setDaoImages,
  setScrollExplore,
  isFooterVisible,
  setIsFooterVisible,
  isDarkMode,
  orbitControlsEnabled,
  setButtonPopup,
  buttonPopup,
  start,
  toggleOrbitControls,
  modelSwitch,
  started,
  setModelSwitch,
  props,
  welcomeMessage2,
  setWelcomeMessage2,
  welcomeMessage,
  setWelcomeMessage
}) {
  const { camera, gl } = useThree();
  const ref = useRef();
  const [perfSucks, degrade] = useState(false);
  const [position, setPosition] = useState([0, -1.5, 0]);
  const [timedDisplay, setTimedDisplay] = useState(true);
  const [isCloudVisible, setIsCloudVisible] = useState(true);
  const [showCloud, setShowCloud] = useState(true);
  const w = window.innerWidth;
  const isMobile = w <= 600;
  const shiftedModel = isMobile ? 0.2 : 0;
  const cloudRadius = isMobile ? 0.9 : 1.5;
  const [isScrolled, setIsScrolled] = useState(false);

  const springProps = useSpring({
    from: { position: [0, 0.5, 0] },
    to: async (next, cancel) => {
      if (!isMobile && !isScrolled) {
        await next({ position: [0, 0, 0] });
      } else {
        await next({ position: [0, 0.35, 0] });
      }
    },
    config: { mass: 1, tension: 120, friction: 14 },
  });

  const daoAnimation = useSpring({
    position: [0, isMobile ? shiftedModel : 0, 0],
    config: { duration: 1000 },
  });

  const modelScale = useSpring({
    scale: welcomeMessage ? 0.25 : 1,
    position: welcomeMessage ? [0, 8, 0] : [0, 0, 0],
    config: { mass: 1, tension: 120, friction: 14 },
  });

  const scaledText = isMobile ? 0.5 : 1;
  const scaledThree = isMobile ? 3 : 0;
  const scaledHeight = isMobile ? 1.05 : 1;

  window.addEventListener("scroll", handleScroll);

  function handleScroll() {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const bottomOfPage = documentHeight - windowHeight;

    if (scrollY >= bottomOfPage) {
      setIsFooterVisible(true);
    } else {
      setIsFooterVisible(false);
    }
  }

  useEffect(() => {
    if (!isCloudVisible) {
      const timeoutId = setTimeout(() => setShowCloud(false), 1000);
      return () => clearTimeout(timeoutId);
    } else {
      setShowCloud(true);
    }
  }, [isCloudVisible]);

  useEffect(() => {
    if (!scrollExplore) {
      const timer = setTimeout(() => {
        setTimedDisplay(true);
      }, 3000);
      return () => clearTimeout(timer);
    } else {
      setTimedDisplay(false);
    }
  }, [scrollExplore]);

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [damping, setDamping] = useState(0.5);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width <= 600) {
        setPosition([0, -0.5, 0]);
      } else {
        setPosition([0, -1, 0]);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    started && (
      <>
        <group>
          {orbitControlsEnabled && (
            <OrbitControls enableZoom enablePan enableRotate zoomSpeed={0.6} panSpeed={0.5} rotateSpeed={0.4} />
          )}
          <PerformanceMonitor onDecline={() => degrade(true)} />
          <color attach="background" args={['#000']} />
          <pointLight position={[-0.5, -1.75, 0]} intensity={3.5} />
          <Sparkles size={2} color={isDarkMode ? '#fff' : '#cc5500'} scale={[10, 10, 10]} />

          <ScrollControls damping={0} pages={7.725}>
            <a.group>
              <a.group position={[0, shiftedModel, 0]}>
                <Scene
                  setIsCloudVisible={setIsCloudVisible}
                  damping={damping}
                  setDamping={setDamping}
                  isScrolled={isScrolled}
                  setIsScrolled={setIsFooterVisible}
                  shiftedModel={shiftedModel}
                  isMobile={isMobile}
                  daoImages={daoImages}
                  scrollExplore={scrollExplore}
                  setDaoImages={setDaoImages}
                  welcomeMessage2={welcomeMessage2}
                  setWelcomeMessage2={setWelcomeMessage2}
                  welcomeMessage={welcomeMessage}
                  setScrollExplore={setScrollExplore}
                  setWelcomeMessage={setWelcomeMessage}
                  isFooterVisible={isFooterVisible}
                  setIsFooterVisible={setIsFooterVisible}
                  scroll={scroll}
                  isDarkMode={isDarkMode}
                />
              </a.group>
            </a.group>

            <mesh scale={[0.95, 1, 0.95]} material={innerMaterial} />
            <mesh material={innerMaterial} />

            <Items isDarkMode={isDarkMode} daoImages={daoImages} started={started} />

            <Scroll html style={{ width: '100%', height: '100vh' }}>
              {timedDisplay && (
                <MouseBody
                  style={{
                    position: 'absolute',
                    top: isMobile ? '65vh' : '68.5vh',
                    opacity: scrollExplore > 0 ? 0 : 1,
                    transition: 'opacity 0.5s ease',
                  }}
                >
                  <Paragraph>Scroll to explore!</Paragraph>
                  <Mouse className="mouse" />
                </MouseBody>
              )}

              <div style={{ position: 'relative', top: isMobile ? '-25px' : '0' }}>
                <h1 style={{
                  position: 'absolute',
                  fontSize: `${4 * scaledText}rem`,
                  top: `${68.5 * scaledHeight}vh`,
                  left: '7.5vw',
                  transform: 'translate3d(0,-100%,0)',
                  opacity: scrollExplore ? 0 : 1,
                  transition: 'opacity 0.5s ease',
                }}>
                  CREATIVES
                </h1>
                <h1 style={{
                  position: 'absolute',
                  fontSize: `${2 * scaledText}rem`,
                  top: '30vh',
                  right: '10vw',
                  transform: 'rotate(180deg)',
                  transformOrigin: 'center',
                  filter: 'blur(1px)',
                  opacity: scrollExplore ? 0 : 1,
                  transition: 'opacity 0.5s ease',
                }}>
                  CREATIVES
                </h1>
              </div>

              <WelcomeContainer style={{
                zIndex: 999999999999,
                position: 'absolute',
                textAlign: 'left',
                fontSize: `${3 * scaledText}rem`,
                top: isMobile ? '40vh' : '55vh',
                right: welcomeMessage ? (isMobile ? '-30%' : '-25%') : '-50%',
                opacity: welcomeMessage ? 1 : 0,
                transform: welcomeMessage ? 'translate(0, -150px) scale(1)' : 'translate(0, 20px) scale(0.95)',
                filter: welcomeMessage ? 'blur(0)' : 'blur(10px)',
                transition: 'opacity 0.5s ease, transform 0.5s ease, filter 0.5s ease, right 0.5s ease'
              }}>
                <WelcomeMessage>Your #1</WelcomeMessage>
                <WelcomeMessage>Authenticated</WelcomeMessage>
              </WelcomeContainer>

              <WelcomeContainer style={{
                zIndex: 999999999999,
                position: 'absolute',
                textAlign: 'left',
                fontSize: `${3 * scaledText}rem`,
                top: isMobile ? '115vh' : '85vh',
                left: welcomeMessage2 ? (isMobile ? '-15%' : '-35%') : '-100%',
                opacity: welcomeMessage2 ? 1 : 0,
                transform: welcomeMessage2 ? 'translate(0, -150px) scale(1)' : 'translate(0, 20px) scale(0.95)',
                filter: welcomeMessage2 ? 'blur(0)' : 'blur(10px)',
                transition: 'opacity 0.5s ease, transform 0.5s ease, filter 0.5s ease, left 0.5s ease'
              }}>
                <WelcomeMessage>Fashion</WelcomeMessage>
                <WelcomeMessage>MarketPlace</WelcomeMessage>
              </WelcomeContainer>

              <div style={{ position: 'relative', top: isMobile ? '600px' : '600px' }}>
                <h1 style={{
                  position: 'absolute',
                  fontSize: `${4 * scaledText}rem`,
                  top: '135vh',
                  right: '20vw',
                  transform: 'translate3d(0,-100%,0)'
                }}>
                  Artists
                </h1>
                <h1 style={{
                  position: 'absolute',
                  fontSize: `${4 * scaledText}rem`,
                  top: '220vh',
                  left: '5vw'
                }}>
                  Graphic Designer
                </h1>
                <h1 style={{
                  position: 'absolute',
                  fontSize: `${4 * scaledText}rem`,
                  top: '270vh',
                  right: '10vw'
                }}>
                  Digital Creator
                </h1>
                <h1 style={{
                  position: 'absolute',
                  fontSize: `${4 * scaledText}rem`,
                  top: '360vh',
                  left: '15vw'
                }}>
                  Innovator
                </h1>
                <h1 style={{
                  position: 'absolute',
                  fontSize: `${4 * scaledText}rem`,
                  top: '440vh',
                  right: '10vw'
                }}>
                  Social Media Marketer
                </h1>
                <h1 style={{
                  position: 'absolute',
                  fontSize: `${4 * scaledText}rem`,
                  top: '510vh',
                  left: '10vw'
                }}>
                  Influencer
                </h1>

                <h1 style={{
                  position: 'absolute',
                  fontSize: `${1.5 * scaledText}rem`,
                  top: `${619 - (isMobile ? 2 : 0) + (scaledThree * 8)}vh`,
                  right: `${27 - (scaledThree * 5)}vw`,
                  transform: 'translate3d(0,-100%,0)'
                }}>
                  Artists
                </h1>
                <h1 style={{
                  position: 'absolute',
                  fontSize: `${1.5 * scaledText}rem`,
                  top: `${627 - (isMobile ? -1 : 0) - (scaledThree * -1)}vh`,
                  right: '14vw'
                }}>
                  Graphic Designer
                </h1>
                <h1 style={{
                  position: 'absolute',
                  fontSize: `${1.5 * scaledText}rem`,
                  top: `${647 + (isMobile ? -10 : 0) + (scaledThree * 7)}vh`,
                  right: `${21 - (scaledThree * 4)}vw`,
                  transform: 'translate3d(0,-100%,0)'
                }}>
                  Painter
                </h1>
                <h1 style={{
                  position: 'absolute',
                  fontSize: `${1.5 * scaledText}rem`,
                  top: `${658 + (isMobile ? -6 : 0) + (scaledThree * 5)}vh`,
                  right: '15vw'
                }}>
                  Social Media Marketer
                </h1>
                <h1 style={{
                  position: 'absolute',
                  fontSize: `${1.5 * scaledText}rem`,
                  top: `${671 + (isMobile ? -5 : 0) + (scaledThree * 2)}vh`,
                  right: `${25 + (scaledThree * 4)}vw`
                }}>
                  Digital Creator
                </h1>

                <h1 style={{
                  position: 'absolute',
                  fontSize: `${1.5 * scaledText}rem`,
                  top: `${618 + (scaledThree * 3)}vh`,
                  left: `${28 + (scaledThree * 4)}vw`
                }}>
                  3D Modeler
                </h1>
                <h1 style={{
                  position: 'absolute',
                  fontSize: `${1.5 * scaledText}rem`,
                  top: `${629 + (isMobile ? -3 : 0) + (scaledThree * 5)}vh`,
                  left: `${17 - (scaledThree * 4)}vw`
                }}>
                  Innovator
                </h1>
                <h1 style={{
                  position: 'absolute',
                  fontSize: `${1.5 * scaledText}rem`,
                  top: `${645 - (scaledThree * 4) + (isMobile ? -2 : 0)}vh`,
                  left: `${12 + (isMobile ? -5 : 0) + (scaledThree * 2)}vw`
                }}>
                  Clothing Designer
                </h1>
                <h1 style={{
                  position: 'absolute',
                  fontSize: `${1.5 * scaledText}rem`,
                  top: `${661 - (isMobile ? 7 : 0) + (scaledThree * 1.5)}vh`,
                  left: `${22 - (scaledThree * 4)}vw`
                }}>
                  Influencer
                </h1>
                <h1 style={{
                  position: 'absolute',
                  fontSize: `${1.5 * scaledText}rem`,
                  top: `${670 + (isMobile ? -9 : 0) + (scaledThree * 1.5)}vh`,
                  left: '27vw'
                }}>
                  Illustrator
                </h1>
              </div>
            </Scroll>
          </ScrollControls>
        </group>
      </>
    )
  );
}

// Wrapping with Suspense
export default function SuspendedDaoModel(props) {
  return (
    <Suspense fallback={null}>
      <DaoModel {...props} />
    </Suspense>
  );
}
