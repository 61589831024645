import React, { useState } from 'react';
import { FaEthereum } from 'react-icons/fa';
import {
  Col1, Col2,
  FeaturedMainCard,
  MainDescription,
  MainDescription2,
  MainImage,
  MainTitle,
  RarityMark,
  TwoColumnSection,
  ViewDropsButton
} from '../PageComponets/SplitView/SplitViewElements';


export const MobileFeaturedCard = ({ isDarkMode, tempGeneralData }) => {
    const isMobile = window.innerWidth <= 600;
    const [selectedCard, setSelectedCard] = useState(null);
  
    const featuredMain = tempGeneralData[0];
  
    const handleClosePopup = () => {
      setSelectedCard(null);
    };
  


  return (
    <FeaturedMainCard isDarkMode={isDarkMode}>
    <MainImage src={featuredMain.logo} alt="Featured Main Logo" />
    <MainTitle>{featuredMain.header}</MainTitle>
    <MainDescription>{featuredMain.name}</MainDescription>
    <MainDescription>{featuredMain.description}</MainDescription>
    <TwoColumnSection>
      <Col1>
        <MainDescription2>{featuredMain.ethPrice} <FaEthereum /></MainDescription2>
        <MainDescription>Owner: <a href={`https://etherscan.io/address/${featuredMain.owner}`} style={{ color: 'lightblue' }}>{featuredMain.owner}</a></MainDescription>
        <MainDescription>Listed Time: {featuredMain.listedTime}</MainDescription>
      </Col1>
      <Col2>
        <MainDescription>Last Sold: {featuredMain.lastSold} <FaEthereum /></MainDescription>
        <MainDescription>RARITY <RarityMark rarity={featuredMain.rarityLevel} isDarkMode={isDarkMode}>#{featuredMain.rarity}</RarityMark></MainDescription>
        <MainDescription>Floor Difference: {featuredMain.floorDifference}</MainDescription>
      </Col2>

    </TwoColumnSection>
    <ViewDropsButton isDarkMode={isDarkMode}>View Latest Drops</ViewDropsButton>
  </FeaturedMainCard>
  )
}

export default MobileFeaturedCard
