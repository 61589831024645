import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { FaDiscord, FaInfoCircle, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import styled from 'styled-components';

// Framer Motion animations
const rotateAnimation = {
    hidden: { rotateZ: 0 },
    visible: { rotateZ: 180 },
  };
  
  const scaleAnimation = {
    hidden: { scale: 1 },
    visible: { scale: 1.02 },
  };
  
  const fadeInScaleAnimation = {
    hidden: { opacity: 0, scale: 0, filter: 'blur(15px)' },
    visible: { opacity: 1, scale: 1, filter: 'blur(0)' },
  };
  
  const blurIn = {
    hidden: { opacity: 0, y: -2, filter: 'blur(15px)' },
    visible: { opacity: 1, y: 0, filter: 'blur(0)' },
  };
  
  const scaleIn = {
    hidden: { scale: 0.5, filter: 'blur(15px)' },
    visible: { scale: 1, filter: 'blur(0)' },
  };
  
  const fadeIn = {
    hidden: { opacity: 0, scale: 0.8, filter: 'blur(15px)' },
    visible: { opacity: 1, scale: 1, filter: 'blur(0px)' },
  };
  
  const rotateIn = {
    hidden: { opacity: 0, rotateX: -90, rotateZ: -90 },
    visible: { opacity: 1, rotateX: 0, rotateZ: 0 },
  };
  
  const slideIn = {
    hidden: { opacity: 0, x: 200, filter: 'blur(15px)' },
    visible: { opacity: 1, x: 0, filter: 'blur(0px)' },
  };


  
export const CircleIndicatorContainer = styled(motion.div)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  @media screen and (max-width: 600px) {
    transform: translate(-50%, -50%) scale(0.5);
    }
`;

export const CircleIndicator = styled(motion.div)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: ${({ isSelected }) => (isSelected ? '1px solid gold' : '1px solid white')};
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    background-color: ${({ isSelected }) => (isSelected ? 'gold' : 'white')};
    border-radius: 50%;
    position: absolute;
    top: -1.5px;
    left: 20%;
  }
  @media screen and (max-width: 600px) {

  }
`;


export const ExpandedTopCard = styled(motion.div)`
  position: fixed;
  left: 7.5%;
  width: ${({ isMobile }) => isMobile ? '85%' : '40%'};
  height: ${({ isMobile }) => isMobile ? '44vh' : '44vh'};
  bottom: ${({ isMobile }) => isMobile ? '32.5%' : '32.5%'};
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  background-color: ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 0.85)' : 'rgba(0, 0, 0, 0.85)'};
  color: ${({ isDarkMode }) => isDarkMode ? '#000' : '#fff'};
  z-index: 999999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: blur(0px);
  backdrop-filter: blur(10px);
  opacity: 0.8;
  overflow: hidden;
`;

export const ExpandedBottomCard = styled(motion.div)`
  position: fixed;
  left: 7.5%;
  width: ${({ isMobile }) => isMobile ? '85%' : '40%'};
  height: ${({ isMobile }) => isMobile ? '20vh' : '20vh'};
  bottom: 10%;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  background-color: ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 0.85)' : 'rgba(0, 0, 0, 0.85)'};
  color: ${({ isDarkMode }) => isDarkMode ? '#000' : '#fff'};
  z-index: 999999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: blur(0px);
  backdrop-filter: blur(10px);
  opacity: 1;
  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
    }
`;

export const DetailsColumn = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 0px;
  animation: ${slideIn} 0.5s ease-in-out forwards;

  a {
    color: #1DA1F2; // Light blue color for links
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;


export const AboutDescription = styled(motion.div)`
  margin-top: 20px;
  
`;

export const CanvasWrapper = styled.div`
  width: 100vw;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const CardContainer = styled(motion.div)`
  position: absolute !important;
  width: 250px;
  overflow: hidden;
  transform: translate(420px, -350px);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  background-color: ${({ isDarkMode }) => isDarkMode ? '#000' : '#FFF'};
  color: ${({ isDarkMode }) => isDarkMode ? '#000' : '#fff'};
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: blur(0px);
  background-color: ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 0.65)' : 'rgba(0, 0, 0, 0.65)'};
  backdrop-filter: blur(10px);
  opacity: 0.8;
`;

export const ExpandedTopCardColumn = styled(motion.div)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ExitIcon = styled(motion.div)`
  position: fixed;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 999;
  font-size: 24px;
`;

export const Arrow = styled(motion.div)`
  position: absolute;
  top: 50%;
  cursor: pointer;
  font-size: 24px;
  z-index: 1;
  user-select: none;
`;

export const LeftArrow = styled(Arrow)`
  left: 10px;
`;

export const RightArrow = styled(Arrow)`
  right: 10px;
`;

export const LogoImage = styled(motion.img)`
  width: 150px;
  height: auto;
`;

export const SymbolImage = styled(motion.img)`
  width: 30px;
  height: auto;
  margin-right: 10px;
`;

export const Header = styled(motion.h1)`
  margin-top: 10px;
  font-size: 1.5em;
`;

export const Description = styled(motion.p)`
  margin-top: 50px;
  text-shadow: 2px 0 2px black;
  text-transform: uppercase;
  font-size: 1.25rem;
  @media screen and (max-width: 600px) {
    font-size: 1rem;
    }
`;

export const Description2 = styled(motion.p)`
  margin-top: 5px;
  text-shadow: 2px 0 2px black;
  text-transform: uppercase;
  font-size: 0.9rem;
`;

export const TabContainer = styled(motion.div)`
  margin-bottom: -20px;
  margin-top: -20px;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#ccc' : '#333')};

`;

export const Tab = styled(motion.div)`
  padding: 10px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: ${({ isActive, isDarkMode }) => (isActive ? `2px solid yellow` : 'none')};
  color: ${({ isActive }) => (isActive ? 'yellow' : 'inherit')};
  
  &:hover {
    color: yellow;
    text-shadow: 0 0 10px yellow;
  }
`;

export const TabContent = styled(motion.div)`
  padding: 10px;
  overflow-y: none;
  max-height: 50vh;
  @media screen and (max-width: 600px) {
    font-size: 0.7rem;
    margin-top: 10px;
    }
`;

export const TraitList = styled(motion.div)`
  flex: 1 1 calc(25% - 20px);
  background: ${({ isDarkMode }) => (isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)')};
  border-radius: 10px;
  padding: 6px;
  text-align: center;
  font-size: 0.8rem;
`;

export const TraitContainer = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 25px;
  max-width: 100%;
  @media screen and (max-width: 600px) {
    margin-top: 15px;
    }
`;

export const TraitItem = styled(motion.p)`
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
  @media screen and (max-width: 600px) {
    font-size: 8px;
    }
`;

export const TraitItem2 = styled(motion.p)`
  margin-bottom: 5px;
  font-size: 10px;
  @media screen and (max-width: 600px) {
    font-size: 8px;
    }
`;

export const TraitItem3 = styled(motion.p)`
  margin-bottom: 5px;
  font-size: 10px;
  font-weight: bold;
  @media screen and (max-width: 600px) {
    font-size: 8px;
    }
`;
export const DetailsList = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
  gap: 25px;
    }
`;

export const DetailItem = styled(motion.p)`
  margin-bottom: -5px;
`;

export const SocialMediaIcons = styled(motion.div)`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  z-index: 9999;
  cursor: pointer;

  svg {
    transition: filter 0.3s ease-in-out, transform 0.5s ease-in-out;

    &:hover {
      filter: drop-shadow(0 0 10px white);
      transform: translateX(0);
    }

    &:nth-child(1) { animation: ${blurIn} 0.6s ease-in-out forwards; }
    &:nth-child(2) { animation: ${blurIn} 0.7s ease-in-out forwards; }
    &:nth-child(3) { animation: ${blurIn} 0.8s ease-in-out forwards; }
    &:nth-child(4) { animation: ${blurIn} 0.9s ease-in-out forwards; }
    &:nth-child(5) { animation: ${blurIn} 1s ease-in-out forwards; }
  }
`;


// Mobile Components

export const ExpandedBottomCardMobile = styled(motion.div)`
  position: fixed;
  left: 7.5%;
  width: 85%;
  height: 30vh;
  bottom: 10%;
  padding: 0px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  background-color: ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 0.85)' : 'rgba(0, 0, 0, 0.85)'};
  color: ${({ isDarkMode }) => isDarkMode ? '#000' : '#fff'};
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: blur(0px);
  backdrop-filter: blur(10px);
  opacity: 1;
  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
    }
`;

export const InfoIcon = styled(motion.div)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1000;
  font-size: 24px;
`;

export const InfoPopup = styled(motion.div)`
  position: fixed;
  left: 7.5%;
  width: 85%;
  height: 50vh;
  bottom: 60%;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  background-color: ${({ isDarkMode }) => isDarkMode ? 'rgba(256, 256, 256, 0.85)' : 'rgba(0, 0, 0, 0.85)'};
  color: ${({ isDarkMode }) => isDarkMode ? '#000' : '#fff'};
  z-index: 999999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: blur(0px);
  backdrop-filter: blur(10px);
  opacity: 1;
  overflow-y: scroll;
  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
    }
`;



// Tab components
export const Traits = ({ currentDetail, isDarkMode }) => {
  const [traitIndex, setTraitIndex] = useState(0);

 useEffect(() => {
    const timeout = setTimeout(() => {
     document.querySelectorAll('.circle-indicator').forEach(indicator => {
       indicator.style.animation = `${fadeInScaleAnimation} 0.5s ease-in-out forwards`;
     });
   }, 5000); // Delay of 5 seconds after the model is loaded
 
   return () => clearTimeout(timeout);
 }, []);

  const displayedTraits = currentDetail.traits.slice(traitIndex, traitIndex + 4);

 return (
   <TabContent isDarkMode={isDarkMode}>
     <TraitContainer>
       {displayedTraits.map((trait, index) => (
         <TraitList key={index} isDarkMode={isDarkMode}>
           <TraitItem>{trait.trait}</TraitItem>
           <TraitItem2>{trait.attribute}</TraitItem2>
           <TraitItem3>{trait.price} | {trait.rarity}</TraitItem3>
         </TraitList>
       ))}
     </TraitContainer>
   </TabContent>
 );
};

export const Details = ({ currentDetail, isDarkMode }) => (
  <TabContent isDarkMode={isDarkMode}>
    <DetailsList>
      <DetailsColumn>
        <DetailItem><a href={`https://etherscan.io/address/${currentDetail.address}`} target="_blank" rel="noopener noreferrer">{currentDetail.address}</a></DetailItem>
        <DetailItem><a href={`https://etherscan.io/token/${currentDetail.tokenId}`} target="_blank" rel="noopener noreferrer">{currentDetail.tokenId}</a></DetailItem>
        <DetailItem>{currentDetail.tokenStandard}</DetailItem>
      </DetailsColumn>
      <DetailsColumn>
        <DetailItem>{currentDetail.chain}</DetailItem>
        <DetailItem>{currentDetail.earnings} <FaInfoCircle /></DetailItem>
      </DetailsColumn>
    </DetailsList>
  </TabContent>
);

export const About = ({ currentDetail, isDarkMode }) => (
  <TabContent isDarkMode={isDarkMode}>
    <AboutDescription>{currentDetail.aboutDescription}</AboutDescription>
    <SocialMediaIcons>
      <FaDiscord />
      <FaInstagram />
      <FaTiktok />
      <FaYoutube />
      <FaXTwitter />
    </SocialMediaIcons>
  </TabContent>
);