import { motion } from 'framer-motion';
import { HiPlusCircle } from "react-icons/hi2";
import styled from 'styled-components';

export const TraitsTitle = styled(motion.h4)`
  font-size: 1.5em;
  margin-top: 20px;
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
`;

export const TraitDescription = styled(motion.p)`
  margin: 5px 0;
  color: ${({ isDarkMode }) => isDarkMode ? '#ddd' : '#666'};
`;

export const SectionTitle = styled(motion.h4)`
  font-size: 1.5em;
  margin-top: 30px;
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
`;

export const SectionContent = styled(motion.p)`
  margin: 10px 0;
  color: ${({ isDarkMode }) => isDarkMode ? '#ddd' : '#666'};
`;

export const GraphPlaceholder = styled(motion.div)`
  width: 100%;
  height: 200px;
  background-color: ${({ isDarkMode }) => isDarkMode ? '#444' : '#ddd'};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  margin-top: 20px;
`;

export const MainGrid = styled(motion.div)`
  position: relative;
  display: grid;
  gap: 20px;
  padding: 20px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const MainCard = styled(motion.div)`
  background: ${({ isDarkMode }) => isDarkMode ? '#333' : '#fff'};
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  border-radius: 10px;
  padding: 0;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
  width: 100%;
  height: 300px; /* Set explicit height */
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s ease-in-out;
  overflow: hidden; /* Ensure the content does not overflow */

  &:hover {
    box-shadow: 0px 2.5px 7.5px rgba(0, 0, 0, 0.3);
    border-radius: 10px; /* Ensure the shadow remains rounded */
  }

  & > img {
    width: 100%;
    height: 250px; /* Image takes up 70% of the MainCard height */
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  & > div {
    padding: 2px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px; /* Content div takes up 30% of the MainCard height */
    font-size: small;

  }


  @media screen and (max-width: 600px) {
    max-height: 250px;
    margin: 0 auto; /* Center card on mobile */


    & > img {
    width: 100%;
    height: 150px; /* Image takes up 70% of the MainCard height */
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }
  }
`;


export const MainCard2 = styled(motion.div)`

  background: ${({ isDarkMode }) => isDarkMode ? '#333' : '#fff'};
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  border-radius: 10px;
  padding: 0;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
  max-width: 100%;
  max-height: 325px;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s ease-in-out;
  overflow: hidden; /* Ensure the content does not overflow */
  max-width: 600px;
  margin-top: -175px;
  &:hover {
    box-shadow: 0px 2.5px 7.5px rgba(0, 0, 0, 0.3);
    border-radius: 10px; /* Ensure the shadow remains rounded */
  }

  & > img {
    width: 50%;
    min-height: 50%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    transition: transform 0.3s ease-in-out;
    overflow: hidden; /* Prevent image overflow */

    &:hover {
      transform: scale(1.05);
    }
  }


  @media screen and (max-width: 600px) {
    max-width: 350px;
    margin: 0 auto; /* Center featured card on mobile */

    max-height: 100%;
  }


`;

export const FeaturedMainCard = styled(MainCard2)`
  padding: 20px;
  max-height: 450px;
  width: 40vw;

  & > img {
    width: 100%;
    height: 60%;
    overflow: hidden; /* Prevent overflow */
  }

  @media (max-width: 600px) {
    width: 100%;
    max-height: 315px;

    
  }
`;



export const TwoColumnSection = styled.div`
 display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;


  @media (max-width: 600px) {
    margin-top: 5px;

    
  }
`;

export const Col1 = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
 // padding-right: 20px; // Increased padding for visual clarity
`;

export const Col2 = styled.div`
 // flex: 1;

  //padding-left: 20px;
`;

// Adding a test background color can help visualize the column division:
// You can temporarily add background colors to Col1 and Col2 to see if they are actually re





export const MainImage = styled.img`
  width: 100%;
  height: 70%;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
  overflow: hidden; /* Ensure the image does not overflow */
`;

export const MainTitle = styled.h3`
  margin: 5px 0;
  font-size: 1.1em;
`;

export const MainDescription = styled.p`
  color: ${({ isDarkMode }) => isDarkMode ? '#ddd' : '#666'};
  font-size: 0.9em;
  margin: 2px 0;
`;


export const MainDescription2 = styled.p`
  color: ${({ isDarkMode }) => isDarkMode ? '#ddd' : '#000'};
  font-weight: bold;
  margin: 0px 0;
display: flex;
align-items: center;
font-size: 0.9em;

`;



export const MainPrice = styled.p`
  font-weight: bold;
  margin: 4px 0;
  display: flex;
  align-items: center;
  font-size: 0.9em;
`;

export const MainOwner = styled.p`
  font-size: 0.8em;
  color: ${({ isDarkMode }) => isDarkMode ? '#aaa' : '#888'};
  margin: 4px 0;
`;

export const CollectionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: ${({ isMobile }) => isMobile ? '12.5vh' : '12.5vh'};
  white-space: nowrap;
  margin-left: 5%;
  @media screen and (max-width: 600px) {
   // grid-template-columns: 1fr;
   // max-width: 300px;
   font-size: 0.8rem;

  }
`;

export const MainCollectionTitle = styled.h2`
  font-size: 2em;
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  text-align: left;
  margin: 0;
  white-space: nowrap;
`;

export const MainCollectionSubheader = styled.h3`
  font-size: 1.5em;
  color: ${({ isDarkMode }) => isDarkMode ? '#aaa' : '#666'};
  text-align: left;
  margin: 0;
  white-space: nowrap;
`;

export const ViewDropsButton = styled.button`
  background-color: ${({ isDarkMode }) => isDarkMode ? '#444' : '#ddd'};
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  margin-top: 20px;
  @media (max-width: 600px) {
    margin-top: 5px;

    
  }
`;

export const SmallText = styled.span`
  font-size: 0.7em;
  color: ${({ isDarkMode }) => isDarkMode ? '#ccc' : '#999'};
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
`;

export const PlusButton = styled(HiPlusCircle)`
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  border-radius: 100%;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 85%;
  bottom: 2%;
  padding: 0;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const MainPopup = styled.div`
  background: ${({ isDarkMode }) => isDarkMode ? '#333' : '#fff'};
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  border-radius: 10px;
  padding: 20px;
  width: 70vw;
  height: 90vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  overflow-x: hidden;
`;

export const BlurBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 9999;
`;

export const CloseIcon = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5em;
  cursor: pointer;
  z-index: 99999999999;
`;

// Styled component for rarity highlighting
export const RarityMark = styled.mark`
  margin: 0 0.4em;
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  font-size: 0.6em; /* Smaller font size for the rarity value */
  background-image: ${({ rarity }) => {
    switch (rarity) {
      case 'legendary':
        return 'linear-gradient(to right, rgba(255, 225, 0, 0.1), rgba(255, 225, 0, 0.7) 4%, rgba(255, 180, 0, 0.3))';
      case 'epic':
        return 'linear-gradient(to right, rgba(255, 180, 0, 0.1), rgba(255, 127, 0, 0.7) 4%, rgba(255, 127, 0, 0.3))';
      case 'rare':
        return 'linear-gradient(to right, rgba(255, 127, 0, 0.1), rgba(255, 64, 0, 0.7) 4%, rgba(255, 64, 0, 0.3))';
      case 'uncommon':
        return 'linear-gradient(to right, rgba(255, 64, 0, 0.1), rgba(255, 0, 0, 0.7) 4%, rgba(255, 0, 0, 0.3))';
      case 'common':
        return 'linear-gradient(to right, rgba(255, 0, 0, 0.1), rgba(192, 192, 192, 0.7) 4%, rgba(192, 192, 192, 0.3))';
      default:
        return 'linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.7) 4%, rgba(255, 255, 255, 0.3))';
    }
  }};
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
`;

export const GridItemWrapper = styled.div`
  padding: 10px;
  box-sizing: border-box;


  @media (max-width: 600px) {
    overflow: hidden;
    display: flex;
    justify-content: center; /* Center items on mobile */
  }
`;


export const AnimatedCollectionWrapper = styled(motion.div)`
  width: ${({ isFullScreen, isMobile }) => isMobile ? '100%' : '40%'};
  float: left;
  height: 85vh;
  margin-left: ${({ isMobile }) => isMobile ? '0' : '7.5%'};
  bottom: 0;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 999;

  @media (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden;  /* Ensure horizontal scroll is disabled */
  }
`;
