import React, { useState } from 'react';
import {
  ApplyButton,
  Dropdown,
  DropdownOption,
  FilterContainer,
  FilterSection,
  FilterTitle,
  InputWrapper,
  PriceRangeInput,
  RarityRangeInput
} from './FilterElements';

const Filter = ({ onApplyFilter, isDarkMode, isFilterVisible }) => {
  const [blockchain, setBlockchain] = useState('Ethereum');
  const [status, setStatus] = useState('All');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [currency, setCurrency] = useState('ETH');
  const [minRarity, setMinRarity] = useState(1);
  const [maxRarity, setMaxRarity] = useState(6666);
  const [selectedTrait, setSelectedTrait] = useState('');

  const handleApplyFilter = () => {
    const filters = {
      blockchain,
      status,
      minPrice,
      maxPrice,
      currency,
      minRarity,
      maxRarity,
      selectedTrait,
    };
    onApplyFilter(filters);
  };

  return (
    <FilterContainer
      isDarkMode={isDarkMode}
      initial={{ height: 0, width: 0 }}
      animate={{
        height: isFilterVisible ? '300px' : 0, 
        width: isFilterVisible ? '65%' : 0
      }}
      transition={{
        height: { duration: 0.3, ease: 'easeInOut' },
        width: { duration: 0.3, ease: 'easeInOut', delay: isFilterVisible ? 0.3 : 0 }
      }}
      style={{ overflow: 'hidden' }} // Ensure content is hidden during the transition
    >
      {/* Blockchain Filter */}
      <FilterSection>
        <FilterTitle>Blockchain</FilterTitle>
        <Dropdown value={blockchain} onChange={(e) => setBlockchain(e.target.value)}>
          <DropdownOption value="Ethereum">Ethereum</DropdownOption>
          <DropdownOption value="WEth">WEth</DropdownOption>
          <DropdownOption value="SOL">SOL</DropdownOption>
          <DropdownOption value="Moonbeam">Moonbeam</DropdownOption>
          <DropdownOption value="Celo">Celo</DropdownOption>
          <DropdownOption value="Etherlink">Etherlink</DropdownOption>
          <DropdownOption value="Lisk">Lisk</DropdownOption>
          <DropdownOption value="Polygon">Polygon</DropdownOption>
          <DropdownOption value="Rari Chain">Rari Chain</DropdownOption>
          <DropdownOption value="Kroma">Kroma</DropdownOption>
          <DropdownOption value="Immutable X">Immutable X</DropdownOption>
          <DropdownOption value="Base">Base</DropdownOption>
        </Dropdown>
      </FilterSection>

      {/* Status Filter */}
      <FilterSection>
        <FilterTitle>Status</FilterTitle>
        <Dropdown value={status} onChange={(e) => setStatus(e.target.value)}>
          <DropdownOption value="All">All</DropdownOption>
          <DropdownOption value="Listed">Listed</DropdownOption>
          <DropdownOption value="On auction">On auction</DropdownOption>
          <DropdownOption value="New">New</DropdownOption>
          <DropdownOption value="Has offers">Has offers</DropdownOption>
        </Dropdown>
      </FilterSection>

      {/* Price Range Filter */}
      <FilterSection>
        <FilterTitle>Price Range</FilterTitle>
        <InputWrapper>
          <PriceRangeInput 
            type="text" 
            placeholder="Min Price" 
            value={minPrice} 
            onChange={(e) => setMinPrice(e.target.value)} 
          />
          <PriceRangeInput 
            type="text" 
            placeholder="Max Price" 
            value={maxPrice} 
            onChange={(e) => setMaxPrice(e.target.value)} 
          />
          <Dropdown value={currency} onChange={(e) => setCurrency(e.target.value)}>
            <DropdownOption value="ETH">ETH</DropdownOption>
            <DropdownOption value="USD">USD</DropdownOption>
            <DropdownOption value="SOL">SOL</DropdownOption>
            <DropdownOption value="WETH">WETH</DropdownOption>
            <DropdownOption value="Polygon">Polygon</DropdownOption>
            <DropdownOption value="BTC">BTC</DropdownOption>
          </Dropdown>
        </InputWrapper>
      </FilterSection>

      {/* Rarity Rank Filter */}
      <FilterSection>
        <FilterTitle>Rarity Rank</FilterTitle>
        <InputWrapper>
          <RarityRangeInput 
            type="number" 
            placeholder="Min Rarity" 
            value={minRarity} 
            onChange={(e) => setMinRarity(e.target.value)} 
            min={1}
            max={6666}
          />
          <RarityRangeInput 
            type="number" 
            placeholder="Max Rarity" 
            value={maxRarity} 
            onChange={(e) => setMaxRarity(e.target.value)} 
            min={1}
            max={6666}
          />
        </InputWrapper>
      </FilterSection>

      {/* Traits Filter */}
      <FilterSection>
        <FilterTitle>Traits</FilterTitle>
        <Dropdown value={selectedTrait} onChange={(e) => setSelectedTrait(e.target.value)}>
          {/* Dynamically generate options based on the available traits in the collection */}
          {/* Example hardcoded options */}
          <DropdownOption value="Background: Sunset">Background: Sunset</DropdownOption>
          <DropdownOption value="Hair: Blonde">Hair: Blonde</DropdownOption>
          <DropdownOption value="Eyes: Blue">Eyes: Blue</DropdownOption>
        </Dropdown>
      </FilterSection>

      <ApplyButton onClick={handleApplyFilter}>Apply</ApplyButton>
    </FilterContainer>
  );
};

export default Filter;
