import { Environment, Lightformer, useScroll } from '@react-three/drei';
import { Canvas, useFrame } from '@react-three/fiber';
import { getProject } from '@theatre/core'; // Add this for Theatre.js usage
import { easing } from 'maath';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import Footer from '../../components/Footer/Footer.js';
import MasterPlan from '../../components/MasterPlan3D/MasterPlan.js';
import { MasterPlanOverlay } from '../../components/MasterPlan3D/MasterPlanOverlay/Overlay.js';
import MeetTeamOverlay from '../../components/MeetTeam/MeetTeamOverlay/Overlay.js';
import Team from '../../components/MeetTeam/Team.js';
import icon2 from '../../images/w3oNameHorzWhite.png';
import DaoOverlay from '../JoinDao/DaoOverlay/Overlay.js';
import ClosetOverlay from '../Model/ClosetOverlay/Overlay.js';
import ClosetModel from '../Model/Model';
import DaoModel from '../Model/Model2';
import theatreState from './../../components/MasterPlan3D/site.json';
import './Model.css';
import { BlurBackground, ClosetBottom, ClosetTop, Mouse, MouseBody, Paragraph, WebLogo, WebLogoCont } from './ModelElements.js';
const Wrap = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-track {
    background: lightgrey;
  }

  @media (max-width: 600px) {
    .model {
      transform: scale(0.5);
    }
  }

  @media (min-width: 601px) and (max-width: 1200px) {
    .model {
      transform: scale(0.8);
    }
  }
`;






function EnvDao({ perfSucks }) {
  const ref = useRef();
  const width = window.innerWidth;
  const sensitivity = width <= 600 ? 0.2 : 1;
  const rangeFactor = width <= 600 ? 0.5 : 1; // Reducing the range for smaller screens

  useFrame((state, delta) => {
    if (!perfSucks) {
      easing.damp3(ref.current.rotation, [Math.PI / 2, 0, (state.clock.elapsedTime / 50 + state.pointer.x * sensitivity) * rangeFactor], 0.2, delta);
      easing.damp3(state.camera.position, [
        Math.sin(state.pointer.x / 30 * sensitivity) * 9 ,
        1.25 + state.pointer.y / 5 * sensitivity ,
        Math.cos(state.pointer.x / 4 * sensitivity) * 9 
      ], 0.5, delta);
      state.camera.lookAt(0, 0, 0);
    }
  });

  return (
    <Environment frames={perfSucks ? 1 : Infinity} resolution={256} background blur={0}>
      <Lightformer intensity={12} rotation-x={Math.PI / 2} position={[0, 0, -9]} scale={[10, 10, 1]} />
      <Lightformer intensity={12} rotation-x={Math.PI / 2} position={[0, 0, -9]} scale={[10, 10, 1]} />
      <group rotation={[Math.PI / 2, 1, 0]}>
        {[2, -2, 2, -4, 2, -5, 2, -9].map((x, i) => (
          <Lightformer key={i} intensity={10} rotation={[Math.PI / 4, 0, 0]} position={[x, 4, i * 4]} scale={[4, 1, 1]} />
        ))}
        <Lightformer intensity={0.5} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
        <Lightformer intensity={0.5} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
        <Lightformer intensity={0.5} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[50, 2, 1]} />
      </group>
      <group ref={ref}>
        <Lightformer intensity={1} form="ring" color="ADD7F2" rotation-y={Math.PI / 2} position={[-5, 2, -1]} scale={[0, 0, 0]} />
      </group>
    </Environment>
  );
}



function EnvCloset({ perfSucks, orbitControlsEnabled }) {
  const ref = useRef();

  useFrame((state, delta) => {
    if (!perfSucks && !orbitControlsEnabled) {
      easing.damp3(ref.current.rotation, [Math.PI / 2, 0, state.clock.elapsedTime / 50 + state.pointer.x], 0.2, delta);
      easing.damp3(state.camera.position, [Math.sin(state.pointer.x / 4) * 9, 1.25 + state.pointer.y, Math.cos(state.pointer.x / 4) * 9], 0.5, delta);
      state.camera.lookAt(0, 0, 0);
    }
  });

  return (
    <Environment background={false} // Set to `true` if you want this to be visible as your scene's background
    files="https://w3omedia.s3.us-east-2.amazonaws.com/w3o+hdr/metro_noord_1k.hdr"
    path="" // Since you're using a direct URL, the path isn't needed.
    preset={null} // Not using a preset since you're loading a custom HDR
frames={perfSucks ? 1 : Infinity} resolution={256}  blur={0}>
      <Lightformer intensity={12} rotation-x={Math.PI / 2} position={[0, 0, -9]} scale={[10, 10, 1]} />
      <Lightformer intensity={12} rotation-x={Math.PI / 2} position={[0, 0, -9]} scale={[10, 10, 1]} />
      <group rotation={[Math.PI / 2, 1, 0]}>
        {[2, -2, 2, -4, 2, -5, 2, -9].map((x, i) => (
          <Lightformer key={i} intensity={10} rotation={[Math.PI / 4, 0, 0]} position={[x, 4, i * 4]} scale={[4, 1, 1]} />
        ))}
        <Lightformer intensity={0.5} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
        <Lightformer intensity={0.5} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
        <Lightformer intensity={0.5} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[50, 2, 1]} />
      </group>
      <group ref={ref}>
        <Lightformer intensity={1} form="ring" color="ADD7F2" rotation-y={Math.PI / 2} position={[-5, 2, -1]} scale={[0, 0, 0]} />
      </group>
    </Environment>
  );
}

function WebLogoContainer({ onClick, isDarkMode }) {
  return (
    <>
      {isDarkMode ? (
        <WebLogo to ='/' src={icon2} alt="Icon"/>
                              
      ) : (
        <WebLogo to ='/' src={icon2} alt="Icon"  />
                              
      )}
    </>
  );
}




export default function NetworkScene({trigger4,isMobile, setTrigger4, currentState, setCurrentState, setButtonPopup4, buttonPopup4, setWhitelistPopup, whitelistPopup, isFooterVisible, setOrbitControlsEnabled, setIsFooterVisible, started,  buttonPopup, buttonPopup2, buttonPopup3, setButtonPopup, setButtonPopup2, setButtonPopup3,  isDarkMode, orbitControlsEnabled, modelSwitch, setModelSwitch, planSwitch, setPlanSwitch, roadmapSwitch, setRoadmapSwitch, toggleOrbitControls, daoPopup, setDaoPopup, toggleUtilities, }) {
  const orbitControlsRef = useRef();
  const [perfSucks, degrade] = useState(false);
  const [scrollExplore, setScrollExplore] = useState(false);
  const [daoImages, setDaoImages] = useState(false);
  const [position, setPosition] = useState([0, -2, 0]);
  const [welcomeMessage, setWelcomeMessage] = useState(false); // New state for welcome message
  const [welcomeMessage2, setWelcomeMessage2] = useState(false); // New state for welcome message
  const project = getProject('New Game', { state: theatreState });


// In your component, ensure you handle the state and updates properly

  
  const mouseBodyRef = useRef();
  const [isMouseVisible, setIsMouseVisible] = useState(true);
 // const [buttonPopup, setButtonPopup] = useState(false);
  const scroll = useScroll()
  // const { width: w, height: h } = useThree((state) => state.viewport);
  const w = window.innerWidth;
  //const shiftedModel = isMobile ? .35 : 0; 
  //const [isMobile, setIsMobile] = useState(true); // Set isMobile to true initially
  const [shiftedModel, setShiftedModel] = useState(isMobile ? 0.35 : 0);

  const sceneContainerRef = useRef();

  const footerSpring = useSpring({
    opacity: (!modelSwitch && isFooterVisible) ? 1 : 0,
    config: { tension: 200, friction: 20 },
  });
  
  const overlaySpring = useSpring({
    opacity: (!modelSwitch && !isFooterVisible) ? 1 : 0,
    config: { tension: 200, friction: 20 },
  });
  
  const canvasSpring = useSpring({
    transform: modelSwitch ? 'translateY(0px)' : (isFooterVisible ? 'translateY(-50px)' : 'translateY(0px)'),
    config: { tension: 200, friction: 20 },
  });

  const contentSpring = useSpring({
    transform: modelSwitch ? 'translateY(0px)' : (isFooterVisible ? 'translateY(-50px)' : 'translateY(0px)'),
    config: { tension: 200, friction: 20 },
  });


  // ...
  
  useEffect(() => {
    if (isFooterVisible) {
      sceneContainerRef.current.scrollTo({
        //top: sceneContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
    if (!isFooterVisible) {
      sceneContainerRef.current.scrollTo({
       // top: 0,
        behavior: 'smooth',
      });
    }
  }, [isFooterVisible]);

  useEffect(() => {
    const handleResize = () => {
      // Update any state or perform actions needed on resize
      console.log('Window resized to: ', window.innerWidth, window.innerHeight);

      // For example, if you need to update the window size in state
      // setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);

    // Initial resize call
    handleResize();

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 





  const renderEnvironment = () => {
    switch (currentState) {
      case 1:
        return <EnvDao perfSucks={perfSucks} />;
      case 2:
        return <EnvCloset orbitControlsEnabled={orbitControlsEnabled} perfSucks={perfSucks} />;
      default:
        return null;
    }
  };

  const renderModel = () => {
    switch (currentState) {
      case 1:
        return            <DaoModel
        welcomeMessage={welcomeMessage}
        setWelcomeMessage={setWelcomeMessage}
        welcomeMessage2={welcomeMessage2}
        setWelcomeMessage2={setWelcomeMessage2}
        setShiftedModel={setShiftedModel}
        shiftedModel={shiftedModel}
        isMobile={isMobile}
        daoImages={daoImages}
        scrollExplore={scrollExplore}
        setDaoImages={setDaoImages}
        setScrollExplore={setScrollExplore}
        started={started}
        isFooterVisible={isFooterVisible}
        setIsFooterVisible={setIsFooterVisible}
        perfSucks={perfSucks}
        scroll={scroll}
        setModelSwitch={setModelSwitch}
        modelSwitch={modelSwitch}
        setButtonPopup={setButtonPopup}
        buttonPopup={buttonPopup}
        orbitControlsEnabled={orbitControlsEnabled}
        toggleOrbitControls={toggleOrbitControls}
        isDarkMode={isDarkMode}
        setPlanSwitch={setPlanSwitch}
      />;
      case 2:
        return                    <ClosetModel
        isMouseVisible={isMouseVisible} setIsMouseVisible={setIsMouseVisible}
        orbitControlsRef={orbitControlsRef}  isFooterVisible={isFooterVisible}  scroll={scroll}
        setModelSwitch={setModelSwitch} modelSwitch={modelSwitch} setButtonPopup={setButtonPopup} buttonPopup={buttonPopup}
        orbitControlsEnabled={orbitControlsEnabled} toggleOrbitControls={toggleOrbitControls}  isDarkMode={isDarkMode}
        setIsFooterVisible={setIsFooterVisible} setWhitelistPopup={setWhitelistPopup}  whitelistPopup={whitelistPopup} 
      />
      case 3:
        return      <MasterPlan project={project} isMobile={isMobile} setDaoPopup={setDaoPopup} isDarkMode={isDarkMode}/>;
      case 4:
        return <Team isDarkMode={isDarkMode} />;
      default:
        return null;
    }
  };

  const renderOverlay = () => {
    switch (currentState) {
      
      case 1:
        return (
          <animated.div style={{ ...overlaySpring }}>
          {!isFooterVisible && (
            <DaoOverlay
                      currentState={currentState}
              setCurrentState={setCurrentState}
             setIsMouseVisible={setIsMouseVisible}
              toggleUtilities={toggleUtilities}
              started={started}
              scroll={scroll}
              trigger4={trigger4}
              setTrigger4={setTrigger4}
              setButtonPopup4={setButtonPopup4}
              buttonPopup4={buttonPopup4}
              setModelSwitch={setModelSwitch}
              modelSwitch={modelSwitch}
              isDarkMode={isDarkMode}
              buttonPopup={buttonPopup}
              setPlanSwitch={setPlanSwitch}
            />
          )}
        </animated.div>
        );
  
      case 2:
        return (
          <>

{orbitControlsEnabled ? (   
  <>      
    <ClosetTop    style={{ y: orbitControlsEnabled ? -100 : 100 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5 }}
          isDarkMode={isDarkMode}/>   
                  <WebLogoCont 
           initial={{ y: orbitControlsEnabled ? -100 : 100, x: '-50%' }}
  animate={{ y: 0, x: '-50%' }}
          transition={{ duration: 0.25, delay: 0.5 }}   to={'/'}  isDarkMode={isDarkMode}>
                        {/*<NavIcon src = {symbol} />*/}
                                                        <WebLogoContainer    isDarkMode={isDarkMode} />
                                    
                                {/*<NavLogoTextWrap onCLick={toggleOrbitControls} > <NavLogoText >EXPLORE    CLOSETS </NavLogoText><ExploreCloset onCLick={toggleOrbitControls}  /></NavLogoTextWrap>*/}
                            </WebLogoCont>

<MouseBody
   
   style={{
  position: 'absolute',
  opacity: isMouseVisible > 0 ? 1 : 0, // Set opacity to isMouseVisible (0 or 1)
  transition: 'opacity 0.5s ease', // Add a 0.5s transition with ease timing function
}}
>
          <Paragraph>Click & Drag to Interact!</Paragraph>
          <Mouse className="mouse" />
        </MouseBody>    
          
               <BlurBackground  style={{
  position: 'absolute',
  opacity: isMouseVisible > 0 ? 1 : 0, // Set opacity to isMouseVisible (0 or 1)
  transition: 'opacity 0.5s ease', // Add a 0.5s transition with ease timing function
}} isMouseVisible={isMouseVisible}></BlurBackground>    
     
    <ClosetBottom  style={{ y: orbitControlsEnabled ? 100 : -100 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5 }} 
          isDarkMode={isDarkMode}/>   
  </>
) : (
  <null/>
)}
            <ClosetOverlay
              currentState={currentState}
              setCurrentState={setCurrentState}
              setIsMouseVisible={setIsMouseVisible}
              setOrbitControlsEnabled={setOrbitControlsEnabled}
              orbitControlsEnabled={orbitControlsEnabled}
              toggleOrbitControls={toggleOrbitControls}
              toggleUtilities={toggleUtilities}
              isDarkMode={isDarkMode}
              setButtonPopup={setButtonPopup}
              setButtonPopup2={setButtonPopup2}
              setButtonPopup3={setButtonPopup3}
              buttonPopup={buttonPopup}
              buttonPopup2={buttonPopup2}
              buttonPopup3={buttonPopup3}
              setDaoPopup={setDaoPopup}
              daoPopup={daoPopup}
              started={started}
              setModelSwitch={setModelSwitch}
              modelSwitch={modelSwitch}
            />
          </>
        );
        case 3:
          return (
            <MasterPlanOverlay
            currentState={currentState}
            setCurrentState={setCurrentState}
              setIsMouseVisible={setIsMouseVisible}
              toggleUtilities={toggleUtilities}
              started={started}
              scroll={scroll}
              trigger4={trigger4}
              setTrigger4={setTrigger4}
              setButtonPopup4={setButtonPopup4}
              buttonPopup4={buttonPopup4}
              setModelSwitch={setModelSwitch}
              modelSwitch={modelSwitch}
              isDarkMode={isDarkMode}
              buttonPopup={buttonPopup}
              setPlanSwitch={setPlanSwitch}
            />
          );

          case 4:
            return (
              <MeetTeamOverlay
              currentState={currentState}
              setCurrentState={setCurrentState}
                setIsMouseVisible={setIsMouseVisible}
                toggleUtilities={toggleUtilities}
                started={started}
                scroll={scroll}
                trigger4={trigger4}
                setTrigger4={setTrigger4}
                setButtonPopup4={setButtonPopup4}
                buttonPopup4={buttonPopup4}
                setModelSwitch={setModelSwitch}
                modelSwitch={modelSwitch}
                isDarkMode={isDarkMode}
                buttonPopup={buttonPopup}
                setPlanSwitch={setPlanSwitch}
              />
            );
      default:
        return null;
    }
  };
  


  return (
    <>
        <animated.div
          ref={sceneContainerRef}
          style={{
            position: 'relative',
            height: '100vh',
            width: '100vw',
            overflow: 'hidden',
            ...canvasSpring,
          }}
        >
          <animated.div
            style={{
              position: 'relative',
              height: '100vh',
              width: '100vw',
              overflow: 'hidden',
              ...contentSpring,
            }}
          >
           {/* {welcomeMessage && (
            <BlurredBackground
              isDarkMode={isDarkMode}
     
            />
          )} */}
      <Wrap>
      <Suspense fallback={null}>
      <Canvas style={{ width: '100%', height: '100%' }} camera={{ theatreKey: 'camera', position: [20, 0.9, 20], fov: 26 }}>
     
          {renderEnvironment()}
          {renderModel()}
       
        </Canvas>
        </Suspense>
      </Wrap>
      </animated.div>
      </animated.div>


      {renderOverlay()}
      <animated.div style={footerSpring}>
        {isFooterVisible &&  

<Footer
setDaoPopup={setDaoPopup}
daoPopup={daoPopup}
isDarkMode={isDarkMode}
setWhitelistPopup={setWhitelistPopup}
whitelistPopup={whitelistPopup}
/>}
      </animated.div>
    </>
  );
}
