import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { FaEthereum } from 'react-icons/fa';
import { FixedSizeGrid as Grid } from 'react-window';
import styled from 'styled-components';
//import '../../model.css';
import InfoPopup from '../InfoPopup';
import MobileFeaturedCard from '../MobileFeaturedCard';
import {
  CollectionHeaderWrapper,
  FeaturedMiniCard,
  MiniCard, MiniCollectionSubheader, MiniCollectionTitle, MiniDescription, MiniImage,
  MiniOwner,
  MiniPrice, MiniTitle,
  PlusButton,
  RarityMark,
  ViewDropsButton
} from './MiniCardElements.js';

export const GridItemWrapper = styled.div`
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;


`;


const GridItemWrapper2 = styled.div`

  box-sizing: border-box;

  margin-top: 0px;
  @media (max-width: 600px) {
    //display: flex;
    justify-content: center; /* Center items on mobile */
  }
`;

const AnimatedCollectionWrapper = styled(motion.div)`
  width: ${({ isMobile }) => (isMobile ? '100%' : '90%')};
  float: left;
  height: 85vh;
  transform: translateX(-50%) !important;
  left: 50%;
  bottom: 0;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 999;

  @media (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden;  /* Ensure horizontal scroll is disabled */
  }
`;
export const MiniCardView = ({ isDarkMode, tempGeneralData, isFilterVisible }) => {
  const isMobile = window.innerWidth <= 600;
  const [selectedCard, setSelectedCard] = useState(null);

  const featuredMain = tempGeneralData[0];
  const otherMains = tempGeneralData.slice(1); // Exclude the featured main card

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleClosePopup = () => {
    setSelectedCard(null);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === 'blur-background') {
      handleClosePopup();
    }
  };

  // Adjust column count based on window width
  const columnCount = isMobile ? 4 : 
    window.innerWidth <= 700 ? 7 :
    window.innerWidth <= 800 ? 8 :
    window.innerWidth <= 900 ? 9 :
    window.innerWidth <= 1000 ? 10 :
    window.innerWidth <= 1100 ? 11 :
    window.innerWidth <= 1200 ? 12 : 12;

    const columnWidth = isMobile ? window.innerWidth / 4 : Math.floor(window.innerWidth * 0.9 / columnCount);
    const rowHeight = isMobile ? 165 : 200; // Defined earlier
    // Adjust row height for mobile
    const gridWidth = columnWidth * columnCount;
  
    const rowCount = Math.ceil((otherMains.length + 2) / columnCount); // +2 for header and featured card
  
  return (
    <>
      {selectedCard && (
        <InfoPopup
          selectedCard={selectedCard}
          handleClosePopup={handleClosePopup}
          handleOutsideClick={handleOutsideClick}
          isDarkMode={isDarkMode}
        />
      )}
  
      <AnimatedCollectionWrapper         isMobile={isMobile}
        initial={{ height: '85vh' }}
        animate={{ height: (isFilterVisible && !isMobile) ? '77.5vh' : '85vh' }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}>
        <Grid
          columnCount={columnCount}
          columnWidth={columnWidth}
          height={isMobile ? window.innerHeight - 100 : window.innerHeight - 100}
          rowCount={rowCount}
          rowHeight={rowHeight} // Use the defined rowHeight here directly
          width={isMobile ? window.innerWidth : columnWidth * columnCount}
        >

          {({ columnIndex, rowIndex, style }) => {
            let index = rowIndex * columnCount + columnIndex;

            if (rowIndex === 0 && columnIndex === 0) {
              // Render the header in the first row, spanning all columns
              return (
                <GridItemWrapper key="header" >
                  <CollectionHeaderWrapper isMobile={isMobile}>
                    <MiniCollectionTitle isDarkMode={isDarkMode}>Our Collection</MiniCollectionTitle>
                    <MiniCollectionSubheader isDarkMode={isDarkMode}>
                      Explore the latest and greatest
                    </MiniCollectionSubheader>
                  </CollectionHeaderWrapper>
                </GridItemWrapper>
              );
            }

            if (rowIndex === 1 && columnIndex === 0) {
              // Render the featured card in the second row, spanning all columns
              return isMobile ? (
                <div style={{marginTop: '185px'}} key="mobile-featured" >
                  <MobileFeaturedCard 
                    isDarkMode={isDarkMode} 
                    tempGeneralData={tempGeneralData} 
                  />
                </div>
              ) : (
                <GridItemWrapper key="featured" >
                  <FeaturedMiniCard isDarkMode={isDarkMode}>
                    <MiniImage src={featuredMain.logo} alt="Featured MiniCard Logo" />
                    <MiniTitle>{featuredMain.header}</MiniTitle>
                    <MiniDescription>{featuredMain.name}</MiniDescription>
                    <MiniDescription>{featuredMain.description}</MiniDescription>
                    <MiniPrice>{featuredMain.ethPrice} <FaEthereum /></MiniPrice>
                    <MiniOwner>
                      Owner: <a href={`https://etherscan.io/address/${featuredMain.owner}`} style={{ color: 'lightblue' }}>{featuredMain.owner}</a>
                    </MiniOwner>
                    <RarityMark rarity={featuredMain.rarityLevel}>#{featuredMain.rarity}</RarityMark>
                    <MiniDescription>Floor Difference: {featuredMain.floorDifference}</MiniDescription>
                    <MiniDescription>Last Sold: {featuredMain.lastSold} <FaEthereum /></MiniDescription>
                    <MiniDescription>Listed Time: {featuredMain.listedTime}</MiniDescription>
                    <ViewDropsButton isDarkMode={isDarkMode}>View Latest Drops</ViewDropsButton>
                  </FeaturedMiniCard>
                </GridItemWrapper>
              );
            }


            // Adjust index for the mini cards
            index -= 2 * columnCount; // Subtract the two header rows

            if (index >= 0 && index < otherMains.length) {
              const main = otherMains[index];
              return (
                <GridItemWrapper2 key={main.id} style={{ ...style, marginTop: '125px'}}>
                  <MiniCard isMobile={isMobile} isDarkMode={isDarkMode}>
                    <MiniImage src={main.logo} alt="MiniCard Logo" style={{ height: '100px', width: '100px' }} />
                    <div>
                    <RarityMark rarity={main.rarityLevel}>#{main.rarity}</RarityMark>
                    <MiniPrice>{main.ethPrice} <FaEthereum /></MiniPrice>
                    <MiniDescription>{main.lastSold} <FaEthereum /></MiniDescription>
                    </div>
                    <PlusButton onClick={() => handleCardClick(main)}>+</PlusButton>
                  </MiniCard>
                </GridItemWrapper2>
              );
            }

            return null; // Return null for empty cells
          }}
        </Grid>
      </AnimatedCollectionWrapper>
    </>
  );
};
