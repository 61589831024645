// InfoPopup.js
import React from 'react';
import { FaEthereum } from 'react-icons/fa';
import {
    BlurBackground,
    CloseIcon,
    GraphPlaceholder,
    InfoPopupContainer,
    InfoPopupDescription,
    InfoPopupImage,
    InfoPopupOwner,
    InfoPopupPrice,
    InfoPopupTitle,
    RarityMark,
    SectionContent,
    SectionTitle,
    SmallText,
    TraitDescription,
    TraitsTitle,
} from './InfoPopupElements';

const InfoPopup = ({ selectedCard, handleClosePopup, handleOutsideClick, isDarkMode }) => {
  return (
    <>
      <BlurBackground id="blur-background" onClick={handleOutsideClick} />
      <InfoPopupContainer isDarkMode={isDarkMode}>
        <CloseIcon onClick={handleClosePopup}>&times;</CloseIcon>
        <InfoPopupImage src={selectedCard.logo} alt="Main Logo" style={{ width: '150px' }} />
        <InfoPopupTitle>{selectedCard.header}</InfoPopupTitle>
        <InfoPopupDescription>{selectedCard.name}</InfoPopupDescription>
        <InfoPopupDescription>{selectedCard.description}</InfoPopupDescription>
        <InfoPopupPrice>{selectedCard.ethPrice} <FaEthereum /></InfoPopupPrice>
        <InfoPopupOwner>Owner: <a href={`https://etherscan.io/address/${selectedCard.owner}`} style={{ color: 'lightblue' }}>{selectedCard.owner}</a></InfoPopupOwner>
        <SmallText>RARITY <RarityMark rarity={selectedCard.rarityLevel} isDarkMode={isDarkMode}>#{selectedCard.rarity}</RarityMark></SmallText>
        <InfoPopupDescription>Floor Difference: {selectedCard.floorDifference}</InfoPopupDescription>
        <InfoPopupDescription>Last Sold: {selectedCard.lastSold} <FaEthereum /></InfoPopupDescription>
        <InfoPopupDescription>Listed Time: {selectedCard.listedTime}</InfoPopupDescription>

        <TraitsTitle>Traits</TraitsTitle>
        {selectedCard.traits.map((trait, index) => (
          <TraitDescription key={index}>{trait.trait}: {trait.attribute} (Rarity: {trait.rarity}, Price: {trait.price})</TraitDescription>
        ))}

        <SectionTitle>Price History</SectionTitle>
        <GraphPlaceholder>Graph here</GraphPlaceholder>

        <SectionTitle>About Me</SectionTitle>
        <SectionContent>Details about the NFT and its creator</SectionContent>

        <SectionTitle>Listed Details</SectionTitle>
        <SectionContent>Connect wallet to see more</SectionContent>
      </InfoPopupContainer>
    </>
  );
};

export default InfoPopup;
