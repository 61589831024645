import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FilterContainer = styled(motion.div)`
  position: absolute;
  width: 65%;
  max-height: 35px;
  top: 26.5%;
  left: 3%;
  background-color: ${({ isDarkMode }) => (isDarkMode ? 'rgba(0, 0, 0, 0.85)' : 'rgba(256, 256, 256, 0.85)')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  padding: 0px;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  flex-direction: row; /* Changed to row for horizontal layout */
  overflow: hidden;

  @media (max-width: 600px) {
    flex-direction: column; /* Stack vertically on mobile */
    height: auto;
  }
`;

export const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
 // margin-right: 20px;
  margin-left: 20px;
  flex-grow: 1;
  border-left: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ccc')}; /* Separator bar */
  padding-left: 10px;
 // padding-right: 10px;
  margin-bottom: 20px;

  &:first-child {
    border-left: none; /* Remove the left border for the first section */
  }

  @media (max-width: 600px) {
    margin-bottom: 20px; /* Add spacing when stacked vertically */
    border-left: none; /* Remove border in vertical stack */
    border-top: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ccc')}; /* Add top border instead */
    padding-top: 10px;
  }
`;

export const FilterTitle = styled.h3`
  text-transform: uppercase;
  font-size: 0.5rem;
  margin-bottom: 0px;
  margin-top: 20px;
`;

export const Dropdown = styled.select`
  width: 100%;
  padding: 10px;
  max-width: 150px;
  border-radius: 5px;
  border: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ccc')};
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#222' : '#f9f9f9')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
`;

export const DropdownOption = styled.option`
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#333' : '#fff')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: row;
`;

export const PriceRangeInput = styled.input`
  padding: 2.5px;
  max-width: 50px;
  border-radius: 5px;
  border: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ccc')};
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#222' : '#f9f9f9')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
`;

export const RarityRangeInput = styled.input`
  padding: 2.5px;
  border-radius: 5px;
  border: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#444' : '#ccc')};
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#222' : '#f9f9f9')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
`;

export const ApplyButton = styled.button`
margin-right: 15px;
  padding: 5px;
  border-radius: 5px;
  border: none;
  background-color: gold;
  color: black;
  cursor: pointer;

  &:hover {
    background-color: darkorange;
  }
`;

export const FilterDropdown = styled.select`
  width: 100%;
  padding: 8px;
  background-color: ${({ isDarkMode }) => isDarkMode ? '#444' : '#fff'};
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  border: 1px solid ${({ isDarkMode }) => isDarkMode ? '#666' : '#ddd'};
  border-radius: 4px;
`;

export const FilterInput = styled.input`
  width: calc(50% - 10px);
  padding: 8px;
  margin-right: 10px;
  background-color: ${({ isDarkMode }) => isDarkMode ? '#444' : '#fff'};
  color: ${({ isDarkMode }) => isDarkMode ? '#fff' : '#333'};
  border: 1px solid ${({ isDarkMode }) => isDarkMode ? '#666' : '#ddd'};
  border-radius: 4px;
`;