import { useAnimations, useGLTF } from '@react-three/drei';
import { editable as e } from "@theatre/r3f";
import React, { useEffect, useRef } from 'react';

export default function Mannequin({props, mannequinKey}) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/web3-mannequin.glb');
  const { actions } = useAnimations(animations, group);
  useEffect(() => {
    const actionName = Object.keys(actions)[0]; // Assuming the first action is the one you want to play
    if (actions[actionName]) {
      actions[actionName].setEffectiveTimeScale(0.6);
      actions[actionName].reset().fadeIn(0.5).play();
    }
  }, [actions]);
  
  return (
    <e.group ref={group} theatreKey = {mannequinKey} {...props} dispose={null}>
      <group name="Scene">
      <group name="Armature" rotation={[Math.PI / 2, 0, 0]} position={[0, 0.025, 0]} scale={0.009}>
          <primitive object={nodes.mixamorigHips} />
        </group>
        <skinnedMesh
          name="base_female_Material21892_0"
          geometry={nodes.base_female_Material21892_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.base_female_Material21892_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="base_female_Material21898_0"
          geometry={nodes.base_female_Material21898_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.base_female_Material21898_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="base_female_Material21905_0"
          geometry={nodes.base_female_Material21905_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.base_female_Material21905_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="base_female_Material21911_0"
          geometry={nodes.base_female_Material21911_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.base_female_Material21911_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="base_female_Material21915_0"
          geometry={nodes.base_female_Material21915_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.base_female_Material21915_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="base_female_Material21919_0"
          geometry={nodes.base_female_Material21919_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.base_female_Material21919_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="base_female_Material21924_0"
          geometry={nodes.base_female_Material21924_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.base_female_Material21924_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="base_female_Material21930_0"
          geometry={nodes.base_female_Material21930_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.base_female_Material21930_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="black_hoodie_and_tarck_pant_f_FABRIC_2_FRONT_2088_0"
          geometry={nodes.black_hoodie_and_tarck_pant_f_FABRIC_2_FRONT_2088_0.geometry}
          material={materials.Material}
          skeleton={nodes.black_hoodie_and_tarck_pant_f_FABRIC_2_FRONT_2088_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="black_hoodie_and_tarck_pant_f_Fabric190897_FRONT_4566_0"
          geometry={nodes.black_hoodie_and_tarck_pant_f_Fabric190897_FRONT_4566_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.black_hoodie_and_tarck_pant_f_Fabric190897_FRONT_4566_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="black_hoodie_and_tarck_pant_f_Fabric195589_FRONT_4605_0"
          geometry={nodes.black_hoodie_and_tarck_pant_f_Fabric195589_FRONT_4605_0.geometry}
          material={materials.PaletteMaterial001}
          skeleton={nodes.black_hoodie_and_tarck_pant_f_Fabric195589_FRONT_4605_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="black_hoodie_and_tarck_pant_f_hoodie_FRONT_2083_0"
          geometry={nodes.black_hoodie_and_tarck_pant_f_hoodie_FRONT_2083_0.geometry}
          material={materials.Material}
          skeleton={nodes.black_hoodie_and_tarck_pant_f_hoodie_FRONT_2083_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="black_hoodie_and_tarck_pant_f_Material190826_0"
          geometry={nodes.black_hoodie_and_tarck_pant_f_Material190826_0.geometry}
          material={materials.PaletteMaterial002}
          skeleton={nodes.black_hoodie_and_tarck_pant_f_Material190826_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="black_hoodie_and_tarck_pant_f_Material190827_0"
          geometry={nodes.black_hoodie_and_tarck_pant_f_Material190827_0.geometry}
          material={materials.PaletteMaterial002}
          skeleton={nodes.black_hoodie_and_tarck_pant_f_Material190827_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="black_hoodie_and_tarck_pant_f_Material195518_0"
          geometry={nodes.black_hoodie_and_tarck_pant_f_Material195518_0.geometry}
          material={materials.PaletteMaterial002}
          skeleton={nodes.black_hoodie_and_tarck_pant_f_Material195518_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="black_hoodie_and_tarck_pant_f_Material195519_0"
          geometry={nodes.black_hoodie_and_tarck_pant_f_Material195519_0.geometry}
          material={materials.PaletteMaterial002}
          skeleton={nodes.black_hoodie_and_tarck_pant_f_Material195519_0.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="skimask_2002_large_0002"
          geometry={nodes.skimask_2002_large_0002.geometry}
          material={materials.hoodie_FRONT_2083}
          skeleton={nodes.skimask_2002_large_0002.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
        <skinnedMesh
          name="Travis_x_Scott_Cactus_Jack_x_Air_Jordan_4_High_Retro_mat_00_ma"
          geometry={nodes.Travis_x_Scott_Cactus_Jack_x_Air_Jordan_4_High_Retro_mat_00_ma.geometry}
          material={materials.PaletteMaterial003}
          skeleton={nodes.Travis_x_Scott_Cactus_Jack_x_Air_Jordan_4_High_Retro_mat_00_ma.skeleton}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        />
      </group>
    </e.group>
  );
}

useGLTF.preload('https://w3omedia.s3.us-east-2.amazonaws.com/w3o+glb/web3-mannequin.glb');
